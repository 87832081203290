import { Axios, API_VERSION } from '@/api/config';

export const login = (data) => {
  const url = `${API_VERSION}/authorization/sign-in/through/basic-credentials`;
  return Axios.post(url, data);
};

export const loginWithEuKey = (data) => {
  const url = `${API_VERSION}/authorization/sign-in/through/ecp`;
  return Axios.post(url, data);
};

export const logout = () => {
  const url = `${API_VERSION}/authorization/logout`;
  return Axios.post(url);
};

export const resetPass = (data) => {
  const url = `${API_VERSION}/auth/reset-password`;
  return Axios.post(url, data);
};

export const confirmResetPass = (data) => {
  const url = `${API_VERSION}/auth/reset-password-confirm`;
  return Axios.post(url, data);
};
