import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import emailMask from 'text-mask-addons/dist/emailMask';
// import textMask, {conformToMask} from 'where-you-import-text-mask-from'

export default {
  value: '',
  phone: [
    '(',
    /[0]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  fax: [
    '(',
    /[0]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  hryvna: createNumberMask({
    prefix: '',
    suffix: ' грн',
    thousandsSeparatorSymbol: ' ',
    allowDecimal: true,
  }),
  peoples: createNumberMask({
    prefix: '',
    suffix: ' осіб',
    thousandsSeparatorSymbol: '',
    allowDecimal: false,
  }),
  squareKilometers: createNumberMask({
    prefix: '',
    suffix: ' кв.м',
    thousandsSeparatorSymbol: '',
    allowDecimal: false,
  }),
  kilometers: createNumberMask({
    prefix: '',
    suffix: ' км',
    thousandsSeparatorSymbol: '',
    allowDecimal: true,
  }),
  integer: createNumberMask({
    prefix: '',
    suffix: '',
    thousandsSeparatorSymbol: '',
    allowDecimal: false,
  }),
  int: createNumberMask({
    prefix: '',
    suffix: '',
    thousandsSeparatorSymbol: ' ',
    allowDecimal: false,
  }),
  email: emailMask
};
