import moment from 'moment';

const FULL_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const SHORT_FORMAT_DATE_YMD = 'YYYY-MM-DD';
const SHORT_FORMAT_DATE_DMY = 'DD.MM.YYYY';
const SHORT_FORMAT_TIME = 'HH:mm:ss';
const TODAY = moment();

const localTime = (date) => {
  return moment(moment.utc(date, FULL_FORMAT)).local();
};

export const convertDateToCurrentTimeZone = (date) => {
  if (!!date) {
    let d = localTime(date);
    // let d = moment(date, FULL_FORMAT);
    return {
      date: d.format(SHORT_FORMAT_DATE_DMY),
      time: d.format(SHORT_FORMAT_TIME),
    };
  }
};

export const checkIsSameDate = (date) => {
  // let d = localTime(date).format(SHORT_FORMAT_DATE_YMD);
  let d = moment(date, FULL_FORMAT);
  let b = TODAY.format(SHORT_FORMAT_DATE_YMD);
  return moment(b).isSame(d);
};

export const checkIsDateAfterToday = (date) => {
  // let day = localTime(date).format(SHORT_FORMAT_DATE_YMD);
  let day = moment(date, FULL_FORMAT);
  let today = TODAY.format(SHORT_FORMAT_DATE_YMD);
  return moment(day).isAfter(today);
};

export const checkIsDateSameOrBeforeToday = (date) => {
  // let day = localTime(date).format(SHORT_FORMAT_DATE_YMD);
  let day = moment(date, FULL_FORMAT);
  let today = TODAY.format(SHORT_FORMAT_DATE_YMD);
  return moment(day).isSameOrBefore(today);
};

export const getDateRangesAgoFromNow = (range) => {
  let to = moment().subtract(range.to, 'years').format(SHORT_FORMAT_DATE_YMD);

  let from = Number.isInteger(range.from)
    ? moment()
        .subtract(1, 'days')
        .subtract(range.from, 'years')
        .format(SHORT_FORMAT_DATE_YMD)
    : range.from;

  return { from, to };
};
