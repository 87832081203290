import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      //////////////////// Файловий ключ ////////////////////
      fileKeyReadedData: null,
      fileKeyOwnerInfo: null,
    };
  },
  computed: {
    ...mapGetters([
      'getKeyFileData',
      'getSelectedACSKForTypeFile',
      'getKeyPassword',
      'isInitEuSign',
      'getDataToSign',
    ]),
    keyFileName() {
      return this.getKeyFileData.name;
    },
    keyFileSize() {
      const size = this.getKeyFileData.size;
      const unit = size >= 1048576 ? 'мб' : 'кб';
      const formattedSize = size >= 1048576 ? size / 1024 / 1024 : size / 1024;

      return `${formattedSize.toFixed(1)} ${unit}`;
    },
  },
  methods: {
    async setFileKeyLibrary() {

      this.euSign = new EndUser(null, EndUserConstants.EndUserLibraryType.JS);

      let res;

      try {
        await this.initializeFileType();

        this.$store.commit('SET_INIT_EU_SIGN', true);
      } catch (e) {
        let msg = e.message || e;
        this.readingKeyError = true;
        this.readingKeyErrorMsg = msg;
      }
    },

    async initializeFileType() {
      let isInit;
      try {
        isInit = await this.euSign.IsInitialized();

        await this.euSign.Initialize(this.euSettings);

      } catch (e) {
        console.log('>>> initializeFileType error', e);
      }
    },

    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = (evt) => {
          if (evt.target.readyState != FileReader.DONE) return;

          resolve({
            file: file,
            data: new Uint8Array(evt.target.result),
          });
        };
        reader.readAsArrayBuffer(file);
      });
    },

    async readPrivateKey() {
      /*
        Загальне ім'я ЦСК з списку CAs.json, який видав сертифікат для ос. ключа.
        Якщо null бібліотека намагається визначити ЦСК автоматично за
        сервером CMP\сертифікатом. Встановлюється у випадках, коли ЦСК не
        підтримує CMP, та для пришвидшення пошуку сертифіката ос. ключа
      */

      let caCN = !!this.getSelectedACSKForTypeFile
        ? this.getSelectedACSKForTypeFile
        : null;
      /*
        Сертифікати, що відповідають ос. ключу (масив об'єктів типу Uint8Array).
        Якщо null бібліотека намагається завантажити їх з ЦСК автоматично з сервера CMP.
        Встановлюється у випадках, коли ЦСК не підтримує CMP, та для пришвидшення
        пошуку сертифіката ос. ключа
      */
      let pkCertificates = null;
      let sign, fileData, jksKeys, userdata;

      try {
        fileData = await this.readFile(this.getKeyFileData);
        // Якщо файл з ос. ключем має розширення JKS, ключ може містити декілька ключів,
        // для зчитування такого ос. ключа необхіно обрати який ключ повинен зчитуватися

        if (fileData.file.name.endsWith('.jks')) {
          jksKeys = await this.euSign.GetJKSPrivateKeys(fileData.data);

          // Для спрощення прикладу обирається перший ключ
          this.fileKeyReadedData = jksKeys[0].privateKey;
        }

        this.fileKeyReadedData = fileData.data;

        userdata = await this.euSign.ReadPrivateKeyBinary(
          this.fileKeyReadedData,
          this.getKeyPassword,
          pkCertificates,
          caCN
        );

        sign = await this.euSign.SignDataInternal(
          true,
          this.getDataToSign,
          true
        );

        this.$store.commit('SET_KEY_SIGN_DATA', sign);

        return userdata;
      } catch (e) {
        this.readingKeyError = true;
        this.readingKeyErrorMsg = e.message;
      }
    },

    // async encodeFileKey() {
    //   const fileKeyData = JSON.stringify({
    //     file: this.fileKeyReadedData,
    //     password: this.getKeyPassword,
    //   });

    //   const encodedFileKeyData = await this.euSign.ProtectDataByPassword(
    //     fileKeyData,
    //     null,
    //     true
    //   );

    //   return encodedFileKeyData;
    // },

    async readKeyFromFile() {
      try {
        this.$store.commit('SET_INIT_EU_SIGN', false);
        this.fileKeyOwnerInfo = await this.readPrivateKey();

        if (!!this.fileKeyOwnerInfo) {
          await this.openModal({
            keyInfo: this.fileKeyOwnerInfo,
          });
          await this.resetValidationData();
        }

        await this.$store.commit('SET_INIT_EU_SIGN', true);
      } catch (e) {
        console.log('>>> readKeyFromFile error', e);
        let msg = !!e ? e.message || e : '';
        this.readingKeyError = true;
        this.readingKeyErrorMsg = msg;
      }
    },
  },
};
