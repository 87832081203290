import {
  getTableOfHotelsAccessAdmin,
  getTableOfTourAccessAdmin,
  getTableOfDartAccessAdmin,
  getTableOfCommissionerAccessAdmin,
  getTableOfPresidingAccessAdmin,
  getTableOfSecretaryAccessAdmin,
  getTableOfDeputyAccessAdmin,
  getTableOfContentAccessAdmin,
  getTableOfAccessAccessAdmin,
  getUserAccessAdmin,
  createDartAccessAdmin,
  updateDartAccessAdmin,
  createCommissionerAccessAdmin,
  updateCommissionerAccessAdmin,
  createSecretaryAccessAdmin,
  updateSecretaryAccessAdmin,
  createDeputyAccessAdmin,
  updateDeputyAccessAdmin,
  createContentAdminAccessAdmin,
  updateContentAdminAccessAdmin,
  createAccessAdminAccessAdmin,
  updateAccessAdminAccessAdmin,
  getEventLogTabelAccessAdmin,
  getEventLogActionsAccessAdmin,
  getTableOfCertificationAgenciesAccessAdmin,
  createCertificationAgencyAccessAdmin,
  updateCertificationAgencyAccessAdmin,
} from '@/api/services/access_admin.js';
import router from '@/router';
import VueI18n from '@/i18n';

export default {
  state: {
    tableOfHotelsAccessAdmin: [],
    tableOfTourAccessAdmin: [],
    tableOfDartAccessAdmin: [],
    tableOfCommissionerAccessAdmin: [],
    tableOfPresidingAccessAdmin: [],
    tableOfSecretaryAccessAdmin: [],
    tableOfDeputyAccessAdmin: [],
    tableOfContentAccessAdmin: [],
    tableOfAccessAccessAdmin: [],
    tableOfLogsAccessAdmin: null,
    optionsOfLogsAccessAdmin: null,
    userDetailsAccessAdmin: null,
    selectedCommissionMember: {
      name: 'Commissioner',
      title: VueI18n.t('sections.commissioner'),
    },
    selectedActionsAccessAdmin: [],
    tableOfCertificationAgenciesAccessAdmin: [],
  },

  getters: {
    getTableOfHotelsAccessAdmin: (state) => state.tableOfHotelsAccessAdmin,
    getTableOfTourAccessAdmin: (state) => state.tableOfTourAccessAdmin,
    getTableOfDartAccessAdmin: (state) => state.tableOfDartAccessAdmin,

    getTableOfCommissionerAccessAdmin: (state) =>
      state.tableOfCommissionerAccessAdmin,
    getTableOfPresidingAccessAdmin: (state) =>
      state.tableOfPresidingAccessAdmin,
    getTableOfSecretaryAccessAdmin: (state) =>
      state.tableOfSecretaryAccessAdmin,
    getTableOfDeputyAccessAdmin: (state) => state.tableOfDeputyAccessAdmin,

    getTableOfContentAccessAdmin: (state) => state.tableOfContentAccessAdmin,
    getTableOfAccessAccessAdmin: (state) => state.tableOfAccessAccessAdmin,
    getTableOfLogsAccessAdmin: (state) => state.tableOfLogsAccessAdmin,
    getOptionsOfLogsAccessAdmin: (state) => state.optionsOfLogsAccessAdmin,
    getUserDetailsAccessAdmin: (state) => state.userDetailsAccessAdmin,

    getSelectedCommissionMember: (state) => state.selectedCommissionMember,
    getSelectedActionsAccessAdmin: (state) => state.selectedActionsAccessAdmin,
    getTableOfCertificationAgenciesAccessAdmin: (state) => state.tableOfCertificationAgenciesAccessAdmin
  },

  mutations: {
    SET_TABLE_OF_HOTELS_ACCESS_ADMIN(state, data) {
      state.tableOfHotelsAccessAdmin = data;
    },
    SET_TABLE_OF_TOUR_ACCESS_ADMIN(state, data) {
      state.tableOfTourAccessAdmin = data;
    },
    SET_TABLE_OF_DART_ACCESS_ADMIN(state, data) {
      state.tableOfDartAccessAdmin = data;
    },
    SET_TABLE_OF_COMMISSIONER_ACCESS_ADMIN(state, data) {
      state.tableOfCommissionerAccessAdmin = data;
    },
    SET_TABLE_OF_PRESIDING_ACCESS_ADMIN(state, data) {
      state.tableOfPresidingAccessAdmin = data;
    },
    SET_TABLE_OF_SECRETARY_ACCESS_ADMIN(state, data) {
      state.tableOfSecretaryAccessAdmin = data;
    },
    SET_TABLE_OF_DEPUTY_ACCESS_ADMIN(state, data) {
      state.tableOfDeputyAccessAdmin = data;
    },
    SET_TABLE_OF_CONTENT_ACCESS_ADMIN(state, data) {
      state.tableOfContentAccessAdmin = data;
    },
    SET_TABLE_OF_CERTIFICATION_AGENIES_ACCESS_ADMIN(state, data) {
      state.tableOfCertificationAgenciesAccessAdmin = data;
    },
    SET_TABLE_OF_ACCESS_ACCESS_ADMIN(state, data) {
      state.tableOfAccessAccessAdmin = data;
    },
    SET_USER_DETAILS_ACCESS_ADMIN(state, data) {
      state.userDetailsAccessAdmin = data;
    },
    SET_SELECTED_COMMISSION_MEMBER(state, data) {
      state.selectedCommissionMember.name = data.name;
      state.selectedCommissionMember.title = data.title;
    },
    SET_TABLE_OF_LOG_ACCESS_ADMIN(state, data) {
      state.tableOfLogsAccessAdmin = data;
    },
    SET_ACTIONS_OF_LOG_ACCESS_ADMIN(state, data) {
      state.optionsOfLogsAccessAdmin = data;
    },
    SET_SELECTED_ACTIONS_ACCESS_ADMIN(state, data) {
      state.selectedActionsAccessAdmin = [...data];
    },
  },

  actions: {
    async getTableOfHotelsAccessAdmin({ commit, getters }) {
      try {
        let res;
        res = await getTableOfHotelsAccessAdmin(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_HOTELS_ACCESS_ADMIN', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfHotelsAccessAdmin',
          e.response.data.error
        );
      }
    },
    async getTableOfTourAccessAdmin({ commit, getters }) {
      try {
        let res;
        res = await getTableOfTourAccessAdmin(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_TOUR_ACCESS_ADMIN', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfTourAccessAdmin',
          e.response.data.error
        );
      }
    },
    async getTableOfDartAccessAdmin({ commit, getters }) {
      try {
        let res;
        res = await getTableOfDartAccessAdmin(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_DART_ACCESS_ADMIN', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfDartAccessAdmin',
          e.response.data.error
        );
      }
    },
    async getTableOfCommissionerAccessAdmin({ commit, getters }) {
      try {
        let res;
        res = await getTableOfCommissionerAccessAdmin(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_COMMISSIONER_ACCESS_ADMIN', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfCommissionerAccessAdmin',
          e.response.data.error
        );
      }
    },
    async getTableOfPresidingAccessAdmin({ commit, getters }) {
      try {
        let res;
        res = await getTableOfPresidingAccessAdmin(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_PRESIDING_ACCESS_ADMIN', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfPresidingAccessAdmin',
          e.response.data.error
        );
      }
    },
    async getTableOfSecretaryAccessAdmin({ commit, getters }) {
      try {
        let res;
        res = await getTableOfSecretaryAccessAdmin(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_SECRETARY_ACCESS_ADMIN', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfSecretaryAccessAdmin',
          e.response.data.error
        );
      }
    },
    async getTableOfDeputyAccessAdmin({ commit, getters }) {
      try {
        let res;
        res = await getTableOfDeputyAccessAdmin(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_DEPUTY_ACCESS_ADMIN', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfDeputyAccessAdmin',
          e.response.data.error
        );
      }
    },
    async getTableOfContentAccessAdmin({ commit, getters }) {
      try {
        let res;
        res = await getTableOfContentAccessAdmin(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_CONTENT_ACCESS_ADMIN', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfContentAccessAdmin',
          e.response.data.error
        );
      }
    },
    async getTableOfCertificationAgenciesAccessAdmin({ commit, getters }) {
      try {
        let res;
        res = await getTableOfCertificationAgenciesAccessAdmin(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_CERTIFICATION_AGENIES_ACCESS_ADMIN', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfCertificationAgenciesAccessAdmin',
          e.response.data.error
        );
      }
    },
    async createCertificationAgencyAccessAdmin({ commit }, data) {
      try {
        let res;
        res = await createCertificationAgencyAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.user_created')
          );
          router.push({ name: 'access-administrator_certification-agency-list' });
        }
      } catch (e) {
        console.log('>>> error createCertificationAgencyAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },
    async updateCertificationAgencyAccessAdmin({ commit, dispatch }, payload) {
      const { data, isRoute = true } = payload;
      try {
        let res;
        res = await updateCertificationAgencyAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );
          if (!!isRoute) {
            router.push({ name: 'access-administrator_certification-agency-list' });
          } else {
            dispatch('getTableOfCertificationAgenciesAccessAdmin');
          }
        }
      } catch (e) {
        console.log('>>> error updateCertificationAgencyAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async getTableOfAccessAccessAdmin({ commit, getters }) {
      try {
        let res;
        res = await getTableOfAccessAccessAdmin(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_ACCESS_ACCESS_ADMIN', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfAccessAccessAdmin',
          e.response.data.error
        );
      }
    },

    async getUserAccessAdmin({ commit }, uuid) {
      try {
        let res;
        res = await getUserAccessAdmin(uuid);
        if (res.status === 200) {
          commit('SET_USER_DETAILS_ACCESS_ADMIN', res.data.data.user);
        }
      } catch (e) {
        console.log('>>> error getUserAccessAdmin', e.response.data.error);
      }
    },

    async createDartAccessAdmin({ commit }, data) {
      try {
        let res;
        res = await createDartAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.user_created')
          );
          router.push({ name: 'access-administrator_dart-list' });
        }
      } catch (e) {
        console.log('>>> error createDartAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async updateDartAccessAdmin({ commit, dispatch }, payload) {
      const { data, isRoute = true } = payload;
      try {
        let res;
        res = await updateDartAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );
          if (!!isRoute) {
            router.push({ name: 'access-administrator_dart-list' });
          } else {
            dispatch('getTableOfDartAccessAdmin');
          }
        }
      } catch (e) {
        console.log('>>> error updateDartAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async createCommissionerAccessAdmin({ commit }, data) {
      try {
        let res;
        res = await createCommissionerAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.user_created')
          );
          router.push({ name: 'access-administrator_commissioner-list' });
        }
      } catch (e) {
        console.log('>>> error createCommissionerAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async updateCommissionerAccessAdmin({ commit, dispatch }, payload) {
      const { data, isRoute = true } = payload;
      try {
        let res;
        res = await updateCommissionerAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );
          if (!!isRoute) {
            router.push({ name: 'access-administrator_commissioner-list' });
          } else {
            dispatch('getTableOfCommissionerAccessAdmin');
          }
        }
      } catch (e) {
        console.log('>>> error updateCommissionerAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async createSecretaryAccessAdmin({ commit }, data) {
      try {
        let res;
        res = await createSecretaryAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.user_created')
          );
          router.push({ name: 'access-administrator_commissioner-list' });
        }
      } catch (e) {
        console.log('>>> error createSecretaryAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async updateSecretaryAccessAdmin({ commit, dispatch }, payload) {
      const { data, isRoute = true } = payload;
      try {
        let res;
        res = await updateSecretaryAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );

          if (!!isRoute) {
            router.push({ name: 'access-administrator_commissioner-list' });
          } else {
            dispatch('getTableOfSecretaryAccessAdmin');
          }
        }
      } catch (e) {
        console.log('>>> error updateSecretaryAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async createDeputyAccessAdmin({ commit }, data) {
      try {
        let res;
        res = await createDeputyAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.user_created')
          );
          router.push({ name: 'access-administrator_commissioner-list' });
        }
      } catch (e) {
        console.log('>>> error createDeputyAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async updateDeputyAccessAdmin({ commit, dispatch }, payload) {
      const { data, isRoute = true } = payload;
      try {
        let res;
        res = await updateDeputyAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );
          if (!!isRoute) {
            router.push({ name: 'access-administrator_commissioner-list' });
          } else {
            dispatch('getTableOfDeputyAccessAdmin');
          }
        }
      } catch (e) {
        console.log('>>> error updateDeputyAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async createContentAdminAccessAdmin({ commit }, data) {
      try {
        let res;
        res = await createContentAdminAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.user_created')
          );
          router.push({ name: 'access-administrator_content-admin-list' });
        }
      } catch (e) {
        console.log('>>> error createContentAdminAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async updateContentAdminAccessAdmin({ commit, dispatch }, payload) {
      const { data, isRoute = true } = payload;
      try {
        let res;
        res = await updateContentAdminAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );
          if (!!isRoute) {
            router.push({ name: 'access-administrator_content-admin-list' });
          } else {
            dispatch('getTableOfContentAccessAdmin');
          }
        }
      } catch (e) {
        console.log('>>> error updateContentAdminAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async createAccessAdminAccessAdmin({ commit }, data) {
      try {
        let res;
        res = await createAccessAdminAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.user_created')
          );
          router.push({ name: 'access-administrator_access-admin-list' });
        }
      } catch (e) {
        console.log('>>> error createAccessAdminAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async updateAccessAdminAccessAdmin({ commit, dispatch }, payload) {
      const { data, isRoute = true } = payload;
      try {
        let res;
        res = await updateAccessAdminAccessAdmin(data);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );

          if (!!isRoute) {
            router.push({ name: 'access-administrator_access-admin-list' });
          } else {
            dispatch('getTableOfAccessAccessAdmin');
          }
        }
      } catch (e) {
        console.log('>>> error updateAccessAdminAccessAdmin', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async getEventLogTabelAccessAdmin({ commit, getters }) {
      try {
        let res;
        let payload = {
          ...getters.getTableSortData,
        };

        if (!!getters.getSelectedActionsAccessAdmin.length) {
          payload['filter[action]'] = getters.getSelectedActionsAccessAdmin;
        }

        res = await getEventLogTabelAccessAdmin(payload);
        if (res.status === 200) {
          commit('SET_TABLE_OF_LOG_ACCESS_ADMIN', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log('>>> error getEventLogTabelAccessAdmin', e.response);
      }
    },

    async getEventLogActionsAccessAdmin({ commit }) {
      try {
        let res;
        res = await getEventLogActionsAccessAdmin();
        if (res.status === 200) {
          commit('SET_ACTIONS_OF_LOG_ACCESS_ADMIN', res.data.data.options);
        }
      } catch (e) {
        console.log('>>> error getEventLogActionsAccessAdmin', e.response);
      }
    },

    async blockUnblockComissionerAccessAdmin({ dispatch, getters }, data) {
      let handlerName = `update${getters.getSelectedCommissionMember.name}AccessAdmin`;

      dispatch(handlerName, { data, isRoute: false });
    },

    async blockUnblockContentAdminAccessAdmin({ dispatch }, data) {
      dispatch('updateContentAdminAccessAdmin', { data, isRoute: false });
    },

    async blockUnblockAccessAdminAccessAdmin({ dispatch }, data) {
      dispatch('updateAccessAdminAccessAdmin', { data, isRoute: false });
    },

    async blockUnblockDartAccessAdmin({ dispatch }, data) {
      dispatch('updateDartAccessAdmin', { data, isRoute: false });
    },
  },
};
