<template>
  <v-tooltip content-class="custom-tooltip" v-bind="getAttr">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="custom-tooltip__el"
        :class="{ 'custom-tooltip__el_table': isTable }"
      >
        <slot></slot>
      </div>
    </template>
    <span class="custom-tooltip__text" v-if="isDynamic">{{ text }}</span>
    <span v-else>{{ $t(`table.${text}`) }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'right',
    },
    isDynamic: {
      type: Boolean,
      default: false,
    },
    isTable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getAttr() {
      return { [this.position]: true };
    },
  },
};
</script>

<style lang="sass" scoped>
.custom-tooltip
  opacity: 1 !important
  border: 1px solid #E6EEF2
  border-radius: 12px
  box-shadow: 0px 0px 10px rgba(38, 55, 76, 0.2)
  max-width: 285px
  width: 285px
  text-align: center
  overflow: hidden

.custom-tooltip__el
  display: inline-block
  cursor: pointer
  &:not(.custom-tooltip__el_table)
    padding-left: 15px
    line-height: 1em

.custom-tooltip__text
  white-space: pre-line
</style>
