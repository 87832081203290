export default [
  {
    path: '/presiding-commissioner',
    name: 'presiding_commissioner',
    component: () => import('@/components/presiding_commissioner/Main.vue'),
    redirect: { name: 'presiding-commissioner_votes-list' },

    children: [
      // {
      //   path: 'tour-operator-reports',
      //   name: 'presiding-commissioner_tour-operator-reports',
      //   component: () =>
      //     import('@/components/presiding_commissioner/TourOperatorReports.vue'),
      //   meta: {
      //     isMenu: true,
      //   },
      // },
      // {
      //   path: 'tour-operator-reports/:id',
      //   name: 'presiding-commissioner_tour-operator-report_review',
      //   component: () => import('@/components/presiding_commissioner/TourOperatorReport.vue'),
      //   meta: {
      //     isMenu: true     
      //   },
      // },
      {
        path: 'votes-list',
        name: 'presiding-commissioner_votes-list',
        component: () =>
          import('@/components/presiding_commissioner/VotesList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'categorization/:id',
        name: 'presiding-commissioner_vote-review',
        component: () =>
          import('@/components/presiding_commissioner/Categorization.vue'),
        meta: {
          isMenu: true,
          status: 'review',
        },
      },
      {
        path: 'categorization/:id',
        name: 'presiding-commissioner_history-review',
        component: () =>
          import('@/components/presiding_commissioner/Categorization.vue'),
        meta: {
          isMenu: true,
          status: 'review',
        },
      },
      {
        path: 'votes-history',
        name: 'presiding-commissioner_history-list',
        component: () =>
          import('@/components/presiding_commissioner/VotesHistory.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'profile',
        name: 'presiding-commissioner_profile',
        component: () =>
          import('@/components/presiding_commissioner/Profile.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'categorization/voting-results/:id',
        name: 'presiding-commissioner_voting-results',
        component: () => import('@/components/vote/Voting.vue'),
        meta: {
          name: 'vote_results',
          isMenu: false,
          goBack: -2
        },
      },
      // ======== for deep pages
      // {
      //   path: 'create-news',
      //   name: 'content_administrator-create-news',
      //   component: () => import('@/components/content_admin/NewsPage.vue'),
      //   meta: {
      //     isMenu: false,
      //     name: 'additing_news',
      //   },
      // },
    ],
  },
];
