import { required, requiredIf } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  validations() {
    return {
      getSelectedACSKForTypeFile: {
        required: requiredIf(() => {
          return this.getSelectedKeyType.type === 'file-key';
        }),
      },
      fileData: {
        required: requiredIf(() => {
          return this.getSelectedKeyType.type === 'file-key';
        }),
      },
      getSelectedACSKForTypeHardware: {
        required: requiredIf(() => {
          return this.getSelectedKeyType.type === 'hardware-key';
        }),
      },
      getSelectedHardwareKey: {
        required: requiredIf(() => {
          return this.getSelectedKeyType.type === 'hardware-key';
        }),
      },

      keyPass: { required },
    };
  },

  computed: {
    ...mapGetters(['getSelectedKeyType']),

    isKeyPasswordError() {
      return this.$v.keyPass.$error || !!this.readingKeyErrorMsg.length;
    },

    errorKeyPasswordMsg() {
      let text = '';
      if (this.$v.keyPass.$error) {
        text = this.$t('error.empty_key_password');
      } else if (this.readingKeyError) {
        text = this.readingKeyErrorMsg;
      }

      return text;
    },
  },
};
