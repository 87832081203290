export function hasValue(obj) {
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== '' && obj[key].length !== 0) {
      return true;
    }

    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      if (hasValue(obj[key])) {
        return true;
      }
    }
  }
  return false;
}
