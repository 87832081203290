import { Axios, API_VERSION } from '@/api/config';

export const getNewsTableContentAdmin = (data) => {
  const url = `${API_VERSION}/content-administrator/news/table`;
  return Axios.get(url, { params: data });
};

export const getCurrentNewsContentAdmin = (uuid) => {
  const url = `${API_VERSION}/content-administrator/news`;
  return Axios.get(url, { params: { uuid } });
};

export const updateCurrentNewsContentAdmin = (data) => {
  const url = `${API_VERSION}/content-administrator/news/update`;
  return Axios.post(url, data);
};

export const createNewsContentAdmin = (data) => {
  const url = `${API_VERSION}/content-administrator/news/create`;
  return Axios.post(url, data);
};

export const deleteNewsContentAdmin = (uuid) => {
  const url = `${API_VERSION}/content-administrator/news`;
  return Axios.delete(url, { params: { uuid } });
};

export const tourismReportsTableContentAdmin = () => {
  const url = `${API_VERSION}/content-administrator/statistics/tourism-reports/table`;
  return Axios.get(url);
};

export const linkToTemplateContentAdmin = (data) => {
  const url = `${API_VERSION}/content-administrator/statistics/tourism-reports/link-to-template`;
  return Axios.get(url, { params: data });
};

export const uploadQuarterlyReportContentAdmin = (data) => {
  const url = `${API_VERSION}/content-administrator/statistics/tourism-reports/upload`;
  return Axios.post(url, data);
};

export const tourismReportArchiveContentAdmin = (data) => {
  const url = `${API_VERSION}/content-administrator/statistics/tourism-reports/archive/`;
  return Axios.get(url, { params: data });
};

export const presentationsTableContentAdmin = (data) => {
  const url = `${API_VERSION}/content-administrator/presentations/table`;
  return Axios.get(url, { params: data });
};

export const createPresentationContentAdmin = (data) => {
  const url = `${API_VERSION}/content-administrator/presentations/create`;
  return Axios.post(url, data);
};

export const updatePresentationContentAdmin = (data) => {
  const url = `${API_VERSION}/content-administrator/presentations/update`;
  return Axios.post(url, data);
};

export const getPresentationContentAdmin = (uuid) => {
  const url = `${API_VERSION}/content-administrator/presentations`;
  return Axios.get(url, { params: uuid });
};
export const deletePresentationContentAdmin = (uuid) => {
  const url = `${API_VERSION}/content-administrator/presentations`;
  return Axios.delete(url, { params: uuid });
};


export const researchFileTableContentAdmin = (data) => {
  const url = `${API_VERSION}/content-administrator/research-files/table`;
  return Axios.get(url, { params: data });
};

export const createResearchFileContentAdmin = (data) => {
  const url = `${API_VERSION}/content-administrator/research-files/create`;
  return Axios.post(url, data);
};

export const updateResearchFileContentAdmin = (data) => {
  const url = `${API_VERSION}/content-administrator/research-files/update`;
  return Axios.post(url, data);
};

export const getResearchFileContentAdmin = (uuid) => {
  const url = `${API_VERSION}/content-administrator/research-files`;
  return Axios.get(url, { params: uuid });
};
export const deleteResearchFileContentAdmin = (uuid) => {
  const url = `${API_VERSION}/content-administrator/research-files`;
  return Axios.delete(url, { params: uuid });
};
