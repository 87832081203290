<template>
  <v-app>
    <v-main>
      <Modal />
      <NotificationMessage />
      <div class="main_container"><router-view /></div>
    </v-main>
    <Footer v-if="!isErrorPage && notProfileRoute()" />
  </v-app>
</template>

<script>
import Footer from '@/views/Footer.vue';
import Modal from '@/elements/Modals/Modal.vue';

export default {
  name: 'App',

  components: {
    Footer,
    Modal,
  },

  data: () => ({}),
  created() {
    this.isTouchDeviceFunc();
  },
  computed: {
    isErrorPage() {
      return this.$route.name === 'error';
    },
  },
  watch: {
    $route() {
      this.notProfileRoute();
    },
  },
  methods: {
    notProfileRoute() {
      return (
        this.$route.name === 'landing-page' ||
        this.$route.name === 'statistic' ||
        this.$route.name === 'statisticPage' ||
        this.$route.name === 'news' ||
        this.$route.name === 'news-details' ||
        this.$route.name === 'about' ||
        this.$route.name === 'questions' ||
        this.$route.name === 'FAQPage' ||
        this.$route.name === 'search-results' ||
        this.$route.name === 'contacts'
      );
    },
    isTouchDeviceFunc() {
      try {
        document.createEvent('TouchEvent');
        this.$store.commit('SET_IS_TOUCH_DEVICE', true);
        // this.isTouchDevice = true;
      } catch (e) {
        this.$store.commit('SET_IS_TOUCH_DEVICE', false);
        // this.isTouchDevice = false;
      }
    },
  },
};
</script>
<style lang="sass">
@import './assets/sass/fonts.sass'
</style>

