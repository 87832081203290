import { saveECP, removeECP } from '@/api/services/general';
import { setToLS, getFromLS, isLSHasItem } from '@/library/helpers';
import VueI18n from '@/i18n';

export default {
  state: {
    keyFileData: null,
    keySignData: '',
    selectedACSKForTypeFile: '',
    selectedACSKForTypeHardware: '',
    keyPassword: '',
    initEuSign: false,
    user_euKey: isLSHasItem('isHasKey') ? getFromLS('isHasKey') : false,
    user_euHardwareKey: null,
    keyMediaTypesList: [],
    selectedKeyType: null,
    isDataToSign: false,
    dataToSign: '',
    keysTypes: [
      {
        id: 1,
        title: 'file_key_title',
        icon: 'file',
        type: 'file-key',
      },
      {
        id: 2,
        title: 'hardware_key_title',
        icon: 'key',
        type: 'hardware-key',
      },
    ],
    formTypes: [
      {
        type: 'profile',
        isSet: false,
        handler: 'saveUserEuKey',
        isSign: false,
      },
      {
        type: 'auth',
        isSet: false,
        handler: 'authorisationWithEuKey',
        isSign: false,
      },
      {
        type: 'categorization-hotel',
        isSet: false,
        isSign: true,
        handler: 'signCategorizationRequest',
        key: 'categorization_application_uuid',
      },
      {
        type: 'categorization-secretary',
        isSet: false,
        isSign: true,
        handler: 'signVotingDateSecretary',
        key: 'categorization_application_uuid',
      },
      {
        type: 'categorization-presiding-commissioner',
        isSet: false,
        isSign: true,
        handler: 'signVotingDatePresiding',
        key: 'categorization_application_uuid',
      },
      {
        type: 'vote',
        isSet: false,
        isSign: true,
        handler: 'voteUserWithEuKey',
        key: 'voting_uuid',
      },
      {
        type: 'report-tour-operator',
        isSet: false,
        isSign: true,
        handler: 'signTourOperatorReport',
        key: 'report_uuid',
      },
    ],
  },

  getters: {
    getKeyFileData: (state) => state.keyFileData,

    getKeySignData: (state) => state.keySignData,

    getSelectedACSKForTypeFile: (state) => state.selectedACSKForTypeFile,

    getSelectedACSKForTypeHardware: (state) =>
      state.selectedACSKForTypeHardware,

    getSelectedHardwareKey: (state) => state.user_euHardwareKey,

    getKeyPassword: (state) => state.keyPassword,

    getKeyMediaTypesList: (state) => state.keyMediaTypesList,

    getKeysTypes: (state) => state.keysTypes,

    getSelectedKeyType: (state) => state.selectedKeyType,

    getCurrentFormType: (state) =>
      state.formTypes.find((el) => el.isSet) || null,

    getDataToSign: (state) => state.dataToSign,

    isInitEuSign: (state) => state.initEuSign,

    isUserHasEuKey: (state) => state.user_euKey,
  },

  mutations: {
    SET_KEY_FILE_DATA(state, keyFile) {
      state.keyFileData = keyFile;
    },
    SET_KEY_SIGN_DATA(state, keySign) {
      state.keySignData = keySign;
    },
    SET_SELECTED_ACSK_TYPE_FILE(state, type) {
      state.selectedACSKForTypeFile = type;
    },
    SET_SELECTED_ACSK_TYPE_HARDWARE(state, type) {
      state.selectedACSKForTypeHardware = type;
    },
    SET_KEY_PASSWORD(state, password) {
      state.keyPassword = password;
    },
    SET_INIT_EU_SIGN(state, value) {
      state.initEuSign = value;
    },
    SET_USER_EU_KEY(state, payload) {
      state.user_euKey = payload;
      setToLS('isHasKey', payload);
    },
    SET_SELECTED_HARDWARE_KEY(state, payload) {
      state.user_euHardwareKey = payload;
    },
    SET_KEY_MEDIA_TYPES_LIST(state, payload) {
      state.keyMediaTypesList = [...payload];
    },
    SET_SELECTED_KEY_TYPE(state, payload) {
      state.selectedKeyType = { ...payload };
    },
    SET_CURRENT_FORM_TYPE(state, type) {
      state.formTypes.map((el) => (el.isSet = el.type === type));
    },

    RESET_CURRENT_FORM_TYPE(state) {
      state.formTypes.map((el) => (el.isSet = false));
    },

    SET_DATA_TO_SIGN(state, payload) {
      state.dataToSign = payload;
    },
  },

  actions: {
    async saveUserEuKey({ commit }, payload) {
      let res;
      commit('SET_RQUEST_LOADED', false);
      try {
        res = await saveECP(payload);

        if (res.status === 200) {
          commit('SET_RQUEST_LOADED', true);
          commit('SET_USER_EU_KEY', true);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.add_key_success')
          );
          commit('GET_IS_ERROR', false);
        }
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
        console.log('>>> saveECP error', e);
      }
    },

    async removeUserEuKey({ commit, dispatch }) {
      let res;
      try {
        res = await removeECP();
        if (res.status === 200) {
          dispatch('clearForm');
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.remove_key_success')
          );
          commit('GET_IS_ERROR', false);
        }
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
        console.log('>>> saveECP error', e);
      }
    },

    clearForm({ commit }) {
      commit('SET_USER_EU_KEY', false);
      commit('SET_KEY_FILE_DATA', null);
      commit('SET_KEY_PASSWORD', '');
      commit('SET_SELECTED_ACSK_TYPE_FILE', '');
      commit('SET_SELECTED_ACSK_TYPE_HARDWARE', '');
      commit('SET_SELECTED_HARDWARE_KEY', null);
      commit('SET_DATA_TO_SIGN', '');
    },
  },
};
