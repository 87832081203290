import { mapGetters } from 'vuex';
import CAs from '/public/js/data/CAs.json';

export default {
  data() {
    return {
      //////////////////// Апаратний ключ ////////////////////
      selectedKeyMedia: '',
      hardwareKeyOwnerInfo: null,
    };
  },

  computed: {
    ...mapGetters([
      'getSelectedHardwareKey',
      'getKeyPassword',
      'getSelectedACSKForTypeHardware',
      'getDataToSign',
    ]),
    selectedMediaName() {
      return this.getSelectedHardwareKey
        ? this.getSelectedHardwareKey.title
        : '';
    },
  },
  methods: {
    async setHardwareKeyLibrary() {

      this.euSign = new EndUser(null, EndUserConstants.EndUserLibraryType.SW);

      let res;

      try {
        await this.initializeHardwareType();
        this.$store.commit('SET_INIT_EU_SIGN', true);
      } catch (e) {
        let msg = e.message || e;
        console.log('>>> initializeHardwareType error', e);
        this.readingKeyError = true;
        this.readingKeyErrorMsg = msg;
      }
    },

    initializeHardwareType() {
      return new Promise((resolve, reject) => {
        // Перевірка чи встановлені необхідні модулі для роботи криптографічної бібліотеки
        this.euSign
          .GetLibraryInfo()
          .then((result) => {
            if (!result.supported) {
              throw (
                'Бібліотека web-підпису не підтримується ' +
                'в вашому браузері або ОС'
              );
            }

            if (result.loaded || !result.loaded) {
              // Бібліотека встановлена, але потребує оновлення
              if (result.isNativeLibraryNeedUpdate) {
                throw 'Бібліотека web-підпису потребує оновлення. ';
                // + 'Будь ласка, встановіть оновлення за посиланням ' +
                // result.nativeLibraryInstallURL
              }

              // Якщо браузер підтримує web-розширення рекомендується
              // додатково до нативних модулів встановлювати web-розширення
              // Увага! Встановлення web-розширень ОБОВ'ЯЗКОВЕ для ОС Linux та ОС Windows Server
              if (
                result.isWebExtensionSupported &&
                !result.isWebExtensionInstalled
              ) {
                throw 'Бібліотека web-підпису потребує встановлення web-розширення.' + `<a href="${result.webExtensionInstallURL}">Сторінка встановлення розширення</a>`;
                // + 'Будь ласка, встановіть web-розширення за посиланням ' +
                // result.webExtensionInstallURL +
                // ' та оновіть сторінку'
              }

              if (!result.isSignAgentSupported) {
                // Бібліотека (нативні модулі) не встановлені
                throw 'Бібліотека web-підпису потребує встановлення.' + `<a href=${result.nativeLibraryInstallURL}>${result.nativeLibraryInstallURL}</a>`;
                // + 'Будь ласка, встановіть бібліотеку за посиланням ' +
                // result.nativeLibraryInstallURL +
                // ' та оновіть сторінку'
              }
            }

            return this.euSign.IsInitialized();
          })
          .then((result) => {
            if (result) {
              resolve();
              return;
            }

            return this.euSign.Initialize(this.euSettings);
          })
          .then(() => {
            this.initializeMediaLibrary();
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    initializeMediaLibrary() {
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.open('GET', './js/data/CACertificates.p7b', false); // false for synchronous request
      xmlHttp.send(null);

      euAgentHelper.initialize(
        {
          certificates: xmlHttp.responseText,
          casServers: CAs,
          lang: 'en',
          storagePrefix: '/',
          getAutoDetect: () => false,
          onDetectCaFailed: (error) => {
            console.log('onDetectCaFailed');
          },
          onError: (error) => {
            console.log('>>>> initializeMediaLibrary error', error);
            this.readingKeyError = true;
            this.readingKeyErrorMsg = error.msg;

            // this.setReadingHardwareKeyError(error.message);
          },
          onWarning: (error) => {
            console.log('onWarning');
          },
          onSuccess: (result) => {
            console.log('onSuccess');
          },
        },
        (result) => {
          this.readMediaTypes();
        },
        (error) => {
          console.log(error);
        }
      );
    },

    readMediaTypes() {
      //получение всех медиатипов
      euAgentHelper.GetAllKeyAsync(
        (types) => {
          this.$store.commit('SET_KEY_MEDIA_TYPES_LIST', types);
        },
        (error) => {
          console.log('>>>> readMediaTypes error', error);
        }
      );
    },

    async encodeHardwareKey() {
      const hardwareKeyData = JSON.stringify({
        typeIndex: this.getSelectedHardwareKey.typeIndex,
        devIndex: this.getSelectedHardwareKey.deviceIndex,
        password: this.getKeyPassword,
      });

      const encodedHardwareKeyData = await this.euSign.ProtectDataByPassword(
        hardwareKeyData,
        null,
        true
      );

      return encodedHardwareKeyData;
    },

    setCaServerAsync(caServer) {
      return new Promise((resolve, reject) => {
        euAgentHelper.setCaFromObj(caServer, resolve, reject);
      });
    },

    readKeyFromMediaAsync(keyMedia) {
      return new Promise((resolve, reject) => {
        euAgentHelper.readPrivateKeyFromMedia(keyMedia, resolve, reject);
      });
    },

    async readKeyFromDevice() {
      this.$store.commit('SET_INIT_EU_SIGN', false);
      try {
        let keyMedia = new EndUserKeyMedia();

        keyMedia.typeIndex = this.getSelectedHardwareKey.typeIndex;
        keyMedia.devIndex = this.getSelectedHardwareKey.deviceIndex;
        keyMedia.password = this.getKeyPassword;

        await this.setCaServerAsync(this.getSelectedACSKForTypeHardware);
        await this.readKeyFromMediaAsync(keyMedia);

        const encodedHardwareKey = await this.encodeHardwareKey();

        euAgentHelper.getKeyInfo((result) => {
          this.hardwareKeyOwnerInfo = result;
          this.setHardwareKeyLibrary();
          this.openModal({
            keyInfo: this.hardwareKeyOwnerInfo,
          });

          this.resetValidationData();
        });

        let sign = await this.signDataByKeyMediaAsync(this.getDataToSign);
        this.$store.commit('SET_KEY_SIGN_DATA', sign);
      } catch (error) {
        console.log('>>> readKeyFromDevice error', error);
        this.readingKeyError = true;
        this.readingKeyErrorMsg = error.message;
      }
      await this.$store.commit('SET_INIT_EU_SIGN', true);
    },

    signDataByKeyMediaAsync(dataToSign) {
      return new Promise((resolve, reject) => {
        euAgentHelper.signData(dataToSign, true, resolve, reject);
      });
    },
  },
};
