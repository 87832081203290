export default {
  namespaced: true,
  state: {
    modalState: false,
    modalSliderState: false,
    modalName: '',
    props: null,
    handler: null,
    modalSize: {
      xs: 550,
      s: 650,
      m: 750,
      l: 950,
      xl: 1021,
      xxl: 1500,
    },
    currentWidth: 0,
    type: '', // ==== ??
  },
  getters: {
    getModalState: (state) => state.modalState,
    getModalSliderState: (state) => state.modalSliderState,
    getModalName: (state) => state.modalName,
    getModalProps: (state) => state.props,
    getModalWidth: (state) => state.currentWidth,
    getModalHandler: (state) => state.handler,
    getModalType: (state) => state.type, // ==== ??
  },
  mutations: {
    SET_MODAL(state, modal) {
      state.modalState = modal.state;
      state.modalName = modal.name ? modal.name : '';
      state.currentWidth = modal.size
        ? state.modalSize[modal.size]
        : state.modalSize['s'];
      state.props = modal.props;
      state.handler = modal.handler;
      state.type = modal.type; // ==== ??
    },
    SET_MODAL_SLIDER(state, modal) {
      state.modalSliderState = modal.state;
      state.props = modal.props;
    },
  },
  actions: {},
};
