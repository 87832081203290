import Vue from 'vue';
// "sum_of": []
export default {
  state: {
    selected_filters: {},

    filtersData: {
      main: [
        { name: 'std', data_name: 'std' },
        { name: 'regional', data_name: 'regional' },
        {
          name: 'activity_time',
          data_name: 'activity-time',
          filter_name: 'date_of_state_registration',
        },
      ],
      std: [
        { name: 'std_type' },
        { name: 'activity_type' },
        { name: 'empoloyee_number', filter: 'number_of_employees' },
      ],
      std_type: [
        // {
        //   name: 'tour_operators',
        //   data_name: null,
        //   isHasList: false,
        //   isCheckbox: true,
        //   sublist: null,
        // },
        // {
        //   name: 'travel_agents',
        //   data_name: null,
        //   isHasList: false,
        //   isCheckbox: true,
        //   sublist: null,
        // },
        {
          name: 'temporary_accommodation',
          data_name: null,
          isCheckbox: true,
          isHasList: false,
          sublist: [
            {
              name: 'hotels',
              filter: 'hotel_star_category',
              data_name: 'hotels',
              isHasList: true,
            },
            {
              name: 'other_accommodation',
              data_name: '',
              isHasList: true,
            },
          ],
        },
        {
          name: 'hotel_beds_number',
          filter: 'hotel_total_beds_quantity',
          data_name: 'hotel_beds_number',
          isHasList: true,
          isCheckbox: false,
          sublist: null,
        },
        {
          name: 'hotel_number_rooms',
          data_name: 'hotel_number_rooms',
          filter: 'hotel_total_sum_number_rooms',
          isHasList: true,
          isCheckbox: false,
          sublist: null,
        },
        {
          name: 'hotel_conference_halls',
          data_name: 'hotel_conference_halls',
          filter: [
            'hotel_number_of_conference_halls',
            'hotel_capacity_of_conference_halls',
          ],
          isHasList: true,
          isCheckbox: false,
          sublist: null,
        },
      ],
      activity_type: {
        name: 'activity_type',
        data_name: 'activity_type',
        filter_name: 'tour_operator_type_of_activity',
        filters: [
          { name: 'domestic_tourism' },
          { name: 'outbound_tourism' },
          { name: 'inbound_tourism' },
        ],
      },
      regional: [
        {
          name: 'location',
          data_name: 'location',
          filter_name: 'location',
          isHasList: true,
        },
        {
          name: 'katottg',
          data_name: 'katottg',
          filter_name: 'katottg',
          isHasList: true,
        },
        // {
        //   name: 'destination',
        // },
      ],
    },

    clearData: '',

    filtersCounterData: {
      totalCounter: ['std', 'regional', 'activity_time'],
      clearAll: {
        clearList: [
          'activity_type',
          'hotels',
          'hotel_beds_number',
          'hotel_number_rooms',
          'hotel_conference_halls',
          'number_of_employees',
          'empoloyee_number',
          'temporary_accommodation',
          'std_type',
          'std',
          'tour_operator_type_of_activity',
        ],
        filtersList: ['tour_operator', 'hotel'],
      },
      std: {
        counter: 0,
        clearList: [
          'activity_type',
          'hotels',
          'hotel_beds_number',
          'hotel_number_rooms',
          'hotel_conference_halls',
          'number_of_employees',
          'empoloyee_number',
          'temporary_accommodation',
          'std_type',
          'std',
        ],
        filtersList: ['tour_operator', 'hotel'],
      },
      regional: {
        counter: 0,
        clearList: ['location', 'katottg', 'regional'],
      },
      activity_time: {
        counter: 0,
        countList: ['date_of_state_registration'],
        clearList: ['activity_time'],
      },
      std_type: {
        counter: 0,
        clearList: [
          'hotels',
          'hotel_conference_halls',
          'hotel_number_rooms',
          'hotel_beds_number',
          'std_type',
        ],
        filtersList: ['hotel'],
      },
      activity_type: {
        counter: 0,
        countList: ['tour_operator_type_of_activity'],
        clearList: ['activity_type'],
        filtersList: ['tour_operator'],
      },
      empoloyee_number: {
        counter: 0,
        countList: ['number_of_employees'],
        clearList: ['empoloyee_number'],
      },

      hotel_beds_number: {
        counter: 0,
        countList: ['hotel_total_beds_quantity'],
        clearList: ['hotel_beds_number'],
      },
      hotel_number_rooms: {
        counter: 0,
        countList: ['hotel_total_sum_number_rooms'],
        clearList: ['hotel_number_rooms'],
      },
      hotels: {
        counter: 0,
        countList: ['hotel_star_category'],
        clearList: ['hotels'],
      },
      other_accommodation: {
        counter: 0,
        countList: [],
      },
      temporary_accommodation: {
        counter: 0,
        clearList: ['hotels', 'temporary_accommodation'],
      },
      hotel_conference_halls: {
        counter: 0,
        countList: [
          'hotel_number_of_conference_halls',
          'hotel_capacity_of_conference_halls',
        ],
        clearList: ['hotel_conference_halls'],
      },
      hotel_total_sum_number_rooms: {
        counter: 0,
        countToList: ['std_type', 'std'],
      },
      hotel_total_beds_quantity: {
        counter: 0,
        countToList: ['std_type', 'std'],
      },
      hotel_number_of_conference_halls: {
        counter: 0,
        countToList: ['std_type', 'std'],
      },
      hotel_capacity_of_conference_halls: {
        counter: 0,
        countToList: ['std_type', 'std'],
      },
      hotel_star_category: {
        counter: 0,
        countToList: ['temporary_accommodation', 'std_type', 'std'],
      },
      tour_operators: { counter: 0, countList: null },
      travel_agents: {
        counter: 0,
      },
      tour_operator_type_of_activity: {
        counter: 0,
        countList: ['domestic_tourism', 'outbound_tourism', 'inbound_tourism'],
        countToList: ['activity_type', 'std'],
      },
      domestic_tourism: { counter: 0, countToList: ['activity_type', 'std'] },
      outbound_tourism: { counter: 0, countToList: ['activity_type', 'std'] },
      inbound_tourism: { counter: 0, countToList: ['activity_type', 'std'] },
      number_of_employees: {
        counter: 0,
        countToList: ['std'],
      },
      location: {
        counter: 0,
        countList: [
          'location_state',
          'location_district',
          'location_settlement',
        ],
        clearList: [
          'location_state',
          'location_district',
          'location_settlement',
          'location',
        ],
      },
      katottg: {
        counter: 0,
        countList: ['location_katottg'],
        clearList: ['location_katottg', 'katottg'],
      },
      destination: { counter: 0 },
      location_state: { counter: 0, countToList: ['location', 'regional'] },
      location_district: { counter: 0, countToList: ['location', 'regional'] },
      location_settlement: {
        counter: 0,
        countToList: ['location', 'regional'],
      },
      location_katottg: { counter: 0, countToList: ['katottg', 'regional'] },
      date_of_state_registration: {
        counter: 0,
        countToList: ['activity_time'],
      },
    },

    clearBlock: [],
  },

  getters: {
    getCurrentFilter: (state) => {
      let obj = {};
      Object.keys(state.selected_filters);
      for (let key in state.selected_filters) {
        obj[`filter[${key}]`] = !!state.selected_filters[key]
          ? state.selected_filters[key]
          : '';
      }
      return obj;
    },

    getfiltersData: (state) => state.filtersData,

    getCountersQuantity: (state) => {
      let res = 0;
      state.filtersCounterData.totalCounter.map((el) => {
        res += state.filtersCounterData[el].counter;
      });

      return res;
    },

    getFiltersCounterData: (state) => state.filtersCounterData,

    isClearBlock: (state) => state.clearBlock, //=== rename

    getClearData: (state) => state.clearData,
  },

  mutations: {
    SET_FILTER(state, payload) {
      const { filter, name, value } = payload;

      if (!!value) {
        if (!!filter && !(filter in state.selected_filters)) {
          Vue.set(state.selected_filters, filter, null);
        }

        if (!(name in state.selected_filters)) {
          Vue.set(state.selected_filters, name, value);
        } else {
          state.selected_filters[name] = value;
        }
      } else {
        Vue.delete(state.selected_filters, name);
        if (!!!name) {
          Vue.delete(state.selected_filters, filter);
        }
      }

      if (
        Object.keys(state.selected_filters).length === 1 &&
        Object.keys(state.selected_filters)[0] === 'hotel'
      ) {
        Vue.delete(state.selected_filters, 'hotel');
      }

    },

    SET_COUNTER(state, { field_name, counter_name, isSet = true }) {
      state.filtersCounterData[field_name].counter = isSet ? 1 : 0;

      let currentCounter = 0;
      state.filtersCounterData[counter_name].countList.map((c) => {
        currentCounter += state.filtersCounterData[c].counter;
      });

      state.filtersCounterData[field_name].countToList.map((l) => {
        let val = state.filtersCounterData[l].counter;
        val = isSet ? val + 1 : val - 1;

        state.filtersCounterData[l].counter = val;
      });

      state.filtersCounterData[counter_name].counter = currentCounter;
    },

    SET_CLEAR_DATA(state, name) {
      state.clearData = name;
    },

    RESET_COUNTERS(state, name) {
      state.filtersCounterData[name].counter = 0;
    },
  },

  actions: {
    async clearCurrentFilterSection({ commit, dispatch, getters }, name) {
      await commit('SET_CLEAR_DATA', name);
      getters.getFiltersCounterData[name].clearList.map((l) => {
        commit('RESET_COUNTERS', l);
      });

      getters.getFiltersCounterData[name]?.filtersList?.map((f) => {
        commit('SET_FILTER', {
          filter: f,
          name: null,
          value: null,
        });
      });

      let isClearAllCounters = await dispatch('isClearAllCounters', name);

      if (isClearAllCounters) {
        await commit('SET_CLEAR_DATA', '');
      }
    },

    isClearAllCounters({ getters }, name) {
      return getters.getFiltersCounterData[name].clearList.every(
        (l) => getters.getFiltersCounterData[l].counter === 0
      );
    },
  },
};
