<template>
  <div class="modal__container-inner">
    <iframe
      :src="getCurUrl"
      width="100%"
      height="475"
      style="border: 0"
      loading="lazy"
      frameborder="0"
      allowfullscreen="true"
    ></iframe>
    <!-- {{ getModalProps }} -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters('modal', ['getModalProps', 'getModalHandler']),
    ...mapGetters(['isRequestLoaded']),

    getCurUrl() {
      return this.getModalProps.url;
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__container-inner
  padding: 0 24px
</style>
