import { Axios, API_VERSION } from '@/api/config';

// export const getAuthUserInfo = () => {
//   const url = `${API_VERSION}/authorization/user`;
//   return Axios.get(url);
// };

export const getTableOfVotingHistoryPresiding = (data) => {
  const url = `${API_VERSION}/presiding-commissioner/hotels/history-voting/table`;
  return Axios.get(url, { params: data });
};

export const getTableOfVotingPresiding = (data) => {
  const url = `${API_VERSION}/presiding-commissioner/hotels/voting/table`;
  return Axios.get(url, { params: data });
};

export const getCategorizationPresiding = (uuid) => {
  const url = `${API_VERSION}/presiding-commissioner/hotels/categorization-applications?uuid=${uuid}`;
  return Axios.get(url);
};

export const getCommentsPresiding = (uuid) => {
  const url = `${API_VERSION}/presiding-commissioner/hotels/categorization-applications/comments?categorization_application_uuid=${uuid}`;
  return Axios.get(url);
};

export const setVotingDatePresiding = (data) => {
  const url = `${API_VERSION}/presiding-commissioner/hotels/categorization-applications/set/voting-date`;
  return Axios.post(url, data);
};

export const signVotingDatePresiding = (data) => {
  const url = `${API_VERSION}/presiding-commissioner/hotels/categorization-applications/voting-date/sign?uuid=${data.categorization_application_uuid}`;
  return Axios.post(url, data);
};

export const confirmVotePresiding = (payload) => {
  const url = `${API_VERSION}/presiding-commissioner/hotels/voting/to-vote`;
  return Axios.post(url, payload);
};

export const addCommentPresidingCommissioner = (data) => {
  const url = `${API_VERSION}/presiding-commissioner/hotels/categorization-applications/comments`;
  return Axios.post(url, data);
};

export const getReportsTablePresidingCommissioner = (data) => {
  const url = `${API_VERSION}/presiding-commissioner/tour-operators/reports/table`;
  return Axios.get(url, { params: data });
};
