<template>
  <div
    :key="keyForReload"
    class="text-field text-field__container"
    :class="{
      'text-field_disabled': isDisabled,
      'text-field_readonly': isReadOnly,
      'text-field_inactive': isInactive,
      'text-field_error': isError,
      'no-underline': noUnderline,
      'text-field_active': focusField,
    }"
  >
    <span
      class="text-field__placeholder text-field__label"
      :class="{
        'text-field_required': isRequired,
        'text-bold': boldReport,
      }"
    >
      {{ title }}
    </span>
    <div v-if="isRate" class="rate__wrapper">
      <star-rating
        v-model="valueModel"
        :rating="rateValue"
        :active-on-click="true"
        :read-only="starsReadOnly"
        :star-size="18"
        :fixed-points="1"
        :show-rating="false"
        :padding="8"
        :border-width="2"
        border-color="#6B6B6B"
        active-border-color="#FFB33B"
        active-color="#FFB33B"
        inactive-color="#fff"
      />
    </div>
    <div v-else :class="{ 'text-field_isIcon': isIcon && !!iconName.length }">
      <svg-icon
        v-if="isIcon && !!iconName.length"
        :name="iconName"
        class="text-field__icon"
      />
      <masked-input
        v-model="valueModel"
        :key="keyForReload"
        :placeholder="getPlaceholder"
        autocomplete="off"
        class="text-field__input"
        :class="{ 'text-bold': boldReport }"
        :type="isPassword ? typeInput : type"
        :autofocus="false"
        :mask="computedMask"
        :guide="true"
        :disabled="isDisabled || isInactive"
        @focus="focusField = true"
        @blur="unfocus"
      >
      </masked-input>
    </div>
    <Comments
      v-if="isCommentable"
      :uuid="uuid"
      :can-comment="canComment"
      :commentTo="commentTo"
    />
    <!-- <div v-else :class="{ 'text-field_isIcon': isIcon && !!iconName.length }">
      <svg-icon
        v-if="isIcon && !!iconName.length"
        :name="iconName"
        class="text-field__icon"
      />
      <input
        :id="id"
        v-model="valueModel"
        :placeholder="placeholder"
        class="text-field__input"
        :type="isPassword ? typeInput : type"
        :disabled="isDisabled"
        autocomplete="off"
        :autofocus="false"
        @focus="focusField = true"
        @blur="unfocus"
      />
    </div> -->
    <div
      v-if="isPassword"
      class="text-field__password"
      @click="typeInutHandler"
    >
      <svg-icon
        :name="isTypePassword ? 'eye-closed' : 'eye'"
        class="text-field__icon-pass"
      />
    </div>
    <div v-if="isError" class="error-message" v-html="!!errorText.length ? errorText : $t('error.required_field')">
      <!-- {{ !!errorText.length ? errorText : $t('error.required_field') }} -->
    </div>
  </div>
</template>

<script>
import Comments from '@/elements/Comments.vue';
import VueI18n from '@/i18n';
import maskSettings from '@/mixins/maskSettings.js';
import StarRating from 'vue-star-rating';
import MaskedInput from 'vue-text-mask';

export default {
  components: {
    StarRating,
    MaskedInput,
    Comments,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    uuid: {
      type: String,
      default: '',
    },
    isRate: {
      type: Boolean,
      default: false,
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    isCommentable: {
      type: Boolean,
      default: false,
    },
    canComment: {
      type: Boolean,
      default: false,
    },
    commentTo: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    isInactive: {
      type: Boolean,
      default: false,
    },
    starsReadOnly: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInteger: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isPassword: {
      type: Boolean,
      default: false,
    },
    valueMask: {
      type: String,
      default: '',
    },
    rateValue: {
      type: [String, Number],
      default: 0,
    },
    id: {
      type: [String, Number],
      default: 0,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      // value of input
      type: [String, Number],
      default: '',
    },
    errorText: {
      type: String,
      default: VueI18n.t('error.required_field'),
    },
    noUnderline: {
      type: Boolean,
      default: false,
    },
    boldReport: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    isTranslate: {
      type: Boolean,
      default: false,
    },
    translateFrom: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      maskSettings,
      focusField: false,
      typeInput: '',
      keyForReload: 0,
    };
  },

  mounted() {
    this.typeInput = this.isPassword ? 'password' : this.type;
  },

  computed: {
    computedMask() {
      if (this.valueMask) {
        return maskSettings[this.valueMask];
      }
      if (this.isInteger) {
        return maskSettings['integer'];
      }
      return false;
    },
    getPlaceholder() {
      return this.focusField ? '' : this.placeholder;
    },

    valueModel: {
      get() {
        if (this.isDisabled) this.updateKeyForReload();

        if (this.isTranslate && !!this.translateFrom.length && !!this.value) {
          return this.$t(`${this.translateFrom}.${this.value}`);
        }
        if (this.isRate) {
          return +this.value;
        } else {
          return this.value;
        }
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    isTypePassword() {
      return this.typeInput === 'password';
    },
  },

  watch: {
    value: {
      handler(val) {
        this.focusField = !!val && !!String(val).length && !this.isDisabled;
      },
      immediate: true,
    },
  },

  methods: {
    updateKeyForReload() {
      this.keyForReload += 1;
    },
    unfocus() {
      this.focusField = !!this.valueModel?.length || !!this.value?.length;
    },
    typeInutHandler() {
      this.typeInput = this.isTypePassword ? 'text' : 'password';
    },
  },
};
</script>

<style lang="sass">
.tooltip
  cursor: pointer
  display: none
  position: absolute
  right: 13px
  top: 0
  width: 330px
  box-shadow: 0px 4px 14px rgb(0 0 0 / 8%)
  border-radius: 12px
  background-color: $white
  z-index: 10
  padding: 20px 10px

.tooltip__text
  color: $gray
  font-size: 12px
  line-height: 24px
  padding: 20px 0

.tooltip__add
  font-size: 14px
  line-height: 24px
  padding: 20px 0 10px 0

.tooltip__text--small
  display: flex
  border-bottom: 1px solid #D5D5D6
  margin: 10px 0
  font-size: 12px
  padding: 10px 0

.tooltip__title
  padding-bottom: 10px
  border-bottom: 1px solid #D5D5D6

.tooltip__input
  padding-bottom: 10px
  border-bottom: 2px solid $gray
  width: 100%

.tooltip__icon, .tooltip__icon-empty
  position: absolute
  top: 0
  right: 0
  cursor: pointer

.tooltip__cross
  position: absolute
  top: 5px
  right: 5px
  cursor: pointer
  height: 20px !important

.tooltip__icon-empty .svg-icon
  height: 25px

.tooltip__btn
  margin: 25px auto
  width: 100%

.active
  display: block

.tooltip__add
  border-top: 2px solid $gray
  margin-top: 15px

.text-field__container
  border-bottom: 2px solid $gray
  // border: solid 1px red
  margin-bottom: 18px
  position: relative
  &.text-field_error
    border-bottom-color: $error_color

.text-field_disabled
  opacity: 0.5
  .text-field__input
    cursor: not-allowed

.text-field_readonly
  .text-field__input
    pointer-events: none

.text-field_inactive

.text-field__input
  width: 100%
  height: 40px
  font-size: 14px
  &::placeholder
    color: $gray

.text-field_active
  border-color: $black
  .text-field__icon-pass.svg-icon
    fill: $black

.textField__input-focused
  border-bottom: 2px solid $black
  position: absolute
  top: 5px
  padding-top: 10px

.text-field__label
  display: block

.text-field__placeholder
  color: $draft
  font-size: 14px
  line-height: 18px
  @include xs
    font-size: 12px
    line-height: 16px

.text-field__icon-pass.svg-icon
  width: 30px
  // height: 100%
  // max-height: 18px
  max-width: 30px
  position: absolute
  bottom: 5px
  right: 0
  // transform: translateY(-50%)
  fill: $gray

.text-field__icon
  width: 18px !important
  margin-right: 10px

.text-field_isIcon
  display: flex
  align-items: center

.text-field_required
  &:after
    content: "*"
    font-size: 1.8rem
    color: $error

.text-field_stars
  border: none

.text-bold
  font-weight: bold
  color: $black

.error-message
  position: absolute
  padding-top: 5px !important
  font-size: 12px
  color: $error_color

.input_stars
  position: relative
  top: -20px
  display: flex
  flex-direction: column
  gap: 12px
  color: #7F7F7F
  font-size: 14px

.input_selector
  position: relative
  top: -20px
  display: flex
  flex-direction: column
  gap: 12px
  color: #7F7F7F
  font-size: 14px
  width: 100%
  z-index: 20

.rate__wrapper
  height: 40px
  display: flex

.no-underline
  border-bottom: none
</style>
