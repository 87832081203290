import { API_VERSION, Axios } from '@/api/config';

export const getNewsData = (data) => {
  const url = `${API_VERSION}/web-site/news`;
  return Axios.get(url, { params: data });
};

export const getLatestNewsData = () => {
  const url = `${API_VERSION}/web-site/news/recent`;
  return Axios.get(url);
};

export const getAllTags = () => {
  const url = `${API_VERSION}/web-site/news/tags`;
  return Axios.get(url);
};
