<template>
  <div class="modal__container-inner">
    <div class="certificate">
      <h3 class="title-h4 certificate__title">
        {{ $t('modals.basis_for_certificate') }}
      </h3>
      <div class="certificate__wrapper">
        <div class="certificate__form" ref="form">
          <text-field
            :title="$t('categorization.name_of_accommodation')"
            v-model="form_data.name_of_accommodation"
            placeholder="-"
            class="certificate__field certificate__field_full"
          />
          <text-field
            :title="$t('profile.name_of_legal_entity')"
            v-model="form_data.name_of_legal_entity"
            placeholder="-"
            class="certificate__field certificate__field_full"
          />
          <text-field
            :title="$t('profile.star_category')"
            isRate
            isInactive
            v-model="form_data.star_category"
            class="certificate__field certificate__field_one-third"
          />
          <text-field
            :title="$t('categorization.number_of_rooms')"
            v-model="form_data.number_of_rooms"
            isInactive
            placeholder="-"
            class="certificate__field certificate__field_one-third"
          />
          <text-field
            :title="$t('categorization.number_of_places')"
            v-model="form_data.number_of_places"
            isInactive
            placeholder="-"
            class="certificate__field certificate__field_one-third"
          />
          <text-field
            :title="$t('profile.address_title')"
            v-model="form_data.address"
            placeholder="-"
            class="certificate__field certificate__field_full"
          />
          <text-field
            :title="$t('modals.basis_for_certificate')"
            v-model="form_data.grounds_for_granting"
            placeholder="-"
            isRequired
            :is-error="this.$v.form_data.grounds_for_granting.$error"
            class="certificate__field certificate__field_full"
          />
          <text-field
            v-model="form_data.registered_number"
            :title="$t('modals.registry_number')"
            placeholder="-"
            isRequired
            :is-error="this.$v.form_data.registered_number.$error"
            class="certificate__field certificate__field_full"
          />
          <div class="certificate__date">
            <div class="required_date-picker">
              <DatePicker
                @open="onDatePickerOpen"
                @close="onDatePickerClose"
                v-model="form_data.certificate_term_start"
                :format="'DD-MM-YYYY'"
                value-type="YYYY-MM-DD HH:mm:ss"
                :lang="getDateConfig"
                :placeholder="$t(`profile.certificate_term_start`)"
                :class="{
                  'is-error': this.$v.form_data.certificate_term_start.$error,
                }"
              ></DatePicker>
            </div>
            <div
              class="error-message"
              v-if="this.$v.form_data.certificate_term_start.$error"
            >
              {{ $t('error.required_field') }}
            </div>
          </div>
          <div class="certificate__date">
            <div class="required_date-picker">
              <DatePicker
                @open="onDatePickerOpen"
                @close="onDatePickerClose"
                v-model="form_data.certificate_term_end"
                :format="'DD-MM-YYYY'"
                value-type="YYYY-MM-DD HH:mm:ss"
                :disabled="!isStrartDay"
                :disabled-date="disabledDatesTo"
                :lang="getDateConfig"
                :placeholder="$t(`profile.certificate_term_end`)"
                :class="{
                  'is-error': this.$v.form_data.certificate_term_end.$error,
                }"
              />
            </div>
            <div
              class="error-message"
              v-if="this.$v.form_data.certificate_term_end.$error"
            >
              {{ $t('error.required_field') }}
            </div>
          </div>
          <text-field
            :title="$t('modals.certificate_validity')"
            v-model="form_data.certificate_validity"
            placeholder="-"
            isInteger
            isRequired
            :is-error="this.$v.form_data.certificate_validity.$error"
            class="certificate__field certificate__field_full"
          />
          <text-field
            :title="$t('modals.service_type')"
            v-model="form_data.service_type"
            placeholder="-"
            isRequired
            :is-error="this.$v.form_data.service_type.$error"
            class="certificate__field certificate__field_full"
          />
        </div>
      </div>
      <div class="modal__btn-2col-center">
        <Button white @click.native="closeModal">
          {{ $t('modals.cancel') }}
        </Button>
        <Button @click.native="acceptHandler">
          {{ $t('modals.confirm') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import datePickerConfig from '@/library/date-picker-config';
import DatePicker from 'vue2-datepicker';
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      form_data: {
        registered_number: '',
        certificate_term_start: '',
        certificate_term_end: '',
        certificate_validity: '',
        service_type: '',
        grounds_for_granting: '',
        star_category: '',
        name_of_accommodation: '',
        name_of_legal_entity: '',
        number_of_rooms: '',
        number_of_places: '',
        address: '',
      },
    };
  },

  async created() {
    await this.$store.dispatch(
      'getCertificateData',
      this.$store.getters.getShortDataOfVoting.hotel_categorization_application_uuid
    );

    await this.setData();
  },

  computed: {
    ...mapGetters(['getCertificateData', 'getShortDataOfVoting']),

    getDateConfig() {
      return datePickerConfig;
    },

    isStrartDay() {
      return !!this.form_data.certificate_term_start?.length;
    },

    getDataForSend() {
      let obj = {};
      for (let key in this.form_data) {
        if (!!this.form_data[key]) {
          obj[key] = this.form_data[key];
        }
      }
      return obj;
    },
  },

  methods: {
    onDatePickerOpen(e) { // Фікс встановлення фокуса при зміні року/місяця на будь який попередній інпут при overflow: auto - баг стороннього компоненту
      const inputs = this.$refs.form.querySelectorAll('input')
      inputs.forEach(input => {
        if(input != e.target) {
          input.disabled = true
        }
      })
    },
    onDatePickerClose(e) { // Фікс встановлення фокуса при зміні року/місяця на будь який попередній інпут при overflow: auto - баг стороннього компоненту
      const inputs = this.$refs.form.querySelectorAll('input')
      inputs.forEach(input => {
        input.disabled = false
      })
    },
    disabledDatesTo(date) {
      return (
        new Date(date).getTime() <=
        new Date(this.form_data.certificate_term_start).getTime()
      );
    },

    setData() {
      this.form_data.star_category = this.getCertificateData.star_category;
      this.form_data.name_of_legal_entity =
        this.getCertificateData.name_of_legal_entity;
      this.form_data.name_of_accommodation =
        this.getCertificateData.name_of_accommodation;
      this.form_data.number_of_rooms = this.getCertificateData.number_of_rooms;
      this.form_data.number_of_places =
        this.getCertificateData.number_of_places;
      this.form_data.address = this.getCertificateData.address;
    },

    closeModal() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },

    acceptHandler() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let payload = {
          data: {
            categorization_application_uuid:
              this.$store.getters.getShortDataOfVoting.hotel_categorization_application_uuid,
            ...this.getDataForSend,
          },
          id: this.$route.params.id,
        };
        this.$store.dispatch('sendCertificateData', payload);
      }
    },
  },

  validations: {
    form_data: {
      grounds_for_granting: {
        required,
      },
      registered_number: {
        required,
      },
      certificate_term_start: {
        required,
      },
      certificate_term_end: {
        required,
      },
      certificate_validity: {
        required,
      },
      service_type: {
        required,
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.certificate__wrapper
  position: relative
  margin-top: 30px
  margin-bottom: 30px
  &:before, &:after
    content: ''
    width: 100%
    height: 40px
    position: absolute
    left: 0
    z-index: 20
  &:before
    top: 0
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
  &:after
    bottom: 0
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)

  @include xxs
    // width: 70%
    // margin: auto
.certificate__title
  text-align: center

.certificate__form
  padding: 20px 30px 5px 50px
  margin-right: 20px
  max-height: 50vh
  overflow-y: auto
  overflow-x: hidden
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  @include xxs
    padding-left:20px
    padding-right: 10px

.certificate__field
  margin-bottom: 34px

.certificate__field_full
  width: 100%
.certificate__field_one-third
  width: 32%
  @include s
    width: 49%
  @include xs
    width: 100%

.certificate__date
  margin-bottom: 30px
  position: relative
  @include xs
    // border: solid 1px red
    width: 100%

.mx-datepicker
  // width: auto
</style>
