import {
  getTableOfVotingDeputyPresiding,
  getCategorizationDeputyPresiding,
  getTableOfVotingHistoryDeputyPresiding,
  getCommentsDeputyPresiding,
  addCommentDeputyPresidingCommissioner,
  getReportsTableDeputyPresidingCommissioner,
} from '@/api/services/deputy_presiding_commiss.js';
import router from '@/router';
import VueI18n from '@/i18n';

export default {
  state: {
    tableOfCategorizationsDeputyPresiding: [],
    categorizationDeputyPresiding: [],
    tableOfVotingHistoryDeputyPresiding: [],
    commentsDeputyPresiding: [],
    tableOfReportsDeputyPresiding: null,
  },

  getters: {
    getTableOfVotingDeputyPresiding: (state) =>
      state.tableOfCategorizationsDeputyPresiding,
    getTableOfVotingHistoryDeputyPresiding: (state) =>
      state.tableOfVotingHistoryDeputyPresiding,
    getCategorizationDeputyPresiding: (state) =>
      state.categorizationDeputyPresiding,
    getCommentsDeputyPresiding: (state) => state.commentsDeputyPresiding,
    getTableOfReportsDeputyPresiding: (state) =>
      state.tableOfReportsDeputyPresiding,
  },

  mutations: {
    SET_CATEGORIZATION_TABLE_DEPUTYPRESIDING(state, data) {
      state.tableOfCategorizationsDeputyPresiding = data;
    },
    SET_TABLE_OF_VOTING_DEPUTYPRESIDING(state, data) {
      state.tableOfVotingHistoryDeputyPresiding = data;
    },
    SET_CATEGORIZATION_DEPUTYPRESIDING(state, data) {
      state.categorizationDeputyPresiding = data;
    },
    SET_COMMENTS_DEPUTYPRESIDING(state, data) {
      state.commentsDeputyPresiding = data;
    },
    SET_TOUR_OPERATOR_TABLE_DEPUTY_PRESIDING(state, data) {
      state.tableOfReportsDeputyPresiding = data;
    },
  },

  actions: {
    async getTableOfVotingDeputyPresiding({ commit, getters }) {
      try {
        let res;
        res = await getTableOfVotingDeputyPresiding(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_CATEGORIZATION_TABLE_DEPUTYPRESIDING', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfCategorizations',
          e.response.data.error
        );
      }
    },
    async getTableOfVotingHistoryDeputyPresiding({ commit, getters }) {
      try {
        let res;
        res = await getTableOfVotingHistoryDeputyPresiding(
          getters.getTableSortData
        );
        if (res.status === 200) {
          commit('SET_TABLE_OF_VOTING_DEPUTYPRESIDING', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfVotingHistoryDeputyPresiding',
          e.response.data.error
        );
      }
    },
    async getCategorizationDeputyPresiding({ commit }, uuid) {
      let res;
      try {
        res = await getCategorizationDeputyPresiding(uuid);
        commit(
          'SET_CATEGORIZATION_DEPUTYPRESIDING',
          res.data.data.categorization_application
        );
      } catch (e) {
        console.log('>>> getCategorizationDeputyPresiding error', e);
      }
    },
    async getCommentsDeputyPresiding({ commit }, uuid) {
      let res;
      try {
        res = await getCommentsDeputyPresiding(uuid);
        commit('SET_COMMENTS_DEPUTYPRESIDING', res.data.data.criterias);
      } catch (e) {
        console.log('>>> getCommentsDeputyPresiding error', e);
      }
    },

    async addCommentDeputyPresidingCommissioner({ commit }, payload) {
      let res;
      const {
        categorization_application_uuid,
        criterion_uuid,
        comment: message,
      } = payload;
      try {
        res = await addCommentDeputyPresidingCommissioner({
          categorization_application_uuid,
          criterion_uuid,
          message,
        });
        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.sent'));
        }
      } catch (e) {
        console.log('>>> addCommentDeputyPresidingCommissioner error', e);
      }
    },

    async getReportsTableDeputyPresidingCommissioner({ commit, getters }) {
      try {
        let res;
        res = await getReportsTableDeputyPresidingCommissioner(
          getters.getTableSortData
        );
        if (res.status === 200) {
          commit('SET_TOUR_OPERATOR_TABLE_DEPUTY_PRESIDING', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log('>>> error getReportsTableDeputyPresidingCommissioner', e);
      }
    },
  },
};
