<template>
  <div class="footer footer__wrapper">
    <div class="footer__container block_container">
      <div class="footer__logo">
        <svg-icon name="arms" class="icon-arms" />
        <div class="footer__title">{{ $t(`footer.title`) }}</div>
      </div>
      <footer class="footer__content">
        <div class="footer__nav">
          <div class="footer__list_wrapper">
            <ul class="footer__list">
              <div class="footer__list__item-title">
                {{ $t(`footer.graph`) }}
              </div>
              <router-link
                v-for="item in pages"
                :key="item.content"
                :to="{ name: item.path, hash: item.hash }"
              >
                <li class="footer__list__item">
                  {{ $t(`footer.${item.content}`) }}
                </li>
              </router-link>
            </ul>
            <ul class="footer__list">
              <li class="footer__list__item-title">
                {{ $t(`footer.adress`) }}
              </li>
              <a href="https://goo.gl/maps/c65bW5tysq3Pd3NF8" target="_blank">
                <li class="footer__list__item">
                  {{ $t(`contact_info.address`) }}
                </li>
              </a>
            </ul>
          </div>

          <ul class="footer__list_logo">
            <li class="footer__list__item-title">
              {{ $t(`footer.useful_links`) }}
            </li>
            <a href="https://www.tourism.gov.ua/" target="_blank">
              <svg-icon name="dart-logo" />
            </a>
          </ul>
        </div>
      </footer>
      <div class="footer__bottom">
        <div class="footer__bottom-title">
          <div class="footer__bottom-title-all-right">
            {{ $t(`footer.footer_policy.all_rights`) }}
          </div>
          <div
            class=""
            v-html="
              $t(`footer.footer_policy.etr_copyright`, {
                current_year: currentYear,
              })
            "
          >
            <!-- {{ $t(`footer.footer_policy.etr_copyright`) }} -->
          </div>
        </div>
        <slot></slot>
        <div class="footer__bottom_icons">
          <a href="https://www.facebook.com/DARTUkraine" target="_blank">
            <svg-icon name="facebookIcon" />
          </a>
          <a
            href="https://www.linkedin.com/company/dartukraine/mycompany/"
            target="_blank"
          >
            <svg-icon name="linkedin" />
          </a>
          <a href="https://mobile.twitter.com/satd_ukraine" target="_blank">
            <svg-icon name="twitter" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pages: [
        {
          path: 'landing-page',
          hash: '#news',
          content: 'graph_news',
        },
        {
          path: 'landing-page',
          hash: '#statistic',
          content: 'graph_statistic',
        },
        {
          path: 'search-results',
          hash: '',
          content: 'graph_register',
        },
        {
          path: 'landing-page',
          hash: '#questions',
          content: 'graph_question',
        },
        {
          path: 'contacts',
          hash: '',
          content: 'graph_contacts',
        },
      ],
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="sass" scoped>
.footer__wrapper
  padding: 34px 6.4rem

.footer__container
  color: $white
  @include s
    padding: 0 20px

  @include xxs
    padding: 0 16px

  @media only screen and (min-width: 1920px)
    padding-right: 6.4rem
    padding-left: 6.4rem

.footer
  background: $black
  z-index: 101
  @include s
    padding: 20px 0
  @include xxs
    padding: 16px 0

.footer__content
  justify-content: space-between
  padding: 24px 0 20px

  @include s
    padding-top: 20px
    padding-bottom: 10px
  @include xxs
    padding-bottom: 0

.footer__logo
  display: flex
  justify-content: start
  align-items: center

.footer__title
  font-size: 18px
  margin-left: 13px
  font-weight: 300
  @include xxs
    font-size: 14px

.footer__nav
  max-width: 100%
  display: flex
  justify-content: space-between

  @include s
    max-width: 100%

  @include xxs
    flex-direction: column
    max-width: 100%

.footer__list
  display: flex
  flex-direction: column
  list-style-type: none
  padding: 0

  @include s
    margin-right: 15px
    &:not(&:last-child)
      margin-bottom: 20px

  @include xxs
    margin-bottom: 20px

.footer__list__item-title
  color: $white
  padding-bottom: 12px
  font-size: 16px

  &:last-child
    padding: 0

  @include s
    font-size: 14px
    line-height: 18px

.footer__list__item
  color: $gray_footer
  padding-bottom: 8px
  font-weight: 350

a:hover .footer__list__item
  text-decoration: underline

.footer__list_logo
  display: flex
  flex-direction: column
  align-items: end
  @include xs
    align-items: start
    padding-bottom: 30px

.footer__bottom-title
  font-size: 12px
  color: $white
  opacity: 0.5

  @include xxs
    // width: 100%

.footer__bottom-title-all-right
  margin-bottom: 5px
  @include xxs
    margin-bottom: 0

.footer__bottom
  display: flex
  justify-content: space-between
  @include xxs
    flex-direction: column-reverse

.footer__bottom_icons
  display: flex
  gap: 40px
  align-items: center
  @include xxs
    margin-bottom: 16px

.footer__bottom_icons a svg
  width: 100%
  height: 100%
  max-width: 32px
  max-height: 32px

.footer__list_wrapper
  display: flex
  gap: 120px
  @include s
    gap: 60px
  @include xs
    gap: 0px
    flex-direction: column

.icon-arms
  width: 20px
  height: 31px
  border: none
  border-radius: 0
  fill: $white
  padding: 0
  margin: 0

.icon-facebookIcon
  width: 24px
  height: 24px

.icon-dart-logo
  fill: $white
  min-width: 114px
</style>
