<template>
  <!-- v-click-outside="clearSearch" -->
  <div class="input-search input-search__form">
    <label class="input-search__wrapper">
      <input
        id="input-search"
        v-model="searchItem"
        :placeholder="placeholder"
        class="input-search__list"
        :class="{ active: isActive, 'no-border': noBorder }"
        autocomplete="off"
        transition="slide-y-transition"
        @focus="isActive = true"
        @blur="unfocus"
        @input="detectValue"
        @keyup.enter="detectEnter"
      />
      <div
        v-if="!!searchItem.length"
        class="input-search__btn-clear"
        @click="clearSearch"
      >
        <svg-icon name="close" class="input-search__ico-clear" />
      </div>
      <svg-icon v-if="!searchItem.length" name="magnifier" />
    </label>
    <div v-if="areOptionsVisible && !isFilter" class="input-search__block">
      <div class="input-search__content">
        <ul v-if="!!searchResultData.length">
          <li
            v-for="item in searchResultData"
            :key="item.id"
            class="input-search__content-item"
            @click="addItem(item)"
          >
            {{ item.name }}
          </li>
        </ul>
        <p v-else>Нічого не знайдено</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isFilter: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Пошук',
    },
    searchResultData: {
      type: [Array],
      default: () => [],
    },
  },
  data() {
    return {
      searchItem: '',
      isActive: false,
      areOptionsVisible: false,
    };
  },
  computed: {
    ...mapGetters(['getTableSortData']),

    searchValue: {
      //======== maybe later
      get() {
        return this.getTableSortData;
      },
      set(val) {
        this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      },
    },

    resultSeachItem() {
      if (this.searchItem?.length < 1) {
        return this.areOptionsVisible;
      }
      return this.searchItem?.length > 0 && this.filterArray?.length === 0;
    },
  },
  watch: {
    searchItem(newValue) {
      if (newValue) {
        this.areOptionsVisible = true;
      } else {
        this.areOptionsVisible = false;
      }
    },
  },
  methods: {
    clearSearch() {
      this.searchItem = '';
      this.$store.commit('SET_TABLE_SORT_DATA', { page: 1 });
      this.$emit('detectSearchValue', this.searchItem);
    },
    addItem(item) {
      this.$emit('getItemId', item.id);
      // this.$router.push({ name: 'search-results', query: this.searchItem });
      this.$router.push({
        name: 'search-results',
        params: {
          query: this.searchItem,
        },
      });
    },
    unfocus() {
      this.isActive = !!this.searchItem.length;
    },
    detectValue() {
      this.$store.commit('SET_TABLE_SORT_DATA', { page: 1 });
      this.$emit('detectSearchValue', this.searchItem);
    },
    detectEnter() {
      if (this.$route.path === '/') {
        this.$router.push({ name: 'search-results' });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.input-search
  font-size: 1.6rem
  color: black
  font-weight: 400
  max-width: 100%
  // margin-bottom: 48px
  @include xxs
    font-size: 12px

.input-search__list
  border-bottom: 2px solid $gray
  line-height: 3
  padding-left: 4em
  min-width: 260px
  width: 100%
  @include s
    text-align: left
    padding-left: 5rem
  @include xxs
    padding-left: 4rem

.input-search__list::placeholder
  color: $gray
.input-search__form
  position: relative
  height: 50px
  @include xs
    margin-bottom: 20px
    height: 40px

.input-search__ico-clear
  fill: $gray
  width: 16px
  height: 16px
  position: static
  transition: fill 0.3s

.input-search__btn-clear
  position: absolute
  display: inline-flex
  justify-content: center
  align-items: center
  top: 50%
  transform: translateY(-50%)
  left: 10px
  width: 30px
  height: 30px
  border-radius: 50%
  cursor: pointer
  transition: all 0.3s
  &:hover
    background: $white
    transition: all 0.3s

    .input-search__ico-clear
      fill: $black
      transition: fill 0.3s

.icon-magnifier
  width: 20px
  height: 18px
  position: absolute
  top: 15px
  left: 0em
  fill: $gray
  margin: 0 1em
  @include xxs
    width: 16px
    height: 16px
    top: 9px

.active
  border-bottom: 2px solid $black
// border-radius: 12px

.no-border
  border-bottom: none

.active + .icon-magnifier
  fill: $black

.header__search_container .input-search__wrapper .input-search__btn-clear
  right: 10px
  left: auto

.input-search__block
  background-color: $white
  border-radius: 12px
  padding-left: 24px
  padding-top: 30px
  position: relative
  z-index: 2
  margin-top: 12px
  box-shadow: 0px 0px 40px rgb(38 55 76 / 12%)
  border: 2px solid rgba(0, 0, 0, 0.05)
  // &::before
  //   content: ''
  //   position: absolute
  //   height: 80px
  //   width: 80%
  //   left: 22px
  //   bottom: 0
  //   z-index: 0
  //   background: linear-gradient(0deg, #FFFFFF 16.96%, rgba(255, 255, 255, 0.82) 44.26%, rgba(248, 248, 248, 0) 106.82%)

.input-search__content
  overflow: auto
  max-height: 250px
  margin-bottom: 37px
  margin-right: 18px

.input-search__content-item
  cursor: pointer
  font-size: 14px
  line-height: 24px

.input-search__content-item:not(:first-child)
  padding-top: 24px

.input-search__content::-webkit-scrollbar
  width: 4px
  background-color: #DCDCDC

.input-search__content::-webkit-scrollbar-thumb
  background-color: $black
</style>
