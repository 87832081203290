<template>
  <div class="modal__container-inner">
    <iframe
      :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyBdykJfeFKjKXxEZkyaR4-rMCFDdc6Kh-A&q=${getModalProps}`"
      width="100%"
      height="475"
      style="border: 0"
      loading="lazy"
    ></iframe>
    <!-- {{ getModalProps }} -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters('modal', ['getModalProps', 'getModalHandler']),
    ...mapGetters(['isRequestLoaded']),
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__container-inner
  padding: 0 24px
</style>
