<template>
  <div class="eu-key eu-key__wrapper">
    <div class="eu-key__items-wrapper" v-if="isUploadKey">
      <Button
        v-for="tab in getKeysTypes"
        :key="tab.id"
        :iconName="tab.icon"
        :white="getSelectedKeyType.id !== tab.id"
        :disabled="!isInitEuSign || getSelectedKeyType.id === tab.id"
        @click.native="setSelectedKeyType(tab)"
        class="eu-key__item"
        :class="{ active: getSelectedKeyType.id === tab.id }"
      >
        {{ $t(`modals.${tab.title}`) }}
      </Button>
    </div>
    <component
      :is="currentComponent"
      :isInit="isInit"
      @initStatus="(val) => (isInit = val)"
    />
    <!-- v-if="!isUserHasEuKey || isAuthForm" -->
    <Button
      v-if="isUploadKey"
      :disabled="!isInitEuSign"
      :isRightIcon="isAuthForm"
      class="eu-key__btn"
      :class="{ 'eu-key__btn-login': isAuthForm }"
      @click.native="isInit = true"
    >
      <template v-if="isInitEuSign">
        {{ $t('buttons.read_key') }}
      </template>
      <LoadingSpinner v-else />
    </Button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import euFileKey from '@/elements/euKey/euFileKey.vue';
import euHardwareKey from '@/elements/euKey/euHardwareKey.vue';
import euAddedKey from '@/elements/euKey/euAddedKey.vue';

export default {
  components: {
    euFileKey,
    euHardwareKey,
    euAddedKey,
  },

  props: {
    isUploadKey: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isInit: false,
    };
  },

  created() {
    this.$store.commit('SET_SELECTED_KEY_TYPE', this.getKeysTypes[0]);
  },

  computed: {
    ...mapGetters([
      'getKeysTypes',
      'isUserHasEuKey',
      'isInitEuSign',
      'getSelectedKeyType',
      'getCurrentFormType',
    ]),

    isAuthForm() {
      return (
        this.getCurrentFormType?.type === 'auth' &&
        this.getCurrentFormType?.isSet
      );
    },

    currentComponent() {
      let name = '';
      if (!this.isUploadKey) {
        name = 'eu-added-key';
      } else {
        if (this.getSelectedKeyType?.type === 'file-key') {
          name = 'eu-file-key';
        } else if (this.getSelectedKeyType?.type === 'hardware-key') {
          name = 'eu-hardware-key';
        }
      }
      return name;
    },
  },

  methods: {
    setSelectedKeyType(value) {
      this.$store.commit('SET_SELECTED_KEY_TYPE', value);
      this.$store.commit('SET_INIT_EU_SIGN', false);
    },
  },
};
</script>
<style lang="sass" scoped>
.eu-key
  &__item
    margin-bottom: 16px
  
    &.active
      color: $white
      background-color: $black
      cursor: default
</style>
