import { Axios, API_VERSION } from '@/api/config';

export const getSearchResults = (param) => {
  const url = `${API_VERSION}/web-site/registry/search/basic?query=${param}`;
  return Axios.get(url);
};

export const getSearchResultsDetails = (type, id) => {
  const url = `${API_VERSION}/web-site/registry/std/${type}?uuid=${id}`;
  return Axios.get(url);
};

export const savedTourismReports = (type) => {
  const url = `${API_VERSION}/web-site/statistics/table/tourism-reports`;
  return Axios.get(url, { params: { type_of_tourism: type } });
};

export const statisticsTableTourism = (data) => {
  const url = `${API_VERSION}/web-site/statistics/table`;
  return Axios.get(url, { params: { ...data } });
};

export const getPresentations = (data) => {
  const url = `${API_VERSION}/web-site/presentations/table`;
  return Axios.get(url, { params: data });
};

export const getResearchFiles = (data) => {
  const url = `${API_VERSION}/web-site/research-files/table`;
  return Axios.get(url, { params: data });
};

export const getHotelsByRegions = (data) => {
  const url = `${API_VERSION}/web-site/statistics/regions/hotels`;
  return Axios.get(url, { params: data });
};

export const getTourOperatorsByRegions = (data) => {
  const url = `${API_VERSION}/web-site/statistics/regions/tour-operators`;
  return Axios.get(url, { params: data });
};

export const getTaxRevenues = () => {
  const url = `${API_VERSION}/web-site/statistics/tax/tax-revenues`;
  return Axios.get(url);
};

export const getTaxRevenuesTable = (data) => {
  const url = `${API_VERSION}/web-site/statistics/tax/tax-revenues/table`;
  return Axios.get(url, { params: data });
};

export const getTourismTax = () => {
  const url = `${API_VERSION}/web-site/statistics/tax/tourism-tax`;
  return Axios.get(url);
};

export const getTourismTaxTable = (data) => {
  const url = `${API_VERSION}/web-site/statistics/tax/tourism-tax/table`;
  return Axios.get(url, { params: data });
};

export const getTaxRevenuesTotal = () => {
  const url = `${API_VERSION}/web-site/statistics/tax/total`;
  return Axios.get(url);
};

export const getStatisticTotal = (data) => {
  const url = `${API_VERSION}/web-site/statistics/total`;
  return Axios.get(url, { params: data });
};
