import { Axios, API_VERSION } from '@/api/config';

// export const getAuthUserInfo = () => {
//   const url = `${API_VERSION}/authorization/user`;
//   return Axios.get(url);
// };

export const getTableOfVotingHistoryDeputyPresiding = (data) => {
  const url = `${API_VERSION}/deputy-presiding-commissioner/hotels/history-voting/table`;
  return Axios.get(url, { params: data });
};

export const getTableOfVotingDeputyPresiding = (data) => {
  const url = `${API_VERSION}/deputy-presiding-commissioner/hotels/voting/table`;
  return Axios.get(url, { params: data });
};

export const getCommentsDeputyPresiding = (uuid) => {
  const url = `${API_VERSION}/deputy-presiding-commissioner/hotels/categorization-applications/comments?categorization_application_uuid=${uuid}`;
  return Axios.get(url);
};

export const getCategorizationDeputyPresiding = (uuid) => {
  const url = `${API_VERSION}/deputy-presiding-commissioner/hotels/categorization-applications?uuid=${uuid}`;
  return Axios.get(url);
};

export const addCommentDeputyPresidingCommissioner = (data) => {
  const url = `${API_VERSION}/deputy-presiding-commissioner/hotels/categorization-applications/comments`;
  return Axios.post(url, data);
};

export const getReportsTableDeputyPresidingCommissioner = (data) => {
  const url = `${API_VERSION}/deputy-presiding-commissioner/tour-operators/reports/table`;
  return Axios.get(url, { params: data });
};
