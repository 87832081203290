import { table } from '@/models/filter/table';
import {
  getSearchResults,
  getSearchResultsDetails,
  getPresentations,
} from '@/api/services/landing';
import { getRegistryTableData } from '@/api/services/general';

export default {
  state: {
    filterHeaders: table.search_results?.columns,
    searchResultDetails: null,
    searchResults: [],
    isLoad: false,
    widgetColors: {
      background: [
        '#A0A2AB',
        '#DEDECC',
        '#CAD1E5',
        '#E4E8F4',
        '#F4F4E4',
        '#ABABA0',
        '#636881',
      ],
      hover: [
        '#808289',
        '#B2B2A3',
        '#A2A7B7',
        '#B6BAC3',
        '#C3C3B6',
        '#898980',
        '#4F5367',
      ],
    },

    presentations: {
      items: [],
      current_page: 0,
      to_item: 0,
      total: 0,
    },

    presentationsItems: 10,
  },

  getters: {
    getFilterHeaderData: (state) => {
      return state.filterHeaders;
    },
    getSearchResultData: (state) => {
      return state.searchResults;
    },
    getSearchResultDataDetails: (state) => {
      return state.searchResultDetails;
    },
    getLoaderSearchState: (state) => {
      return state.isLoad;
    },

    getWidgetColors: (state) => state.widgetColors,
    getPresentations: (state) => state.presentations,
    getPresentationsItemsNumber: (state) => state.presentationsItems,
  },

  mutations: {
    GET_SEARCH_RESULT_DATA(state, payload) {
      state.searchResults = [...payload];
    },
    SET_SEARCH_RESULT_DATA_DETAILS(state, payload) {
      state.searchResultDetails = payload;
    },
    SET_SEARCH_LOADER(state, payload) {
      state.isLoad = payload;
    },
    SET_PRESENTATIONS(state, payload) {
      const { data, isReset } = payload;

      if (isReset) {
        state.presentations.items = [...data.items];
      } else {
        state.presentations.items = [
          ...state.presentations.items,
          ...data.items,
        ];
      }
      state.presentations.current_page = data.current_page;
      state.presentations.to_item = data.to_item;
      state.presentations.total = data.total;
    },
  },

  actions: {
    async getSearchResultsData({ commit }, payload) {
      try {
        let res;
        res = await getSearchResults(payload);
        if (res.status === 200) {
          commit('GET_SEARCH_RESULT_DATA', res.data.data.items);
        }
      } catch (e) {
        // console.log('>>>> getSearchResultsData error', e);
      }
    },
    setLoader({ commit }, payload) {
      commit('SET_SEARCH_LOADER', payload);
    },

    async getSearchResultsDetails({ commit }, payload) {
      try {
        let res;
        res = await getSearchResultsDetails(payload.type, payload.id);
        if (res.status === 200) {
          commit('SET_SEARCH_RESULT_DATA_DETAILS', res.data.data);
          // commit('SHOW_NOTIFICATION', true);
          // commit('GET_RESPONSE_MESSAGE', 'Відкрито');
        }
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async getRegistryTableData({ commit, getters }) {
      let res;
      let payload = {
        ...getters.getTableSortData,
        ...getters.getCurrentFilter,
      };

      try {
        res = await getRegistryTableData(payload);
        if (res.status === 200) {
          commit('SET_REGISTER_DATA', res.data.data.table.items);
          commit('SET_REGISTER_DATA_TOTAL_QTY', res.data.data.table.total);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        // console.log('>>> getRegistryTableData error', e);
      }
    },

    async getPresentations({ commit, getters }, data) {
      const { page = 1, isReset = false } = data;

      let res;
      let payload = {
        'page[size]': getters.getPresentationsItemsNumber,
        'page[number]': page,
      };
      try {
        res = await getPresentations(payload);
        if (res.status === 200) {
          commit('SET_PRESENTATIONS', {
            data: res.data.data.presentations,
            isReset,
          });
        }
      } catch (e) {
        // console.log('>>>> getPresentations error', e.response.data);
      }
    },
  },
};
