export const table = {
  'dart_hotel-categorizations': {
    path_name: 'dart_categorization_review',
    columns: [
      {
        name: 'review_status',
        isSortable: true,
      },
      {
        name: 'name_of_legal_entity',
        isSortable: true,
      },
      {
        name: 'categorization_name',
        isSortable: true,
      },
      {
        name: 'number',
        isSortable: true,
        // alignCenter: true
      },
      {
        name: 'submitted_at',
        type: 'date',
        isSortable: true,
      },
      {
        name: 'voting_at',
        type: 'date',
        isSortable: true,
      },
    ],
    tooltip: {
      pending_review: [
        {
          name: 'consider',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        // {
        //   name: 'downloadPDF',
        //   isRouting: false,
        //   handler: '',
        // },
      ],
      waiting_for_voting_date: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        // {
        //   name: 'downloadPDF',
        //   isRouting: false,
        //   handler: '',
        // },
      ],
      needs_improvement: [
        {
          name: 'readonly',
          type_action: 'edit',
          isRouting: true,
          handler: null,
        },
        // {
        //   name: 'downloadPDF',
        //   isRouting: false,
        //   handler: '',
        // },
      ],
    },
    actions: {
      pending_review: 'readonly',
      waiting_for_voting_date: 'readonly',
      needs_improvement: 'edit',
    },
  },
  'dart_tour-operator-reports': {
    path_name: 'dart_tour-operator-report_review',
    columns: [
      {
        name: 'review_status',
        isSortable: true,
      },
      {
        name: 'number',
        isSortable: true,
        // alignCenter: true
      },
      {
        name: 'name',
        isSortable: true,
      },
      {
        name: 'signed_at',
        type: 'date',
        isSortable: true,
      },
    ],
    tooltip: {
      pending_review: [
        {
          name: 'consider',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
      needs_improvement: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
      approved: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
    },
    actions: {
      pending_review: 'readonly',
      approved: 'readonly',
      needs_improvement: 'edit',
    },
  },
  'dart_vote-history': {
    path_name: 'dart_vote-history_review',
    columns: [
      {
        name: 'review_status',
        isSortable: true,
      },
      {
        name: 'number',
        isSortable: true,
      },
      {
        name: 'name_of_legal_entity',
        isSortable: true,
      },
      {
        name: 'categorization_name',
        isSortable: true,
      },
      {
        name: 'submitted_at',
        isSortable: true,
        type: 'date',
      },
      {
        name: 'voting_at',
        isSortable: true,
        type: 'date',
      },
    ],
    tooltip: {
      approved: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
      declined: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
    },
    actions: {
      approved: 'readonly',
      declined: 'readonly',
    },
  },
};
