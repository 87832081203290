import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import VueI18n from '@/i18n';
// import { Axios, API_VERSION } from '@/api/config';
import Axios from 'axios';


import {
  getAuthUser,
  getShortDataOfVoting,
  getTableOfParticipants,
  signVotingResult,
  getLocationState,
  getLocationDistricts,
  getLocationSettlement,
  getContinents,
  getCountries,
  getCertificateData,
  sendCertificateData,
} from '@/api/services/general.js';

import {
  setToLS,
  getFromLS,
  removeFromLS,
  isLSHasItem,
} from '@/library/helpers';
import modal from './modules/modal';
import contentAdmin from './modules/content_admin';
import accessAdmin from './modules/access_admin';
import commissioner from './modules/commissioner';
import presidingCommissioner from './modules/presiding_commissioner';
import secretaryCommissioner from './modules/secretary_of_the_commission';
import deputyPresidingCommissioner from './modules/deputy_presiding_commissioner';
import hotels from './modules/hotels';
import tour_operator from './modules/tour_operator';
import dart from './modules/dart';
import auth from './modules/auth';
import ecp from './modules/ecp';
import landing from './modules/landing/landing';
import news from './modules/landing/news';
import filter from './modules/landing/filter';
import filter2 from './modules/landing/filter2';
import statistic from './modules/landing/statistic';
import certificationAgency from './modules/certification_agency';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    modal,
    landing,
    news,
    accessAdmin,
    presidingCommissioner,
    secretaryCommissioner,
    deputyPresidingCommissioner,
    commissioner,
    contentAdmin,
    auth,
    hotels,
    tour_operator,
    dart,
    ecp,
    filter,
    filter2,
    statistic,
    certificationAgency,
  },

  state: {
    selected_filters: {},

    cancelTokenSourceStates: null,
    cancelTokenSourceDistricts: null,
    cancelTokenSourceSettlements: null,

    tableReportData: null,
    pageQty: null,
    currentPage: 1,
    registerData: [],
    registerDataTotalQty: 0,
    loaderState: false,
    authUser: isLSHasItem('currentUser') ? getFromLS('currentUser') : null,
    newsData: [],
    latestNewsData: [],
    comments: [],
    isNotificationShow: false,
    categorizationVoting: null,
    shortDataOfVoting: null,
    tableOfParticipants: null,
    routeFromTable: false,

    tableSortData: { page: 1 },

    locationStates: [],
    locationDistricts: [],
    locationSettlements: [],

    isTouchDevice: false,
    continents: null,
    countries: null,
    certificateData: null,
    requestLoaded: true,
  },

  getters: {
    getDistrictListId: (state) => state.districtListId,
    getSettelmentListId: (state) => state.settelmentListId,

    getIsTouchDevice: (state) => state.isTouchDevice,

    getTableReportData: (state) => state.tableReportData,

    getLoaderState: (state) => state.loaderState,

    getCurrentMenu: (state, rootGetters) => {
      return !!rootGetters.getCurrentRole
        ? require(`@/models/${rootGetters.getCurrentRole}/menu`)?.menu
        : [];
    },

    getCurrentIconStatus: (state, rootGetters) => {
      return !!rootGetters.getCurrentRole
        ? require(`@/models/${rootGetters.getCurrentRole}/icons`)?.default
        : [];
    },

    getStatusModel: (state, rootGetters) => {
      return !!rootGetters.getCurrentRole
        ? require(`@/models/${rootGetters.getCurrentRole}/categorization`)
          ?.default
        : [];
    },

    getTableModel: (state, rootGetters) => {
      return !!rootGetters.getCurrentRole.length
        ? require(`@/models/${rootGetters.getCurrentRole}/table`)?.table
        : [];
    },

    getCurrentProfilePath: (state, getters) => {
      return getters.getCurrentMenu.find((el) => el.name === 'profile')
        ?.path_name;
    },

    getFilterTableModel: (state) => require('@/models/filter/table')?.table,

    getComments: (state) => {
      return state.comments;
    },

    getPageQty: (state) => {
      return state.pageQty;
    },

    getCurrentPage: (state) => {
      return state.currentPage;
    },

    getRegisterData: (state) => {
      return state.registerData;
    },

    getRegisterDataTotalQty: (state) => {
      return state.registerDataTotalQty;
    },

    getAuthUserData: (state) => {
      return state.authUser;
    },

    getNotification: (state) => {
      return state.isNotificationShow;
    },

    getCategorizationVoting: (state) => {
      return state.categorizationVoting;
    },

    getShortDataOfVoting: (state) => {
      return state.shortDataOfVoting;
    },

    getTableOfParticipants: (state) => {
      return state.tableOfParticipants;
    },

    getRouteFromTable: (state) => {
      return state.routeFromTable;
    },

    getTableSortData: (state) => {
      let obj = {
        // 'page[size]': 3,
      };

      for (let key in state.tableSortData) {
        if (state.tableSortData[key]) {
          switch (key) {
            case 'sort':
              const { item, type } = state.tableSortData[key];
              obj[key] = `${type === 'dwn' ? `-${item}` : item}`;
              break;

            case 'page':
              obj[`${key}[number]`] = state.tableSortData.page;
              break;

            case 'search':
              obj[key] = state.tableSortData[key];
              break;
          }
        }
      }
      return obj;
    },

    getLocationStates: (state) => state.locationStates,

    getLocationDistricts: (state) => state.locationDistricts,

    getLocationSettlements: (state) => state.locationSettlements,

    getVoteResultCategorisationPath: (state, getters) => {
      let res = '';
      if (getters.getCurrentRole === 'hotel') {
        res = 'hotel_categorization-edit';
      } else if (getters.getCurrentRole === 'secretary_of_the_commission') {
        res = 'secretary-commission_voting-results';
      } else {
        res = `${getters.getCurrentRole.split('_').join('-')}_voting-results`;
      }
      return res;
    },

    getVoteResultCategorisationHistoryPath: (state, getters) => {
      let res = '';
      if (getters.getCurrentRole === 'hotel') {
        res = 'hotel_categorization-edit';
      } else if (getters.getCurrentRole === 'secretary_of_the_commission') {
        res = 'secretary-commission_history-review';
      } else {
        res = `${getters.getCurrentRole.split('_').join('-')}_history-review`;
      }
      return res;
    },

    getVoteStartCategorisationPath: (state, getters) => {
      let res = '';
      if (getters.getCurrentRole === 'secretary_of_the_commission') {
        res = 'secretary-commission_vote-review';
      } else {
        res = `${getters.getCurrentRole.split('_').join('-')}_vote-review`;
      }
      return res;
    },

    getCommissionReportPath: (state, getters) => {
      let res = '';
      if (getters.getCurrentRole === 'tour_operator') {
        res = 'tour-operator_report-edit';
      } else {
        res = `${getters.getCurrentRole
          .split('_')
          .join('-')}_tour-operator-report_review`;
        // 'presiding-commissioner_tour-operator-report_review'
        // 'deputy-presiding-commissioner_tour-operator-report_review'
      }
      return res;
    },

    getContinents: (state) => state.continents,

    getCountries: (state) => state.countries,

    getCertificateData: (state) => state.certificateData,

    isRequestLoaded: (state) => state.requestLoaded,
  },

  mutations: {
    SET_IS_TOUCH_DEVICE(state, value) {
      state.isTouchDevice = value;
    },
    SET_LOADER(state, payload) {
      state.loaderState = payload;
    },
    SET_TABLEDATA(state, payload) {
      Vue.set(state.tableReportData[payload.id], 'values', payload.data);
    },
    SET_REGISTER_DATA(state, payload) {
      state.registerData = [...payload];
    },
    SET_REGISTER_DATA_TOTAL_QTY(state, payload) {
      state.registerDataTotalQty = payload;
    },
    SET_LAST_PAGE(state, payload) {
      state.pageQty = payload;
    },
    SET_CURRENT_PAGE(state, payload) {
      state.currentPage = payload;
    },
    GET_SEARCH_RESULT_DATA(state, payload) {
      state.searchResults = [...payload];
    },
    SET_SEARCH_RESULT_DATA_DETAILS(state, payload) {
      state.searchResultDetails = payload;
    },
    SET_AUTH_USER_DATA(state, payload) {
      state.authUser = payload;
    },
    SHOW_NOTIFICATION(state, payload) {
      state.isNotificationShow = payload;
    },
    SET_CATEGORIZATION_VOTING(state, payload) {
      state.categorizationVoting = payload;
    },
    SET_SHORT_DATA_OF_VOTING(state, payload) {
      state.shortDataOfVoting = payload;
    },
    SET_TABLE_OF_PARICIPANTS(state, payload) {
      state.tableOfParticipants = payload;
    },
    SET_ROUTE_FROM_TABLE(state, payload) {
      state.routeFromTable = payload;
    },
    SET_TABLE_SORT_DATA(state, payload) {
      state.tableSortData = { ...state.tableSortData, ...payload };
    },

    RESET_TABLE_SORT_DATA(state) {
      for (let key in state.tableSortData) {
        delete state.tableSortData[key];
      }
      state.tableSortData = { ...state.tableSortData, page: 1 };
    },

    SET_LOCATIONS_STATES(state, data) {
      state.locationStates = data;
    },

    SET_LOCATIONS_DISTRICTS(state, data) {
      state.locationDistricts = data;
    },

    SET_LOCATIONS_SETTLEMENTS(state, data) {
      state.locationSettlements = data;
    },

    SET_CONTINENTS(state, data) {
      state.continents = data;
    },

    SET_COUNTRIES(state, data) {
      state.countries = data;
    },

    SET_CERTIFICATE_DATA(state, data) {
      state.certificateData = data;
    },

    SET_RQUEST_LOADED(state, value) {
      state.requestLoaded = value;
    },
    SET_CANCEL_TOKEN_SOURCE(state, cancelTokenSourceStates) {
      state.cancelTokenSourceStates = cancelTokenSourceStates;
    },
    SET_CANCEL_TOKEN_SOURCE_DISTRICT(state, cancelTokenSourceDistricts) {
      state.cancelTokenSourceDistricts = cancelTokenSourceDistricts;
    },
    SET_CANCEL_TOKEN_SOURCE_SETTLEMENTS(state, cancelTokenSourceSettlements) {
      state.cancelTokenSourceSettlements = cancelTokenSourceSettlements;
    },
  },

  actions: {
    setLoader({ commit }, payload) {
      commit('SET_LOADER', payload);
    },
    setRouteFromTable({ commit }, payload) {
      commit('SET_ROUTE_FROM_TABLE', payload);
    },
    changingValueTable({ commit }) {
      let keysOfData = Object.keys(tableReportData).reverse();
      for (let item in keysOfData) {
        if (tableReportData[keysOfData[item]].rowsToSum) {
          let valuesToSum = tableReportData[keysOfData[item]].rowsToSum;
          let newValue1 = 0;

          valuesToSum.forEach((element) => {
            newValue1 += parseFloat(tableReportData[element].values[0]);
          });
          let newValue2 = 0;
          valuesToSum.forEach((element) => {
            newValue2 += parseFloat(tableReportData[element].values[1]);
          });
          let payload = {
            data: [newValue1, newValue2],
            id: keysOfData[item],
          };
          commit('SET_TABLEDATA', payload);
        }
      }
    },

    async getAuthUserData({ commit }) {
      try {
        let res;
        res = await getAuthUser();
        if (res.status === 200) {
          commit('SET_AUTH_USER_DATA', res.data.data.user.role);
          commit('SET_USER_EU_KEY', res.data.data.user.is_has_ecp);
        }
      } catch (e) {
        console.log('>>> error getAuthUserData', e.response?.errors);
      }
    },

    getCurrentPageValue({ commit }, value) {
      commit('SET_CURRENT_PAGE', value);
    },

    setCategorizationVoting({ commit }, value) {
      commit('SET_CATEGORIZATION_VOTING', value);
    },

    async getShortDataOfVoting({ commit }, value) {
      try {
        let res;
        res = await getShortDataOfVoting(value);
        if (res.status === 200) {
          commit('SET_SHORT_DATA_OF_VOTING', res.data.data.voting);
        }
      } catch (e) {
        console.log('>>> error getShortDataOfVoting', e);
      }
    },

    async getTableOfParticipants({ commit, getters }, value) {
      try {
        let res;
        res = await getTableOfParticipants({
          voting_uuid: value,
          ...getters.getTableSortData,
        });
        if (res.status === 200) {
          commit('SET_TABLE_OF_PARICIPANTS', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log('>>> error getTableOfParticipants', e);
      }
    },

    async voteUserWithEuKey({ commit }, payload) {
      commit('SET_RQUEST_LOADED', false);
      let res;
      try {
        res = await signVotingResult({ ...payload });
        if (res.status === 200) {
          router.go(-2);
          commit('SET_RQUEST_LOADED', true);
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', VueI18n.t('table.signed'));
          // router.push({ name: 'presiding-commissioner_history-list' });
        }
      } catch (e) {
        console.log('>>> voteUserWithEuKey error', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response);
        commit('GET_IS_ERROR', true);
      }
    },

    async getLocationState({ commit, state }) {

      if (state.cancelTokenSourceStates) {
        state.cancelTokenSourceStates.cancel();
      }

      const cancelTokenSourceStates = Axios.CancelToken.source();

      commit('SET_CANCEL_TOKEN_SOURCE', cancelTokenSourceStates);

      try {
        let res = await getLocationState(state.cancelTokenSourceStates.token);
        commit('SET_LOCATIONS_STATES', res.data.data.states);

        // next commit for filterList of registry table
        commit(`SET_FILTER_LIST_OF_REGIONS_TOUR_OPERATOR`, res.data.data.states);
        commit(`SET_FILTER_LIST_OF_REGIONS_HOTEL`, res.data.data.states);
      } catch (e) {
        // console.log('>>> getLocationState error', e);
      }
    },


    async getLocationDistricts({ state, commit }, params) {
      const { state_id = null } = params;

      if (state.cancelTokenSourceDistricts) {
        state.cancelTokenSourceDistricts.cancel();
      }

      const cancelTokenSourceDistricts = Axios.CancelToken.source();
      commit('SET_CANCEL_TOKEN_SOURCE_DISTRICT', cancelTokenSourceDistricts);

      try {
        // let data = !!state_id ? { state_id } : null;
        let res = await getLocationDistricts({ state_id, cancelToken: cancelTokenSourceDistricts.token });
        commit('SET_LOCATIONS_DISTRICTS', res.data.data.districts);

        // next commit for filterList of registry table
        commit('SET_FILTER_LIST_OF_DISTRICTS_TOUR_OPERATOR', res.data.data.districts);
        commit('SET_FILTER_LIST_OF_DISTRICTS_HOTEL', res.data.data.districts);
        return res.data.data.districts;
      } catch (e) {
        // console.log('>>> getLocationDistricts error', e);
      }
    },

    async getLocationSettlements({ state, commit }, params) {
      const { district_id = null } = params;

      if (state.cancelTokenSourceSettlements) {
        state.cancelTokenSourceSettlements.cancel();
      }

      const cancelTokenSourceSettlements = Axios.CancelToken.source();
      commit('SET_CANCEL_TOKEN_SOURCE_SETTLEMENTS', cancelTokenSourceSettlements);

      let res;
      try {
        // let data = !!district_id ? { district_id } : null;
        res = await getLocationSettlement({ district_id, cancelToken: cancelTokenSourceSettlements.token });
        commit('SET_LOCATIONS_SETTLEMENTS', res.data.data.settlements);

        // next commit for filterList of registry table
        commit('SET_FILTER_LIST_OF_SETTLEMENTS_TOUR_OPERATOR', res.data.data.settlements);
        commit('SET_FILTER_LIST_OF_SETTLEMENTS_HOTEL', res.data.data.settlements);
        // commit('SET_FILTER_LIST_OF_SETTLEMENTS_BY_DISTRICT_ID',
        //   { [district_id]: res.data.data.settlements }
        // );

        // return res.data.data.settlements;
      } catch (e) {
        // console.log('>>> getLocationSettlements error', e);
      }
    },

    async getContinents({ commit }) {
      let res;
      try {
        res = await getContinents();
        commit('SET_CONTINENTS', res.data.data.continents);
      } catch (e) {
        console.log('>>> getContinents error', e);
      }
    },

    async getCountries({ commit }, continents) {
      let res;
      try {
        res = await getCountries({ continents });
        commit('SET_COUNTRIES', res.data.data.countries);
      } catch (e) {
        console.log('>>> getContinents error', e);
      }
    },

    async getCertificateData({ commit }, uuid) {
      let res;
      try {
        res = await getCertificateData(uuid);
        commit('SET_CERTIFICATE_DATA', res.data.data.initial_data);
      } catch (e) {
        console.log('>>> getContinents error', e);
      }
    },

    async sendCertificateData({ commit }, payload) {
      let res;

      const { data, id } = payload;
      try {
        res = await sendCertificateData(data);
        if (res.status === 200) {
          commit('SET_CURRENT_FORM_TYPE', 'vote');
          commit('SET_DATA_TO_SIGN', id);
          commit('modal/SET_MODAL', {
            state: true,
            name: 'add-key',
            size: 'l',
          });
        }
      } catch (e) {
        console.log('>>> sendCertificateData error', e.response.data);
      }
    },

    downloadFile({ }, url) {
      let link = document.createElement('a');
      link.href = url;
      link.click();
    },
  },
});
