import {
  addCategorizationRequestDraft,
  editCategorizationRequest,
  getTableOfCategorizationsHotel,
  getCategorizationHotel,
  signCategorizationRequest,
  getCommentsHotel,
  getIsHotelRequestsTableExistsDraft,
  deleteCategorizationRequest,
  existingCategorizationInCurrentPeriod,
} from '@/api/services/hotel.js';
import router from '@/router';
import VueI18n from '@/i18n';

export default {
  state: {
    categorizationFormStructure: [],
    categorizationFormState: [],
    tableOfCategorizations: [],
    сommentsHotel: [],
    categorizationDraftSaved: false,
    hotelRequestTableExistsDraft: false,
    existingCategorization: false,
  },

  getters: {
    getTableOfCategorizationsHotel: (state) => state.tableOfCategorizations,
    getCategorizationHotel: (state) => state.categorizationFormState,
    getCommentsHotel: (state) => state.сommentsHotel,
    isCategorizationDraftSaved: (state) => state.categorizationDraftSaved,
    isHotelRequestTableExistsDraft: (state) =>
      state.hotelRequestTableExistsDraft,
    isExistsCategorisationInCurPeriod: (state) => state.existingCategorization,
  },

  mutations: {
    SET_CATEGORIZATION_TABLE(state, data) {
      state.tableOfCategorizations = data;
    },
    SET_CATEGORIZATION_STRUCTURE(state, data) {
      state.categorizationFormStructure = data;
    },
    SET_CATEGORIZATION_FORM_STATE(state, data) {
      state.categorizationFormState = data;
    },
    SET_COMMENTS_HOTEL(state, data) {
      state.сommentsHotel = data;
    },
    CLEAR_STORE(state) {
      state.сommentsHotel = [];
      state.categorizationFormState = [];
    },
    SET_CATEGORISATION_DRAFT_SAVED(state, value) {
      state.categorizationDraftSaved = value;
    },
    SET_HOTEL_REQUEST_TABLE_EXISTS_DRAFT(state, value) {
      state.hotelRequestTableExistsDraft = value;
    },
    SET_EXISTING_CATEGORISATION(state, value) {
      state.existingCategorization = value;
    },
  },

  actions: {
    clearStore({ commit }) {
      commit('CLEAR_STORE');
    },

    async getTableOfCategorizationsHotel({ commit, getters, dispatch }) {
      try {
        let res;

        res = await getTableOfCategorizationsHotel(getters.getTableSortData);
        if (res.status === 200) {
          dispatch('getIsHotelRequestsTableExistsDraft');
          dispatch('checkExistingCategorizationInCurrentPeriod');
          commit('SET_CATEGORIZATION_TABLE', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfCategorizations',
          e.response.data.error
        );
      }
    },

    async addCategorizationRequestDraft({ commit }, data) {
      let res;
      try {
        res = await addCategorizationRequestDraft(data);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.data_is_saved'));
        router.push({ name: 'hotel_categorizations-list' });
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async editCategorizationRequest({ commit }, data) {
      let res;
      try {
        res = await editCategorizationRequest(data);
        if (res.status === 200) {
          router.push({ name: 'hotel_categorizations-list' });
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );
        }
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async createFullCategorizationRequest({ commit }, data) {
      let res;
      try {
        // res = await createFullCategorizationRequest(data);
        res = await addCategorizationRequestDraft(data);
        if (res.status === 200) {
          commit('SET_CATEGORISATION_DRAFT_SAVED', true);
          commit(
            'SET_DATA_TO_SIGN',
            res.data.data.categorization_application.uuid
          );
          commit('modal/SET_MODAL', {
            state: true,
            name: 'add-key',
            size: 'l',
          });
        }
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data?.errors.application);
        commit('GET_IS_ERROR', true);
        router.push({ name: 'hotel_categorizations-list' });
        console.log(
          '>>> createFullCategorizationRequest error',
          e.response.data?.errors.application
        );
      }
    },

    async getCommentsHotel({ commit }, uuid) {
      let res;
      try {
        res = await getCommentsHotel(uuid);
        commit('SET_COMMENTS_HOTEL', res.data.data.criterias);
      } catch (e) {
        console.log('>>> getCommentsHotel error', e);
      }
    },

    async getCategorizationHotel({ commit }, data) {
      let res;
      try {
        res = await getCategorizationHotel(data);
        commit(
          'SET_CATEGORIZATION_FORM_STATE',
          res.data.data.categorization_application
        );
      } catch (e) {
        console.log('>>> getCategorizationHotel error', e);
      }
    },

    async signEditedCategorizationRequest({ commit }, data) {
      let res;
      try {
        res = await editCategorizationRequest(data);
        if (res.status === 200) {
          commit('SET_CATEGORISATION_DRAFT_SAVED', true);
          commit('modal/SET_MODAL', {
            state: true,
            name: 'add-key',
            size: 'l',
          });
        }
      } catch (e) {
        console.log('>>>> signEditedCategorizationRequest', e);
      }
    },

    async signCategorizationRequest({ commit }, payload) {
      let res;
      commit('SET_RQUEST_LOADED', false);
      try {
        res = await signCategorizationRequest({ ...payload });
        if (res.status === 200) {
          commit('SET_RQUEST_LOADED', true);
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', VueI18n.t('table.signed'));
        }
      } catch (e) {
        console.log('>>>> signCategorizationRequest error', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response);
        commit('GET_IS_ERROR', true);
      }
    },

    async getIsHotelRequestsTableExistsDraft({ commit }) {
      let res;
      try {
        res = await getIsHotelRequestsTableExistsDraft();
        if (res.status === 200) {
          commit(
            'SET_HOTEL_REQUEST_TABLE_EXISTS_DRAFT',
            res.data.data.exists_draft
          );
        }
      } catch (e) {
        console.log('>>>> getIsHotelRequestsTableExistsDraft error', e);
      }
    },

    async deleteCategorization({ commit, dispatch }, uuid) {
      let res;
      let data = { categorization_application_uuid: uuid };
      try {
        res = await deleteCategorizationRequest(data);
        if (res.status === 200) {
          dispatch('getTableOfCategorizationsHotel');
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.draft_deleted')
          );
        }
      } catch (e) {
        console.log('>>>> deleteCategorization error', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response);
        commit('GET_IS_ERROR', true);
      }
    },

    async checkExistingCategorizationInCurrentPeriod({ commit }) {
      let res;
      try {
        res = await existingCategorizationInCurrentPeriod();
        if (res.status === 200) {
          commit(
            'SET_EXISTING_CATEGORISATION',
            res.data.data.is_created_application_in_current_period
          );
        }
      } catch (e) {
        console.log('>>>> checkExistingCategorizationInCurrentPeriod error', e);
      }
    },
  },
};
