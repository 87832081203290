export const setToLS = (name, val) => {
  localStorage.setItem(name, JSON.stringify(val));
};

export const getFromLS = (name) => {
  return JSON.parse(localStorage.getItem(name));
};

export const removeFromLS = (name) => {
  localStorage.removeItem(name);
};

export const isLSHasItem = (name) => {
  return !!localStorage.getItem(name);
};

export const revertToCamelCase = (str) => {
  let res = '';
  if (!!str?.length) {
    res = str
      .split('_')
      .map((el) => el[0].toUpperCase() + el.slice(1))
      .join('');
  }
  return res;
};

export const createFile = async (url, type) => {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = {
    type: type,
  };
  return new File([data], url.split('/').at(-1), metadata);
};

export const pickRgbRange = (position = 0, elements = []) => {
  const [left, right, weight] = pickClosest(position, elements);

  return pickRgba(left.color, right.color, weight);
}

const pickRgba = (color1, color2, weight) => {
  const w1 = weight;
  const w2 = 1 - w1;
  const rgba = [
      Math.round(color1[0] * w2 + color2[0] * w1),
      Math.round(color1[1] * w2 + color2[1] * w1),
      Math.round(color1[2] * w2 + color2[2] * w1),
      1
  ];

  return rgba;
}

const pickClosest = (position, elements) => {
  let left = elements[0], 
  right = { color: [0, 0, 0], position: Number.MAX_VALUE };

  let leftIndex = 0;

  for (var i = 0; i < elements.length; i++) {
      if (position >= elements[i].position && position > left.position){
          left = elements[i];
          leftIndex = i;
      }
  }

  if (elements.length - 1 === leftIndex) {
      right = elements[leftIndex];
  } else {
      right = elements[leftIndex + 1];
  }
  if(left == right){
    return [right, right, 0];
  }

  const dleft = position - left.position;
  const sum = dleft + right.position - position;
  const weight = dleft / sum;

  return [left, right, weight];
}

/**
 * Number.prototype.format(n, x, s, c)
 * 
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
export function numberFormat(num, n, x, s, c) {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
      num = num.toFixed(Math.max(0, ~~n));

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};