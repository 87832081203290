<template>
  <div class="select select__wrapper">
    <div class="select-title" :class="{ select__field_required: isRequired }">
      {{ title }}
    </div>
    <!-- {{ !!filteredContent.length }} -->
    <section v-click-outside="onClickOutside" class="dropdown-wrapper">
      <div
        class="selected-item"
        :class="{
          'selected-item__error': isError,
          'selected-item__disabled': isDisabled,
          'selected-item__active':
            isVisible || (!!searchQuery && !!searchQuery.length),
        }"
        @click="toggleList"
      >
        <div class="selected-item__input-wrapper">
          <input
            v-model="searchQuery"
            type="text"
            class="selected-item__input"
            :placeholder="placeholder"
            :disabled="isDisabled || isInactive"
            @click="contentShow"
          />
        </div>
        <div
          v-if="searchQuery && !isDisabled && !isInactive"
          class="clear-button"
          @click="clearQuery"
        >
          <svg-icon name="cross" class="cross-sm-icon" />
        </div>
        <svg-icon
          name="arrow-up-s"
          class="drop-down-icon"
          :class="isVisible ? 'dropdown' : ''"
        />
      </div>
      <div v-show="isVisible" class="dropdown-popover">
        <span v-if="isNotContentForOptions">
          {{ $t('main.no_search_result') }}
        </span>
        <div v-else class="options">
          <ul v-if="isObjectType">
            <li
              v-for="(item, idx) in filteredContent"
              :key="idx"
              @click="chooseItem(item)"
            >
              <Checkbox
                v-if="isMultiSelect"
                v-model="checkedItems"
                :value="item"
              />
              {{ item[titleKey] }}
            </li>
          </ul>
          <ul v-else>
            <li
              v-for="(item, idx) in filteredContent"
              :key="idx"
              @click="chooseItem(item)"
            >
              <Checkbox
                v-if="isMultiSelect"
                :checked="itemsSelected.includes(item)"
              />
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="isError" class="error-message">
        {{ errorText }}
      </div>
    </section>
    <Comments
      v-if="isCommentable"
      :uuid="uuid"
      :can-comment="canComment"
      :commentTo="commentTo"
      onTop
    />
  </div>
</template>

<script>
import VueI18n from '@/i18n';
import Comments from '@/elements/Comments.vue';
export default {
  model: {
    prop: 'value',
    event: 'select-value',
  },
  components: { Comments },
  props: {
    value: {
      type: [String, Object, Array],
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    itemsSelected: {
      type: Array,
      default: () => [],
      required: false,
    },
    titleKey: {
      type: String,
      default: 'name',
    },
    placeholder: {
      type: String,
      default: 'Пошук',
    },
    uuid: {
      type: String,
      default: '',
    },
    isObjectType: {
      type: Boolean,
      default: false,
    },
    isMultiSelect: {
      type: Boolean,
      default: false,
    },
    valueToReturn: {
      type: String,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: VueI18n.t('error.required_field'),
    },
    isCommentable: {
      type: Boolean,
      default: false,
    },
    canComment: {
      type: Boolean,
      default: false,
    },
    commentTo: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInactive: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    searchCallback: {
      type: Function,
    }
  },

  data() {
    return {
      selectedItems: [],
      searchQuery: '',
      selectedItem: null,
      isVisible: false,
      filteredContent: this.items,
      vcoConfig: {
        events: ['dblclick', 'click'],
        isActive: true,
        detectIFrame: true,
      },
    };
  },

  async mounted() {
    this.filteredContent = this.items;
  },

  watch: {
    value: {
      handler(val) {
        if (!!val) {
          // this.searchQuery = this.isObjectType
          //   ? this.value[this.titleKey]
          //   : this.value;
          // this.searchQuery = Array.isArray(this.value)
          //   ? this.value.join(', ')
          //   : this.value;
          if (this.isObjectType) {
            if (Array.isArray(this.value)) {
              this.searchQuery = this.value
                .map((el) => el[this.titleKey])
                .join(', ');

              this.selectedItems = this.value;
            } else {
              this.searchQuery = this.value[this.titleKey];
            }
          } else {
            this.searchQuery = this.value;
          }
        } else {
          this.searchQuery = '';
          this.selectedItems.splice(0);
        }
      },
      deep: true,
      immediate: true,
    },

    searchQuery: async function (val) {
      this.$emit('search-input', val)

      if(this.searchCallback) {
        await this.searchCallback(this)
      }

      this.$nextTick(() => {
        this.filteredContent = this.items;

        if (!!val && !this.isMultiSelect) {
          this.filteredContent = this.items;
          const query = this.searchQuery.toLowerCase();
          this.filteredContent = this.items.filter((item) => {
            let value = String(item[this.titleKey]).toLowerCase();
            
            return value.includes(query);
          });
        } else this.filteredContent = this.items;
      })
    },
  },

  computed: {
    isNotContentForOptions() {
      return this.filteredContent && this.filteredContent.length === 0;
    },
    checkedItems: {
      get() {
        return this.selectedItems;
      },
      set(val) {
        this.selectedItems = [...val];
        this.$emit('select-value', this.selectedItems);
      },
    },
  },

  methods: {
    onClickOutside(event) {
      this.isVisible = false;
    },
    chooseItem(item) {
      if (!this.isMultiSelect) {
        if (this.isObjectType) {
          this.filteredContent = this.items;
          this.searchQuery = item[`${this.titleKey}`];
          this.filteredContent = this.items;
          if (this.valueToReturn === 'object') {
            this.$emit('select-value', item);
          } else {
            this.$emit('select-value', item[`${this.titleKey}`]);
          }
          this.isVisible = false;
        } else {
          this.filteredContent = this.items;
          this.$emit('select-value', item);
          this.searchQuery = item;
          this.isVisible = false;
        }
      }
    },
    contentShow() {
      this.filteredContent = this.items;
    },
    clearQuery() {
      this.searchQuery = '';
      this.selectedItems.splice(0);
      this.filteredContent = this.items;
      let value = this.isMultiSelect ? this.selectedItems : this.searchQuery;
      this.$emit('select-value', value);
    },

    toggleList() {
      if (this.isDisabled || this.isInactive) return;
      this.isVisible = !this.isVisible;
    },
  },
};
</script>

<style scoped lang="sass">
.select-title
  color: $draft
  font-size: 16px
.cross-sm-icon
  width: 9px
  opacity: 0.3
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  // filter: invert(77%) sepia(2%) saturate(0%) hue-rotate(318deg) brightness(90%) contrast(92%)
  transition: opacity 0.5s

.dropdown-wrapper
  position: relative
  .selected-item
    height: 100%
    width: 100%
    border-bottom: 2px solid $gray
    border-radius: 1px
    display: flex
    position: relative
    font-size: 14px
    font-weight: 400
    .drop-down-icon
      fill: $gray
      transform: rotate(0deg)
      transition: all 0.4s ease
      position: absolute
      right: 0
      bottom: 10px
    .drop-down-icon.dropdown
      transform: rotate(180deg)
      transition: all 0.4s ease

  .selected-item__active
    border-color: $black
    .drop-down-icon
      fill: $black

  .selected-item__error
    border-color: $error_color
    .drop-down-icon
      fill: $error_color

  .selected-item__disabled
    opacity: 0.5

  .selected-item__disabled, .selected-item__disabled *
    cursor: not-allowed

  .clear-button
    position: absolute
    right: 30px
    bottom: 12px
    width: 18px
    height: 18px
    border: solid 1px $gray
    background-color: $gray_light
    border-radius: 50%
    opacity: 0
    cursor: pointer
    transition: opacity 0.5s
    &:hover
      border-color: $gray_active
      .cross-sm-icon
        opacity: 1
        transition: opacity 0.3s

  .selected-item__input-wrapper
    align-self: end
    height: 40px
    width: 100%
    max-width: 86%
    overflow: hidden

  .selected-item__input
    font-size: 14px
    width: 100%
    height: 100%
    text-overflow: ellipsis
    vertical-align: middle
    &::placeholder
      color: $gray

  .selected-item
    &:not(.selected-item__disabled)
      &:hover
        .clear-button
          opacity: 1
          transition: opacity 0.3s

  .svg-icon.icon-arrow-up-s
    width: 13px
    height: 10px

  .dropdown-popover
    border-radius: 1.2rem
    z-index: 99
    position: absolute
    border: 2px solid lightgrey
    top: 50px
    left: 0
    right: 0
    background-color: $white
    max-width: 100%
    padding: 10px
    input
      width: 90%
      height: 30px
      border: 2px solid lightgrey
      padding-left: 8px
    .options
      width: 100%
      &::after
        width: calc(100% - 2.4rem)
        position: absolute
        bottom: 0
        left: 1.2rem
        height: 10%
        content: ""
        background: linear-gradient(to top, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 100%)
      ul
        list-style: none
        text-align: left
        max-height: 180px
        overflow-y: scroll
        overflow-x: hidden
        font-size: 14px
        li
          display: flex
          width: 100%
          border-bottom: 1px solid lightgrey
          padding: 10px
          cursor: pointer
          &:hover
            background-color: $gray_border

  .error-message
    position: absolute
    padding-top: 5px
    font-size: 12px
    color: $error_color

.select__wrapper
  position: relative

.select__field_required
  &:after
    content: "*"
    font-size: 1.8rem
    color: $error
    padding-left: 5px
</style>
