export const table = {
  'content-administrator_news-list': {
    path_name: 'content-administrator_news-review',
    columns: [
      {
        name: 'name',
        isSortable: true,
      },
      {
        name: 'author',
        isSortable: true,
      },
      {
        name: 'created_at',
        type: 'date',
        isSortable: true,
      },
      {
        name: 'updated_at',
        type: 'date',
        isSortable: true,
      },
    ],
    tooltip: [
      {
        name: 'readonly',
        type_action: 'readonly',
        isRouting: true,
        handler: null,
        path: 'news-details',
      },
      {
        name: 'edit',
        type_action: 'edit',
        isRouting: true,
        handler: null,
      },
      // {
      //   name: 'print',
      //   isRouting: false,
      //   handler: '',
      // },
      {
        name: 'delete',
        isRouting: false,
        handler: 'deleteNewsContentAdmin',
      },
    ],
    actions: 'edit',
  },
  'content-administrator_statistics-list': {
    // path_name: 'content-administrator_statistics-review',
    path_name: {
      edit: 'content-administrator_statistics-review',
      create: 'content-administrator_statistics-create',
    },
    columns: [
      {
        name: 'tourism_type',
        isSortable: false,
      },
      {
        name: 'period',
        isSortable: false,
      },
      {
        name: 'created_at',
        type: 'date',
        isSortable: false,
      },
      {
        name: 'updated_at',
        type: 'date',
        isSortable: false,
      },
    ],
    tooltip: [
      {
        name: 'create',
        type_action: 'create',
        isExist: false,
        isRouting: true,
        handler: null,
        path: 'content-administrator_statistics-create',
      },
      {
        name: 'edit',
        type_action: 'edit',
        isExist: true,
        isRouting: true,
        handler: null,
        path: 'content-administrator_statistics-review',
      },
      {
        name: 'archives',
        type_action: '',
        isRouting: true,
        handler: null,
        path: 'content-administrator_statistics-archive',
      },
    ],
    actions: {
      edit: true,
      create: false,
    },
  },
  'content-administrator_presentations-list': {
    path_name: 'content-administrator_presentation-review',
    columns: [
      {
        name: 'author',
        isSortable: true,
      },
      {
        name: 'title',
        isSortable: true,
      },
      {
        name: 'created_at',
        type: 'date',
        isSortable: true,
      },
      {
        name: 'updated_at',
        type: 'date',
        isSortable: true,
      },
    ],
    tooltip: [
      {
        name: 'edit',
        type_action: 'edit',
        isRouting: true,
        handler: null,
      },
      {
        name: 'delete',
        isRouting: false,
        handler: 'deletePresentationContentAdmin',
      },
    ],
    actions: 'edit',
  },
  'content-administrator_research-files-list': {
    path_name: 'content-administrator_research-files-review',
    columns: [
      {
        name: 'author',
        isSortable: true,
      },
      {
        name: 'title',
        isSortable: true,
      },
      {
        name: 'research_file_type',
        isSortable: true,
      },
      {
        name: 'created_at',
        type: 'date',
        isSortable: true,
      },
      {
        name: 'updated_at',
        type: 'date',
        isSortable: true,
      },
    ],
    tooltip: [
      {
        name: 'edit',
        type_action: 'edit',
        isRouting: true,
        handler: null,
      },
      {
        name: 'delete',
        isRouting: false,
        handler: 'deleteResearchFileContentAdmin',
      },
    ],
    actions: 'edit',
  },
};
