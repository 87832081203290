<template>
  <div class="header__main header__wrapper">
    <div class="block_container">
      <component :is="headerMainComponent" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import headerMainTop from '@/views/header_main/HeaderMainTop.vue';
import headerMainBottom from '@/views/header_main/HeaderMainBottom.vue';

export default {
  components: {
    headerMainTop,
    headerMainBottom,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters(['getCurrentRole', 'getCurrentUser']),

    headerMainComponent() {
      return this.isMainMenu ? 'headerMainTop' : 'headerMainBottom';
    },
    getCurrentTitle() {
      return this.isMainMenu
        ? this.$t(`sections.${this.getCurrentRole}`)
        : this.$t(`sections.${this.$route.meta?.name}`);
    },

    isMainMenu() {
      return this.$route.meta.isMenu;
    },
  },

  methods: {
    logout() {
      this.$router.push({ name: 'auth-password' });
    },
  },
};
</script>

<style lang="sass" scoped>
.header__main
  padding-bottom: 0
</style>
