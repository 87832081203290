<template>
  <div class="modal__container-inner">
    <div class="accept accept__box">
      <h3 class="accept__title modal__mb-20 title-h4">
        {{ $t('modals.voting_result') }}
      </h3>
      <div class="modal__btn-1col-center">
        <Button class="decision-preview" :vote-decision="chosenDecision">
          {{ $t(`voting.${chosenDecision}`) }}
        </Button>
      </div>
      <TextField
        v-if="chosenDecision === 'declined'"
        class="accept__comment"
        v-model="comment"
        :title="$t('voting.declined_comment_label')"
        isRequired
        :isError="errors.comment"
        @input="onCommentInput"
      />
      <div class="accept__content">
        {{ $t('modals.voting_result_subtitle') }}
      </div>
      <div class="modal__btn-2col-center">
        <Button white @click.native="closeModal" :disabled="!isRequestLoaded">
          {{ $t('modals.cancel') }}
        </Button>
        <Button @click.native="acceptHandler" :disabled="!isRequestLoaded">
          {{ $t('modals.confirm') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TextField from '../TextField.vue';

export default {
  data() {
    return {
      comment: '',

      errors: {
        comment: false,
      }
    };
  },

  components: {
    TextField,
  },

  computed: {
    ...mapGetters('modal', [
      'getModalProps',
      'getModalHandler',
      'getModalType',
    ]),
    ...mapGetters(['isRequestLoaded']),
    chosenDecision() {
      return this.getModalHandler.payload.vote;
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },

    async acceptHandler() {
      const { name, payload } = this.getModalHandler;

      payload.comment = this.comment

      if(this.getModalHandler.payload.vote === 'declined') {
        if(!this.comment.length) {
          this.errors.comment = true
          
          return false
        }
      }

      await this.$store.dispatch(name, payload);
      this.closeModal();
    },

    onCommentInput(e) {
      this.errors.comment = !this.comment.length ? true : false
    }
  },
};
</script>

<style lang="sass" scoped>
.accept__box
  padding: 0 80px
.accept__box *
  text-align: center
.accept__content
  color: $gray
  margin-bottom: 60px

::v-deep .accept__comment
  input 
    text-align: left
</style>
