import Vue from 'vue';
import VueI18n from '@/i18n'
import { hasValue } from '@/utils/utils';
import { getRegistryTableData } from '@/api/services/general';
import { getDateRangesAgoFromNow } from '@/library/time'

// "sum_of": []
export default {
  state: {
    currentFilterRole: '', // 'tour_operator' || 'hotel
    total_counter_tour_operator: 0,

    filtersLists: {
      main: [
        {
          name: 'tour_operator',
          counter: 0,
          id: 'tour_operator',

          isHasList: true,
        },

        {
          name: 'hotel',
          counter: 0,
          id: 'hotel',

          isHasList: true,
        },
      ],


      tour_operator: [
        {
          name: 'regions',
          counter: 0,
          id: 'regions',

          isHasList: true,

          regions: [],
          districts: [],
          settlements: [],
        },

        {
          name: 'activity_type',
          counter: 0,

          id: 'activity_type',
          isHasList: true,
        },

        {
          name: 'activity_period',
          counter: 0,

          id: 'activity_period',
          isHasList: true,
        },
      ],

      activity_type: [
        {
          name: VueI18n.t('filter.domestic_tourism'),
          id: 'domestic_tourism',

          isHasList: false,
        },
        {
          name: VueI18n.t('filter.outbound_tourism'),
          id: 'outbound_tourism',

          isHasList: false,
        },
        {
          name: VueI18n.t('filter.inbound_tourism'),
          id: 'inbound_tourism',

          isHasList: false,
        },
      ],

      activity_period: [
        // {
        //   name: VueI18n.t('filter.period_all'),
        //   period: { from: '1991-01-01', to: 0 },
        //   back_end_period: `${getDateRangesAgoFromNow({ from: '1991-01-01', to: 0 }).from}, ${getDateRangesAgoFromNow({ from: '1991-01-01', to: 0 }).to}`,
        //   id: 'period_all',
        //   isHasList: false,
        // },
        {
          name: VueI18n.t('filter.period_one_year'),
          period: { from: 1, to: 0 },
          back_end_period: `${getDateRangesAgoFromNow({ from: 1, to: 0 }).from}, ${getDateRangesAgoFromNow({ from: 1, to: 0 }).to}`,
          id: 'period_one_year',
          isHasList: false,
        },
        {
          name: VueI18n.t('filter.period_five_years'),
          period: { from: 5, to: 1 },
          back_end_period: `${getDateRangesAgoFromNow({ from: 5, to: 1 }).from}, ${getDateRangesAgoFromNow({ from: 5, to: 1 }).to}`,
          id: 'period_five_years',
          isHasList: false,
        },
        {
          name: VueI18n.t('filter.period_more_five_years'),
          period: { from: '1991-01-01', to: 5 },
          back_end_period: `${getDateRangesAgoFromNow({ from: '1991-01-01', to: 5 }).from}, ${getDateRangesAgoFromNow({ from: '1991-01-01', to: 5 }).to}`,
          id: 'period_more_five_years',
          isHasList: false,
        },
      ],


      hotel: [
        {
          name: 'regions',
          counter: 0,
          id: 'regions',
          counter: 0,

          isHasList: true,

          regions: [],
          districts: [],
          settlements: [],
        },
        {
          name: 'stars',
          id: 'hotel_star_category',
          counter: 0,

          isHasList: true,
        },
        {
          name: 'conference_halls',
          id: 'hotel_number_of_conference_halls',
          counter: 0,

          isHasList: true,
        },
        {
          name: 'beds_quantity',
          id: 'hotel_total_beds_quantity',
          counter: 0,

          isHasList: true,
        },
        {
          name: 'total_rooms',
          id: 'hotel_total_sum_number_rooms',
          counter: 0,

          isHasList: true,
        },
      ],

      regions: [
        // { 
        //   counter: 0, 
        //   id: 2, 
        //   isHasList: true, 
        //   name: "Автономна Республіка Крим",
        //   nextBlockName: "district",
        // }
      ],
      districts: [
        // { 
        //   counter: 0, 
        //   id: 27, 
        //   state_id: 2,
        //   isHasList: true, 
        //   name: "Бахчисарайський р-н",
        //   nextBlockName: "settlement",
        // }
      ],
      settlements: [],
    },

    selectedFilters: {
      tour_operator: {
        tour_operator_type_of_activity: [],
        date_of_state_registration: [],

        location_state: [],
        location_district: [
          // { 
          //   counter: 0, 
          //   id: 27, 
          //   state_id: 2,
          //   isHasList: true, 
          //   name: "Бахчисарайський р-н",
          //   nextBlockName: "settlement",
          // },
          // { 
          //   counter: 0, 
          //   id: 27, 
          //   state_id: 2,
          //   isHasList: true, 
          //   name: "Бахчисарайський р-н",
          //   nextBlockName: "settlement",
          // }
        ],
        location_settlement: [],
      },

      hotel: {
        number_of_employees: '',
        hotel_star_category: '',
        hotel_total_beds_quantity: '',
        hotel_total_sum_number_rooms: '',
        hotel_number_of_conference_halls: '',
        hotel_capacity_of_conference_halls: '',

        location_state: [],
        location_district: [],
        location_settlement: [],
      },
    },

    selectedFiltersBackEnd: {
      'filter[tour_operator]': null,
      'filter[hotel]': null,

      tour_operator: {
        tour_operator_type_of_activity: [],
        date_of_state_registration: [],

        location_state: [],
        location_district: [],
        location_settlement: [],
      },

      hotel: {
        number_of_employees: '',
        hotel_star_category: '',
        hotel_total_beds_quantity: '',
        hotel_total_sum_number_rooms: '',
        hotel_number_of_conference_halls: '',
        hotel_capacity_of_conference_halls: '',

        location_state: [],
        location_district: [],
        location_settlement: [],
      },
    }
  },

  getters: {

    getCurrentFilterRole: (state) => state.currentFilterRole,

    getCurrentFilterListyByName: (state) => (name) => {
      return state.filtersLists[name];
    },
    getFilterListOfTerritories: (state) => (role, name, territory_name) => state.filtersLists[role].find(el => el.name === name)[territory_name],

    getSelectedFiltersByRole: (state) => (role) => state.selectedFilters[role] || [],
    getSelectedFiltersByRoleAndNameOfFilter: (state) => (role, name) => state.selectedFilters[role][name] || [],

    getCerrentRatingFilterByHotel: (state) => {
      const rating = state.selectedFilters['hotel']['hotel_star_category']?.[0]?.id || 0;
      const value = rating ? rating.split(',')[0] : rating;

      return value;
    },

    getSelectedFiltersCounter: (state) => {
      let quantity = 0;

      for(let k in state.selectedFilters) {
        const type = state.selectedFilters[k];

        for(let filter in type) {
          quantity += type[filter].length;
        }
      }

      return quantity;
    },

    checkIfBoothRoleDataSelected: (state) => hasValue(state.selectedFiltersBackEnd.tour_operator) &&
      hasValue(state.selectedFiltersBackEnd.hotel),


    getAllRegistryFiltersForBackend: (state) => (role) => {

      const selectedRole = role && role !== 'all' ? `filter[${role}]` : null;
      const filterObject = {};
      selectedRole ? filterObject[selectedRole] = 1 : null;

      const isBoothDataSelected = hasValue(state.selectedFiltersBackEnd.tour_operator) &&
        hasValue(state.selectedFiltersBackEnd.hotel)

      if (isBoothDataSelected) {
        filterObject['filter[tour_operator]'] = 1;
        filterObject['filter[hotel]'] = 1;
      }

      const filters = state.selectedFiltersBackEnd[role === 'all' ? 'tour_operator' : role];

      if (filters && Object.keys(filters).length > 0) {
        for (const key in filters) {
          const filterValue = filters[key];

          if (Array.isArray(filterValue) && filterValue.length > 0) {
            const filterKey = `filter[${key}]`;
            filterObject[filterKey] = filterValue;

          } else if (filterValue !== null && filterValue !== '') {
            const filterKey = `filter[${key}]`;
            filterObject[filterKey] = filterValue;
          }
        }
      }

      return filterObject;
    },

    isSelectedFiltersBackEnd(state) {
      const tourOperatorFilters = state.selectedFiltersBackEnd.tour_operator;
      const hotelFilters = state.selectedFiltersBackEnd.hotel;

      const isTourOperatorFiltersSelected = Object.values(tourOperatorFilters).some(
        (value) => value.length > 0
      );
      const isHotelFiltersSelected = Object.values(hotelFilters).some(
        (value) => value.length > 0
      );

      const isBothRoleFiltersSelected = isTourOperatorFiltersSelected && isHotelFiltersSelected;
      const isSomeFiltersSelected = isTourOperatorFiltersSelected || isHotelFiltersSelected;
      return { isBothRoleFiltersSelected, isTourOperatorFiltersSelected, isHotelFiltersSelected, isSomeFiltersSelected };
    },
  },

  mutations: {
    SET_BACKEND_DATA(state, data) {
      const { role, back_end_id = 'id', filter_name, ...items } = data;

      if (Object.keys(items[filter_name]).length > 0) {
        state.selectedFiltersBackEnd[role][filter_name] = items[filter_name].map(item => item[back_end_id]).join(',');
      } else {
        state.selectedFiltersBackEnd[role][filter_name] = []
      }
    },

    SET_FILTER_ROLE(state, data) {
      state.currentFilterRole = data;
    },

    SET_SELECTED_REGIONS_OF_TOUR_OPERATOR(state, data) {
      state.selectedFilters.tour_operator.location_state = data;

      const coutOfRegions = state.selectedFiltersBackEnd.tour_operator.location_state.length ? state.selectedFiltersBackEnd.tour_operator.location_state.split(',').length : 0
      const coutOfDistricts = state.selectedFiltersBackEnd.tour_operator.location_district.length ? state.selectedFiltersBackEnd.tour_operator.location_district.split(',').length : 0
      const coutOfSettlments = state.selectedFiltersBackEnd.tour_operator.location_settlement.length ? state.selectedFiltersBackEnd.tour_operator.location_settlement.split(',').length : 0

      state.total_counter_tour_operator = coutOfRegions + coutOfDistricts + coutOfSettlments;
      state.filtersLists.tour_operator.find(el => el.id === 'regions').counter = state.total_counter_tour_operator;
    },

    SET_SELECTED_REGIONS_OF_HOTEL(state, data) {
      state.selectedFilters.hotel.location_state = data;

      state.filtersLists.hotel.find(el => el.id === 'regions').counter = data.length;
    },

    SET_SELECTED_DISTRICTS_OF_TOUR_OPERATOR(state, data) {
      state.selectedFilters.tour_operator.location_district = data;

      const allRegionsList = state.filtersLists.tour_operator.find(list => list.id === 'regions').regions;

      const statesId = data.map(el => el.state_id);
      const uniqueStatesId = [...new Set(statesId)];

      const statesIdLengthObject = uniqueStatesId.map(id => {
        // count how much statesId with this id
        const count = statesId.filter(el => el === id).length;

        return {
          counter: count,
          state_id: id,
        }
      })

      const updatedRegions = allRegionsList.map(region => {
        const counter = statesIdLengthObject.find(el => el.state_id === region.id);
        return {
          ...region,
          counter: counter ? counter.counter : 0,
        }
      })

      state.filtersLists.tour_operator.find(list => list.id === 'regions').regions = updatedRegions;
      // );

      const coutOfRegions = state.selectedFiltersBackEnd.tour_operator.location_state.length ? state.selectedFiltersBackEnd.tour_operator.location_state.split(',').length : 0
      const coutOfDistricts = state.selectedFiltersBackEnd.tour_operator.location_district.length ? state.selectedFiltersBackEnd.tour_operator.location_district.split(',').length : 0
      const coutOfSettlments = state.selectedFiltersBackEnd.tour_operator.location_settlement.length ? state.selectedFiltersBackEnd.tour_operator.location_settlement.split(',').length : 0

      state.total_counter_tour_operator = coutOfRegions + coutOfDistricts + coutOfSettlments;
      state.filtersLists.tour_operator.find(el => el.id === 'regions').counter = state.total_counter_tour_operator;

    },

    CLEAR_SETTLEMENTS_OF_TOUR_OPERATOR_BY_DISTIRCT_ID(state, id) {
      state.selectedFilters.tour_operator.location_settlement = state.selectedFilters.tour_operator.location_settlement.filter(el => el.district_id !== id);
    },

    SET_SELECTED_DISTRICTS_OF_HOTEL(state, data) {
      state.selectedFilters.hotel.location_district = data;
    },

    SET_SELECTED_SETTLEMENTS_OF_TOUR_OPERATOR(state, data) {
      state.selectedFilters.tour_operator.location_settlement = data;

      const allDistrictsList = state.filtersLists.tour_operator.find(list => list.id === 'regions').districts;

      const districtId = data.map(el => el.district_id);
      const uniquedistrictId = [...new Set(districtId)];

      const districtIdLengthObject = uniquedistrictId.map(id => {
        // count how much districtId with this id
        const count = districtId.filter(el => el === id).length;
        return {
          counter: count,
          district_id: id,
        }
      })

      state.filtersLists.tour_operator.find(list => list.id === 'regions').districts = allDistrictsList.map(distirct => {
        const counter = districtIdLengthObject.find(el => el.district_id === distirct.id);
        return {
          ...distirct,
          counter: counter ? counter.counter : 0,
        }
      })
      // );


      const coutOfRegions = state.selectedFiltersBackEnd.tour_operator.location_state.length ? state.selectedFiltersBackEnd.tour_operator.location_state.split(',').length : 0
      const coutOfDistricts = state.selectedFiltersBackEnd.tour_operator.location_district.length ? state.selectedFiltersBackEnd.tour_operator.location_district.split(',').length : 0
      const coutOfSettlments = state.selectedFiltersBackEnd.tour_operator.location_settlement.length ? state.selectedFiltersBackEnd.tour_operator.location_settlement.split(',').length : 0

      state.total_counter_tour_operator = coutOfRegions + coutOfDistricts + coutOfSettlments;
      state.filtersLists.tour_operator.find(el => el.id === 'regions').counter = state.total_counter_tour_operator;
    },

    SET_SELECTED_SETTLEMENTS_OF_HOTEL(state, data) {
      state.selectedFilters.hotel.location_settlement = data;
    },

    SET_SELECTED_HOTEL_STAR_CATEGORY_OF_HOTEL(state, data) {
      state.selectedFilters.hotel.hotel_star_category = data;
    },

    SET_SELECTED_HOTEL_NUMBER_OF_CONFERENCE_HALLS_OF_HOTEL(state, data) {
      state.selectedFilters.hotel.hotel_number_of_conference_halls = data;
    },

    SET_SELECTED_HOTEL_CAPACITY_OF_CONFERENCE_HALLS_OF_HOTEL(state, data) {
      state.selectedFilters.hotel.hotel_capacity_of_conference_halls = data;
    },

    SET_SELECTED_HOTEL_TOTAL_SUM_NUMBER_ROOMS_OF_HOTEL(state, data) {
      state.selectedFilters.hotel.hotel_total_sum_number_rooms = data;
    },

    SET_SELECTED_HOTEL_TOTAL_BEDS_QUANTITY_OF_HOTEL(state, data) {
      state.selectedFilters.hotel.hotel_total_beds_quantity = data;
    },

    SET_SELECTED_TOUR_OPERATOR_TYPE_OF_ACTIVITY_OF_TOUR_OPERATOR(state, data) {
      state.selectedFilters.tour_operator.tour_operator_type_of_activity = data;
      state.filtersLists.tour_operator.find(el => el.id === 'activity_type').counter = data.length;
    },

    SET_SELECTED_DATE_OF_STATE_REGISTRATION_OF_TOUR_OPERATOR(state, data) {
      state.selectedFilters.tour_operator.date_of_state_registration = data;
      state.filtersLists.tour_operator.find(el => el.id === 'activity_period').counter = data.length;

      const totalCounter = state.filtersLists.tour_operator.reduce((accumulator, filter) => {
        return accumulator + filter.counter;
      }, 0);
      state.filtersLists.main.find(el => el.name === 'tour_operator').counter = totalCounter;
    },


    SET_FILTER_LIST_OF_REGIONS_TOUR_OPERATOR(state, data) {
      state.filtersLists.tour_operator.find(list => list.name === 'regions').regions = data.map((el) => {
        return {
          ...el,
          nextBlockName: 'district',
          counter: 0,
          isHasList: true,
        }
      });
    },
    SET_FILTER_LIST_OF_REGIONS_HOTEL(state, data) {
      state.filtersLists.hotel.find(list => list.name === 'regions').regions = data.map((el) => {
        return {
          ...el,
          nextBlockName: 'district',
          counter: 0,
          isHasList: true,
        }
      });
    },



    SET_FILTER_LIST_OF_DISTRICTS_TOUR_OPERATOR(state, data) {
      state.filtersLists.tour_operator.find(list => list.name === 'regions').districts = data.map((el) => {
        return {
          ...el,
          nextBlockName: 'settlement',
          counter: 0,
          isHasList: true,
        }
      });
    },

    SET_FILTER_LIST_OF_DISTRICTS_HOTEL(state, data) {
      state.filtersLists.hotel.find(list => list.name === 'regions').districts = data.map((el) => {
        return {
          ...el,
          nextBlockName: 'settlement',
          counter: 0,
          isHasList: true,
        }
      });
    },

    SET_FILTER_LIST_OF_SETTLEMENTS_TOUR_OPERATOR(state, data) {
      state.filtersLists.tour_operator.find(list => list.name === 'regions').settlements = data.map((el) => {
        return {
          ...el,
          // counter: 0,
          isHasList: false,
        }
      });
    },
    SET_FILTER_LIST_OF_SETTLEMENTS_HOTEL(state, data) {
      state.filtersLists.hotel.find(list => list.name === 'regions').settlements = data.map((el) => {
        return {
          ...el,
          // counter: 0,
          isHasList: false,
        }
      });
    },

    SET_CLEAR_FILTERS_COUNTERS(state) {
      for(let k in state.filtersLists) {
        if({}.toString.call(state.filtersLists[k]).slice(8, -1) === 'Array') {
          state.filtersLists[k].forEach((row) => {
            if(row.hasOwnProperty('counter')) {
              row.counter = 0;
            }

            if(row.id === 'regions') {
              row.regions.forEach(region => {region.counter = 0});
              row.districts.forEach(district => {district.counter = 0});
            }
          });
        }
      }
    },

    SET_CLEAR_ALL_FILTERS(state) {
      state.selectedFilters = {
        tour_operator: {
          tour_operator_type_of_activity: [],
          date_of_state_registration: [],

          location_state: [],
          location_district: [],
          location_settlement: [],
        },

        hotel: {
          number_of_employees: '',
          hotel_star_category: '',
          hotel_total_beds_quantity: '',
          hotel_total_sum_number_rooms: '',
          hotel_number_of_conference_halls: '',
          hotel_capacity_of_conference_halls: '',

          location_state: [],
          location_district: [],
          location_settlement: [],
        },
      }

      this.commit('SET_CLEAR_FILTERS_COUNTERS');

      state.selectedFiltersBackEnd = {
        'filter[tour_operator]': null,
        'filter[hotel]': null,

        tour_operator: {
          tour_operator_type_of_activity: [],
          date_of_state_registration: [],

          location_state: [],
          location_district: [],
          location_settlement: [],
        },

        hotel: {
          number_of_employees: '',
          hotel_star_category: '',
          hotel_total_beds_quantity: '',
          hotel_total_sum_number_rooms: '',
          hotel_number_of_conference_halls: '',
          hotel_capacity_of_conference_halls: '',

          location_state: [],
          location_district: [],
          location_settlement: [],
        },
      }
    },
    SET_CLEAR_HOTEL_FILTERS(state) {
      state.selectedFilters.hotel = {
        number_of_employees: '',
        hotel_star_category: '',
        hotel_total_beds_quantity: '',
        hotel_total_sum_number_rooms: '',
        hotel_number_of_conference_halls: '',
        hotel_capacity_of_conference_halls: '',

        location_state: [],
        location_district: [],
        location_settlement: [],
      }

      state.selectedFiltersBackEnd.hotel = {
        number_of_employees: '',
        hotel_star_category: '',
        hotel_total_beds_quantity: '',
        hotel_total_sum_number_rooms: '',
        hotel_number_of_conference_halls: '',
        hotel_capacity_of_conference_halls: '',

        location_state: [],
        location_district: [],
        location_settlement: [],
      }

      this.commit('SET_CLEAR_FILTERS_COUNTERS');
    },

    SET_CLEAR_TOUR_OPERATOR_FILTERS(state) {
      state.selectedFilters.tour_operator = {
        tour_operator_type_of_activity: [],
        date_of_state_registration: [],

        location_state: [],
        location_district: [],
        location_settlement: [],
      }

      state.selectedFiltersBackEnd.tour_operator = {
        tour_operator_type_of_activity: [],
        date_of_state_registration: [],

        location_state: [],
        location_district: [],
        location_settlement: [],
      }

      this.commit('SET_CLEAR_FILTERS_COUNTERS');
    },

    SET_TOTAL_COUNTER(state) {

    }
  },

  actions: {
    clearCurrentFilters({ commit }) {
    },
    updateFiltersByType({ state, commit }, { role, selected_list_type, back_end_id, filter_name, ...items }) {
      if (items[filter_name].length > 0) {
        commit('SET_BACKEND_DATA', { role, filter_name, back_end_id, [filter_name]: items[filter_name] });
      } else {
        commit('SET_BACKEND_DATA', { role, filter_name, back_end_id, [filter_name]: '' });
      }
      commit(`SET_SELECTED_${selected_list_type.toUpperCase()}_OF_${role.toUpperCase()}`, items[filter_name]);

      let elForCounter = state.filtersLists[role].find(el => {
        if (selected_list_type === 'hotel_capacity_of_conference_halls') {
          return el.id === 'hotel_number_of_conference_halls'
        }

        return el.id === selected_list_type
      })
    },

    async getRegistryPageTableData({ commit, getters }, { role }) {
      let res;
      let payload = {
        ...getters.getTableSortData,
      };
      if (role !== 'all') {
        payload = {
          ...payload,
          ...getters.getAllRegistryFiltersForBackend(role),
        };
      }

      try {
        res = await getRegistryTableData(payload);
        if (res.status === 200) {
          commit('SET_REGISTER_DATA', res.data.data.table.items);
          commit('SET_REGISTER_DATA_TOTAL_QTY', res.data.data.table.total);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        // console.log('>>> getRegistryTableData error', e);
      }
    }
  },
};
