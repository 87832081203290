var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.keyForReload,staticClass:"text-field text-field__container",class:{
    'text-field_disabled': _vm.isDisabled,
    'text-field_readonly': _vm.isReadOnly,
    'text-field_inactive': _vm.isInactive,
    'text-field_error': _vm.isError,
    'no-underline': _vm.noUnderline,
    'text-field_active': _vm.focusField,
  }},[_c('span',{staticClass:"text-field__placeholder text-field__label",class:{
      'text-field_required': _vm.isRequired,
      'text-bold': _vm.boldReport,
    }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.isRate)?_c('div',{staticClass:"rate__wrapper"},[_c('star-rating',{attrs:{"rating":_vm.rateValue,"active-on-click":true,"read-only":_vm.starsReadOnly,"star-size":18,"fixed-points":1,"show-rating":false,"padding":8,"border-width":2,"border-color":"#6B6B6B","active-border-color":"#FFB33B","active-color":"#FFB33B","inactive-color":"#fff"},model:{value:(_vm.valueModel),callback:function ($$v) {_vm.valueModel=$$v},expression:"valueModel"}})],1):_c('div',{class:{ 'text-field_isIcon': _vm.isIcon && !!_vm.iconName.length }},[(_vm.isIcon && !!_vm.iconName.length)?_c('svg-icon',{staticClass:"text-field__icon",attrs:{"name":_vm.iconName}}):_vm._e(),_c('masked-input',{key:_vm.keyForReload,staticClass:"text-field__input",class:{ 'text-bold': _vm.boldReport },attrs:{"placeholder":_vm.getPlaceholder,"autocomplete":"off","type":_vm.isPassword ? _vm.typeInput : _vm.type,"autofocus":false,"mask":_vm.computedMask,"guide":true,"disabled":_vm.isDisabled || _vm.isInactive},on:{"focus":function($event){_vm.focusField = true},"blur":_vm.unfocus},model:{value:(_vm.valueModel),callback:function ($$v) {_vm.valueModel=$$v},expression:"valueModel"}})],1),(_vm.isCommentable)?_c('Comments',{attrs:{"uuid":_vm.uuid,"can-comment":_vm.canComment,"commentTo":_vm.commentTo}}):_vm._e(),(_vm.isPassword)?_c('div',{staticClass:"text-field__password",on:{"click":_vm.typeInutHandler}},[_c('svg-icon',{staticClass:"text-field__icon-pass",attrs:{"name":_vm.isTypePassword ? 'eye-closed' : 'eye'}})],1):_vm._e(),(_vm.isError)?_c('div',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(!!_vm.errorText.length ? _vm.errorText : _vm.$t('error.required_field'))}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }