export function generateFilterBlockName(nextBlockName) {
  if (!nextBlockName) return null;

  // Convert nextBlockName to PascalCase
  const formattedName = nextBlockName
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');

  return `Filter${formattedName}Block`;
}
