<template>
  <div
    class="file__wrapper"
    :class="{ file_disabled: isDisabled, file_inactive: isInactive }"
    @dragenter.prevent
    @dragover.prevent
    @drop.prevent
    @drop="handleFileDrop"
    @dragenter="dragging = true"
    @dragend="dragging = false"
    @dragleave="dragging = false"
  >
    <!-- {{ isDisabled }} {{ isInactive }} {{ isDownloadOnly }} -->
    <div v-if="!isDisabled && !isDownloadOnly" class="file__info">
      <div class="file__info-text subtitle">{{ $t('main.info') }}</div>
      <Tooltip :text="toolTipText" position="top" :is-dynamic="true">
        <svg-icon class="file__info-icon" name="info_questions" />
      </Tooltip>
    </div>
    <label
      v-if="!isDownloadOnly && !isDisabled && !isInactive"
      class="input__file-label"
      :for="getId + id"
    >
      <div
        class="file__drag-field"
        :class="{ dragenter: dragging, 'file__drag-field_error': isError }"
      >
        <div
          v-if="isUserHasEuKey && isEuKey"
          class="file__remove-file"
          @click="$emit('remove')"
        >
          <div class="file__key-name">
            {{ $t('profile.profile_identified_by_cep') }}
          </div>
          <svg-icon class="file__upload-icon" name="trash" />
        </div>
        <template v-else>
          <svg-icon class="file__upload-icon" name="upload_small" />
          <input
            :id="getId + id"
            type="file"
            :accept="getValidFormats"
            :multiple="isMultiple"
            :disabled="isDisabled || isInactive"
            class="input"
            @change="handleFileInput"
          />
          <p class="file__upload-text">
            Перетягніть <span class="subtitle">сюди файл або оберіть</span>
          </p>
          <p v-if="showError" class="file__upload-error">
            {{ errorMessage }}
          </p>
        </template>
      </div>
    </label>
    <Comments
      v-if="isCommentable"
      :uuid="uuid"
      :can-comment="canComment"
      :commentTo="commentTo"
      on-top
    />

    <div v-if="isError" class="error-message">
      {{ errorText }}
    </div>
    <!-- v-if="(filesUploaded && !isUserHasEuKey && !isEuKey) || isAuthForm" -->
    <div v-if="(filesUploaded && !isEuKey) || isAuthForm" class="files__loaded">
      <div v-for="(file, id_2) in filesUploaded" :key="id_2">
        <div
          v-if="!isDisabled && !isInactive && !isDownloadOnly"
          class="files__loaded-file"
        >
          <svg-icon class="file__upload-icon" name="upload_small" />
          <div class="progress__wrapper">
            <div class="progress__wrapper-file">
              <a target="_blank" :href="fileToUrl(file)">
                {{ fileName(file) }}
              </a>
              <span class="subtitle file__upload-text">
                {{ fileSize(file) }}
              </span>
            </div>
          </div>
          <div
            v-if="!isDisabled || !isDownloadOnly"
            class="cross-icon"
            @click="removeFile(file)"
          >
            <svg-icon class="cross-icon" name="cross" />
          </div>
        </div>
        <div
          class="files__loaded-file"
          v-else-if="(isDisabled || isInactive) && !isDownloadOnly"
        >
          <div class="file__drag-field-download">
            <svg-icon class="file__upload-icon" name="upload_small" />
            <div class="progress__wrapper">
              <div class="progress__wrapper-file">
                {{ fileName(file) }}
              </div>
            </div>
            <!-- <svg-icon class="file__download-icon" name="download_file" /> -->
          </div>
        </div>
        <div
          v-else-if="!isDisabled && !isInactive && isDownloadOnly"
          class="files__loaded-file"
        >
          <!-- <div class="file__info">
            <div class="file__info-text subtitle">{{ $t('main.info') }}</div>
            <Tooltip :text="toolTipText" position="top" :is-dynamic="true">
              <svg-icon class="file__info-icon" name="info_questions" />
            </Tooltip>
          </div> -->
          <a
            class="file__drag-field-download"
            target="_blank"
            :href="fileToUrl(file)"
          >
            <svg-icon class="file__upload-icon" name="upload_small" />
            <div class="progress__wrapper">
              <div class="progress__wrapper-file">
                {{ fileName(file) }}
              </div>
            </div>
            <svg-icon class="file__download-icon" name="download_file" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Comments from '@/elements/Comments.vue';
import VueI18n from '@/i18n';
import { mapGetters } from 'vuex';
export default {
  components: {
    Comments,
  },
  model: {
    prop: 'filesUploaded',
    event: 'input',
  },
  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: String,
      default: '',
    },
    isCommentable: {
      type: Boolean,
      default: false,
    },
    canComment: {
      type: Boolean,
      default: false,
    },
    commentTo: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInactive: {
      type: Boolean,
      default: false,
    },
    isDownloadOnly: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: VueI18n.t('error.required_field'),
    },
    filesUploaded: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: 0,
    },
    validFormats: {
      type: Array,
      default: () => [],
    },
    maxSizeKB: {
      type: Number,
      default: 0,
    },
    isEuKey: {
      type: Boolean,
      default: false,
    },
    isKeyformat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showError: false,
      errorMessage: '',
      dragging: false,
    };
  },

  computed: {
    ...mapGetters(['isUserHasEuKey', 'getCurrentFormType']),

    getValidFormats() {
      return this.validFormats.map((el) => `.${el}`).join(', ');
    },

    getId() {
      return this.isKeyformat ? 'file-key' : 'input__file';
    },

    toolTipText() {
      let toolTipText =
        this.$t('tooltip.file_upload_tooltip_1') +
        '\n' +
        this.$t('tooltip.file_upload_tooltip_2') +
        this.validFormats.join(', ') +
        '\n' +
        this.$t('tooltip.file_upload_tooltip_3') +
        this.maxSizeKB / 1000 +
        this.$t('tooltip.file_upload_tooltip_4');
      return toolTipText;
    },
    isAuthForm() {
      return (
        this.getCurrentFormType?.type === 'auth' &&
        this.getCurrentFormType?.isSet
      );
    },
  },
  created() {
    if (!this.filesUploaded) {
      this.$emit('input', []);
    }
  },
  methods: {
    fileToUrl(file) {
      return URL.createObjectURL(file);
    },

    handleFileDrop(e) {
      if (!this.filesUploaded) {
        this.$emit('input', []);
      }
      this.dragging = false;
      let files = e.dataTransfer.files;
      if (!files) return;

      [...files].forEach((f) => {
        if (
          this.filesUploaded &&
          !this.isMultiple &&
          this.filesUploaded.length > 0
        )
          return;
        this.addFile(f);
      });
    },
    handleFileInput(e) {
      let files = e.target.files;
      if (!files) return;
      [...files].forEach((f) => {
        if (
          !this.filesUploaded ||
          (!this.isMultiple && this.filesUploaded.length > 0)
        )
          return;
        this.addFile(f);
      });
    },
    addFile(file) {
      let fileFormat = file.name.split('.').pop();

      // Validation

      if (
        !this.validFormats.includes(fileFormat.toLowerCase()) &&
        this.validFormats.length != 0
      ) {
        this.errorMessage =
          this.$t('error.invalid_file') + this.validFormats.join(', ');
        this.showError = true;
        return 'break';
      } else if (file.size >= this.maxSizeKB * 1000 && this.maxSizeKB != 0) {
        this.errorMessage =
          this.$t('error.invalid_size') +
          (this.maxSizeKB / 1000).toString() +
          ' Мбайт';
        this.showError = true;
      } else if (this.checkIfSameFileUploaded(file)) {
        this.errorMessage = this.$t('error.file_exists');
        this.showError = true;
      } else {
        let filesToUpload = this.filesUploaded;
        filesToUpload.push(file);
        this.$emit('input', filesToUpload);
        this.showError = false;
        this.$emit('dropError');
      }
    },
    fileSize(file) {
      return `(${(file.size / 1000000).toFixed(2)} Mbyte)`;
    },
    fileName(file) {
      let fileName = file.name.split('.');
      let fileExtension = fileName.pop();
      fileName = fileName.join().slice(0, 18) + '...' + fileExtension;
      return fileName;
    },
    removeFile(item) {
      let filesAfterRemove = this.filesUploaded;
      const index = filesAfterRemove.indexOf(item);
      if (index > -1) {
        filesAfterRemove.splice(index, 1);
      }
      this.$emit('input', filesAfterRemove);
    },
    checkIfSameFileUploaded(file) {
      let fileFound = false;
      if (!this.filesUploaded) return;
      this.filesUploaded.forEach((element) => {
        if (file.size == element.size && file.name == element.name) {
          fileFound = true;
        }
      });
      return fileFound;
    },
  },
};
</script>

<style lang="sass">
.v-application a
  color: $black

.file__wrapper
  position: relative
  min-height: 15rem
  width: 100%

.file__info
  display: flex
  align-items: center
  justify-content: end
  // gap: 1rem

// .file__tooltip
//   display: flex
//   flex-direction: column

.file__info-icon, .file__upload-icon, .cross-icon
  // max-width: 2rem
  max-width: 22px
  fill: $gray

.file__download-icon
  margin-left: auto

.file__upload-text, .file__info-text, .file__upload-error
  font-size: 1.2rem
  text-align: center

.file__upload-error
  color: $error
  margin: 0 1rem


.file__drag-field, .file__drag-field-download
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  min-height: 12rem
  border: 2px dashed $gray
  transition: all 0.3s
  width: 100%

.file__wrapper
  &:not(.file_inactive)
    .file__drag-field
      cursor: pointer
      &:hover
        border-color: #0D326D
        color: #0D326D
        transition: all 0.15s


.file__drag-field-download
  flex-direction: row
  gap: 1rem
  padding: 0 3rem
  opacity: 1

.dragenter
  background-color: #0D326D05
  border: 2px dashed #0D326D


.files__loaded
  margin-top: 0.5rem
  display: flex
  flex-direction: column
  gap: 0.5rem

.files__loaded-file
  display: flex
  align-items: center
  gap: 2rem

.loading-icon svg
  height: 100%

.progress__wrapper
  display: flex
  flex-direction: column

.progress__wrapper-file
  font-size: 1.6rem
  color: $black
  & a
    margin-right: 1rem
    color: $black !important
    &:hover
      text-decoration: underline

.progress__wrapper-progress
  font-size: 1.4rem

.cross-icon
  margin-left: auto
  cursor: pointer
  z-index: 100

.input
  display: none

.error-message
  position: absolute
  padding-top: 5px
  font-size: 12px
  color: $error_color

.file__drag-field_error, .file__drag-field_error *
  border-color: $error_color
  color: $error_color
  fill: $error_color
  &:hover
    border-color: $error_color

.file_disabled
  opacity: 0.5
  cursor: not-allowed
  .file__drag-field, .file__drag-field-disabled
    cursor: not-allowed
    color: $black
    border-color: $gray
    &::hover
      border-color: $gray
  .cross-icon
    cursor: not-allowed

.file__remove-file
  width: 100%
  flex: 1
  justify-content: space-between
  padding: 0 30px

.file__remove-file *
  color: $black
  fill: $black

.file__remove-file
  display: flex
  align-items: center

.file__key-name
  font-size: 13px
  width: 80%
</style>
