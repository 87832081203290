<template>
  <div class="">
    <div class="eu-key__items-wrapper">
      <SelectField
        :items="CAsData"
        :title="$t('main.choose_key_organisation')"
        is-object-type
        title-key="issuerCName"
        :is-error="$v.getSelectedACSKForTypeFile.$error"
        :is-disabled="!isInitEuSign"
        class="eu-key__item-form"
        @select-value="setSelectedValue"
      />
      <text-field
        v-model="keyPass"
        :title="$t('main.enter_key_password')"
        :placeholder="$t('main.password')"
        :is-password="true"
        :is-error="isKeyPasswordError"
        :error-text="errorKeyPasswordMsg"
        :is-disabled="!isInitEuSign"
        class="eu-key__item-form eu-key__item-form_last-el"
      />
      <div class="eu-key__file-loader-wrapper eu-key__item-form">
        <!-- <input type="file" accept=".dat,.pfx,.pk8,.zs2,.jks" /> -->
        <FileUpload
          v-model="fileData"
          :valid-formats="keyFormats"
          isKeyformat
          :max-size-k-b="100"
          :is-error="$v.fileData.$error"
          :is-disabled="!isInitEuSign"
          class="eu-key__file-loader"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import euSignController from '@/mixins/euSign/euSignController';
import SelectField from '@/elements/SelectField.vue';
import FileUpload from '@/elements/FileUpload.vue';
export default {
  components: {
    SelectField,
    FileUpload,
  },

  mixins: [euSignController],

  props: {
    isInit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      selected: '',
      keyFormats: ['dat', 'pfx', 'pk8', 'zs2', 'jks'],
    };
  },

  watch: {
    isInit(val) {
      val && this.$v.$touch();
      !this.$v.$invalid && this.readKeyFromFile();
      this.$emit('initStatus', false);
    },
  },

  mounted() {
    this.setFileKeyLibrary();
    this.$store.commit('SET_KEY_PASSWORD', '');
    this.$store.commit('SET_KEY_FILE_DATA', null);
  },

  computed: {
    ...mapGetters([
      'getKeyFileData',
      'getSelectedACSKForTypeFile',
      'getKeyPassword',
      'isInitEuSign',
    ]),

    keyPass: {
      get() {
        return this.getKeyPassword;
      },
      set(val) {
        this.$store.commit('SET_KEY_PASSWORD', val);
      },
    },

    fileData: {
      get() {
        return !!this.getKeyFileData ? [this.getKeyFileData] : [];
      },
      set(val) {
        let file = !!val.length ? val[0] : null;
        this.$store.commit('SET_KEY_FILE_DATA', file);
      },
    },
  },

  methods: {
    setSelectedValue(val) {
      this.$store.commit('SET_SELECTED_ACSK_TYPE_FILE', val);
      if (!Boolean(val.length)) {
        this.$store.commit('SET_KEY_PASSWORD', '');
      }
    },
  },
};
</script>
