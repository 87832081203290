export const menu = [
  {
    name: 'vote',
    path_name: 'commissioner_votes-list',
    rooth_name: 'vote'
  },
  {
    name: 'vote_history',
    path_name: 'commissioner_history-list',
    rooth_name: 'history'
  },
  {
    name: 'profile',
    path_name: 'commissioner_profile',
    rooth_name: 'profile'
  },
];
