import {
  getNewsTableContentAdmin,
  getCurrentNewsContentAdmin,
  updateCurrentNewsContentAdmin,
  createNewsContentAdmin,
  deleteNewsContentAdmin,
  tourismReportsTableContentAdmin,
  linkToTemplateContentAdmin,
  uploadQuarterlyReportContentAdmin,
  tourismReportArchiveContentAdmin,
  presentationsTableContentAdmin,
  createPresentationContentAdmin,
  deletePresentationContentAdmin,
  getPresentationContentAdmin,
  updatePresentationContentAdmin,
  researchFileTableContentAdmin,
  createResearchFileContentAdmin,
  deleteResearchFileContentAdmin,
  getResearchFileContentAdmin,
  updateResearchFileContentAdmin,
} from '@/api/services/content_admin.js';
import router from '@/router';
import VueI18n from '@/i18n';
export default {
  state: {
    contentAdminNewsTable: null,
    currentNews: null,
    tourismReportsTableAdmin: null,
    templateLinkContentAdmin: null,
    statisticSaved: true,
    tourismReportArchiveAdmin: null,
    presentationsTableAdmin: null,
    currentPresentationAdmin: null,
    researchFilesTableAdmin: null,
    currentResearchFileAdmin: null,
  },

  getters: {
    getContentAdminNewsTable: (state) => state.contentAdminNewsTable,
    getContentAdminCurrentNews: (state) => state.currentNews,
    getTourismReportsTableAdmin: (state) => state.tourismReportsTableAdmin,
    getTemplateLinkContentAdmin: (state) => state.templateLinkContentAdmin,
    getTourismReportArchiveAdmin: (state) => state.tourismReportArchiveAdmin,
    getPresentationsTableAdmin: (state) => state.presentationsTableAdmin,
    getCurrentPresentationAdmin: (state) => state.currentPresentationAdmin,
    getResearchFilesTableAdmin: (state) => {
      return state.researchFilesTableAdmin && state.researchFilesTableAdmin.map(el => {
        el.research_file_type = VueI18n.t(`statistic.${el.type}`)

        return el
      })
    },
    getCurrentResearchFileAdmin: (state) => state.currentResearchFileAdmin,

    isStatisticSaved: (state) => state.statisticSaved,
  },

  mutations: {
    SET_NEWS_TABLE_CONTENT_ADMIN(state, data) {
      state.contentAdminNewsTable = data;
    },
    SET_CURRENT_NEWS_CONTENT_ADMIN(state, data) {
      state.currentNews = data;
    },
    SET_TOURISM_REPORTS_TABLE_CONTENT_ADMIN(state, data) {
      state.tourismReportsTableAdmin = data;
    },
    SET_TEMPLATE_LINK_CONTENT_ADMIN(state, link) {
      state.templateLinkContentAdmin = link;
    },
    SET_STATISTIC_SAVED(state, value) {
      state.statisticSaved = value;
    },
    SET_TOURISM_REPORT_ARCHIVE_ADMIN(state, data) {
      state.tourismReportArchiveAdmin = data;
    },
    SET_PRESENTATIONS_TABLE_CONTENT_ADMIN(state, data) {
      state.presentationsTableAdmin = data;
    },
    SET_CURRENT_PRESENTATION_CONTENT_ADMIN(state, data) {
      state.currentPresentationAdmin = data;
    },
    SET_RESEARCH_FILES_TABLE_CONTENT_ADMIN(state, data) {
      state.researchFilesTableAdmin = data;
    },
    SET_CURRENT_RESEARCH_FILE_CONTENT_ADMIN(state, data) {
      state.currentResearchFileAdmin = data;
    },
  },

  actions: {
    async getNewsTableContentAdmin({ commit, getters }) {
      let res;
      try {
        res = await getNewsTableContentAdmin(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_NEWS_TABLE_CONTENT_ADMIN', res.data.data.table.items);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log('>>> getNewsTableContentAdmin error', e.response.data);
      }
    },

    async getCurrentNewsContentAdmin({ commit }, uuid) {
      let res;
      try {
        res = await getCurrentNewsContentAdmin(uuid);
        if (res.status === 200) {
          commit('SET_CURRENT_NEWS_CONTENT_ADMIN', res.data.data.news);
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.news_is_opend')
          );
        }
      } catch (e) {
        console.log('>>>> getCurrentNewsContentAdmin error', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response);
        commit('GET_IS_ERROR', true);
      }
    },

    async updateCurrentNewsContentAdmin({ commit }, data) {
      let res;
      try {
        res = await updateCurrentNewsContentAdmin(data);
        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );
          router.push({ name: 'content-administrator_news-list' });
        }
      } catch (e) {
        console.log('>>> updateCurrentNewsContentAdmin error', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async createNewsContentAdmin({ commit }, data) {
      let res;
      try {
        res = await createNewsContentAdmin(data);
        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.news_is_created')
          );
          router.push({ name: 'content-administrator_news-list' });
        }
      } catch (e) {
        console.log('>>> createNewsContentAdmin error', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async deleteNewsContentAdmin({ commit, dispatch }, uuid) {
      let res;
      try {
        res = await deleteNewsContentAdmin(uuid);
        if (res.status === 200) {
          dispatch('getNewsTableContentAdmin');
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.news_is_deleted')
          );
        }
      } catch (e) {
        console.log('>>> deleteNewsContentAdmin error', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async getTourismReportsTableContentAdmin({ commit }) {
      let res;
      try {
        res = await tourismReportsTableContentAdmin();
        if (res.status === 200) {
          commit(
            'SET_TOURISM_REPORTS_TABLE_CONTENT_ADMIN',
            res.data.data.table.items
          );
        }
      } catch (e) {
        console.log('>>> tourismReportsTableContentAdmin error', e.response);
      }
    },

    async getLinkToTemplateContentAdmin({ commit }, data) {
      let res;
      try {
        res = await linkToTemplateContentAdmin(data);
        if (res.status === 200) {
          commit('SET_TEMPLATE_LINK_CONTENT_ADMIN', res.data.data.url);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.template_loaded')
          );
        }
      } catch (e) {
        console.log('>>> getLinkToTemplateContentAdmin error', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data);
        commit('GET_IS_ERROR', true);
      }
    },

    async uploadQuarterlyReportContentAdmin({ commit }, data) {
      commit('SET_STATISTIC_SAVED', false);
      let res;
      try {
        res = await uploadQuarterlyReportContentAdmin(data);
        if (res.status === 200) {
          commit('SET_STATISTIC_SAVED', true);
          router.push({ name: 'content-administrator_statistics-list' });
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );
        }
      } catch (e) {
        console.log('>>> uploadQuarterlyReportContentAdmin error', e.response);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async getTourismReportArchiveContentAdmin({ commit }, type) {
      let res;
      try {
        res = await tourismReportArchiveContentAdmin({ type });
        if (res.status === 200) {
          commit(
            'SET_TOURISM_REPORT_ARCHIVE_ADMIN',
            res.data.data.tourism_reports
          );
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.archive_opend')
          );
        }
      } catch (e) {
        console.log(
          '>>> getTourismReportArchiveContentAdmin error',
          e.response.data
        );
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data);
        commit('GET_IS_ERROR', true);
      }
    },

    async presentationsTableContentAdmin({ commit, getters }) {
      let res;
      try {
        res = await presentationsTableContentAdmin(getters.getTableSortData);
        if (res.status === 200) {
          commit(
            'SET_PRESENTATIONS_TABLE_CONTENT_ADMIN',
            res.data.data.table.items
          );
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> getPresentationsTableContentAdmin error',
          e.response.data
        );
      }
    },

    async createPresentationContentAdmin({ commit }, data) {
      let res;
      try {
        res = await createPresentationContentAdmin(data);
        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.presentation_created')
          );
          router.push({ name: 'content-administrator_presentations-list' });
        }
      } catch (e) {
        console.log(
          '>>> createPresentationContentAdmin error',
          e.response.data
        );
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data);
        commit('GET_IS_ERROR', true);
      }
    },

    async updatePresentationContentAdmin({ commit }, data) {
      let res;
      try {
        res = await updatePresentationContentAdmin(data);
        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );
          router.push({ name: 'content-administrator_presentations-list' });
        }
      } catch (e) {
        console.log(
          '>>> updatePresentationContentAdmin error',
          e.response.data
        );
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data);
        commit('GET_IS_ERROR', true);
      }
    },

    async getPresentationContentAdmin({ commit }, uuid) {
      let res;
      try {
        res = await getPresentationContentAdmin({ uuid });
        if (res.status === 200) {
          commit(
            'SET_CURRENT_PRESENTATION_CONTENT_ADMIN',
            res.data.data.presentation
          );
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.presentation_opend')
          );
        }
      } catch (e) {
        console.log('>>> getPresentationContentAdmin error', e.response.data);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data);
        commit('GET_IS_ERROR', true);
      }
    },

    async deletePresentationContentAdmin({ commit, dispatch }, uuid) {
      let res;
      try {
        res = await deletePresentationContentAdmin({ uuid });
        if (res.status === 200) {
          dispatch('presentationsTableContentAdmin');
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.presentation_deleted')
          );
        }
      } catch (e) {
        console.log(
          '>>> deletePresentationContentAdmin error',
          e.response.data
        );
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data);
        commit('GET_IS_ERROR', true);
      }
    },

    async researchFilesTableContentAdmin({ commit, getters }) {
      let res;
      try {
        res = await researchFileTableContentAdmin(getters.getTableSortData);
        if (res.status === 200) {
          commit(
            'SET_RESEARCH_FILES_TABLE_CONTENT_ADMIN',
            res.data.data.table.items
          );
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> getResearchFileTableContentAdmin error',
          e.response.data
        );
      }
    },

    async createResearchFileContentAdmin({ commit }, data) {
      let res;
      try {
        res = await createResearchFileContentAdmin(data);
        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.research_file_created')
          );
          router.push({ name: 'content-administrator_research-files-list' });
        }
      } catch (e) {
        console.log(
          '>>> createResearchFileContentAdmin error',
          e.response.data
        );
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data);
        commit('GET_IS_ERROR', true);
      }
    },

    async getResearchFileContentAdmin({ commit }, uuid) {
      let res;
      try {
        res = await getResearchFileContentAdmin({ uuid });
        if (res.status === 200) {
          commit(
            'SET_CURRENT_RESEARCH_FILE_CONTENT_ADMIN',
            res.data.data.researchFile
          );
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.research_file_opend')
          );
        }
      } catch (e) {
        console.log('>>> getResearchContentAdmin error', e.response.data);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data);
        commit('GET_IS_ERROR', true);
      }
    },

    async updateResearchFileContentAdmin({ commit }, data) {
      let res;
      try {
        res = await updateResearchFileContentAdmin(data);
        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );
          router.push({ name: 'content-administrator_research-files-list' });
        }
      } catch (e) {
        console.log(
          '>>> updateResearchFileContentAdmin error',
          e.response.data
        );
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data);
        commit('GET_IS_ERROR', true);
      }
    },

    async deleteResearchFileContentAdmin({ commit, dispatch }, uuid) {
      let res;
      try {
        res = await deleteResearchFileContentAdmin({ uuid });
        if (res.status === 200) {
          dispatch('researchFilesTableContentAdmin');
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.research_file_deleted')
          );
        }
      } catch (e) {
        console.log(
          '>>> deleteResearchFileContentAdmin error',
          e.response.data
        );
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data);
        commit('GET_IS_ERROR', true);
      }
    },
  },
};
