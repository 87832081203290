export const menu = [
  {
    name: 'news',
    path_name: 'content-administrator_news-list',
    rooth_name: 'news',
  },
  {
    name: 'statistics',
    path_name: 'content-administrator_statistics-list',
    rooth_name: 'statistics',
  },
  {
    name: 'presentations',
    path_name: 'content-administrator_presentations-list',
    rooth_name: 'presentation',
  },
  {
    name: 'research-files',
    path_name: 'content-administrator_research-files-list',
    rooth_name: 'research-files',
  },
  {
    name: 'profile',
    path_name: 'content-administrator_profile',
    rooth_name: 'profile',
  },
];
