import { Axios, API_VERSION } from '@/api/config';

// export const getAuthUserInfo = () => {
//   const url = `${API_VERSION}/authorization/user`;
//   return Axios.get(url);
// };

export const getTableOfVotingHistoryCommissioner = (data) => {
  const url = `${API_VERSION}/commissioner/hotels/history-voting/table`;
  return Axios.get(url, { params: data });
};

export const getTableOfVotingCommissioner = (data) => {
  const url = `${API_VERSION}/commissioner/hotels/voting/table?sort=+number`;
  return Axios.get(url, { params: data });
};

export const getCategorizationCommissioner = (uuid) => {
  const url = `${API_VERSION}/commissioner/hotels/categorization-applications?uuid=${uuid}`;
  return Axios.get(url);
};

export const getCommentsCommissioner = (uuid) => {
  const url = `${API_VERSION}/commissioner/hotels/categorization-applications/comments?categorization_application_uuid=${uuid}`;
  return Axios.get(url);
};

export const confirmVoteCommissioner = (payload) => {
  const url = `${API_VERSION}/commissioner/hotels/voting/to-vote`;
  return Axios.post(url, payload);
};

export const addCommentCommissioner = (data) => {
  const url = `${API_VERSION}/commissioner/hotels/categorization-applications/comments`;
  return Axios.post(url, data);
};
