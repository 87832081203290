export default [
  {
    path: '/content-administrator',
    name: 'content_administrator',
    component: () => import('@/components/content_admin/Main.vue'),
    redirect: { name: 'content-administrator_news-list' },

    children: [
      {
        path: 'news',
        name: 'content-administrator_news-list',
        component: () => import('@/components/content_admin/NewsList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'statistics',
        name: 'content-administrator_statistics-list',
        component: () =>
          import('@/components/content_admin/StatisticsList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'presentations',
        name: 'content-administrator_presentations-list',
        component: () =>
          import('@/components/content_admin/PresentationsList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'presentation/create',
        name: 'content-administrator_presentation-create',
        component: () => import('@/components/content_admin/Presentation.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'presentation/:id',
        name: 'content-administrator_presentation-review',
        component: () => import('@/components/content_admin/Presentation.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'research-files',
        name: 'content-administrator_research-files-list',
        component: () =>
          import('@/components/content_admin/ResearchFiles/ResearchFilesList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'research-files/create',
        name: 'content-administrator_research-files-create',
        component: () => import('@/components/content_admin/ResearchFiles/ResearchFile.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'research-files/:id',
        name: 'content-administrator_research-files-review',
        component: () => import('@/components/content_admin/ResearchFiles/ResearchFile.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'profile',
        name: 'content-administrator_profile',
        component: () => import('@/components/content_admin/Profile.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'news/create',
        name: 'content-administrator_create-news',
        component: () => import('@/components/content_admin/NewsPage.vue'),
        meta: {
          name: 'additing_news',
          isMenu: true,
          goBack: -1,
        },
      },
      {
        path: 'news/:id',
        name: 'content-administrator_news-review',
        component: () => import('@/components/content_admin/NewsPage.vue'),
        meta: {
          isMenu: true,
          goBack: -1,
        },
      },
      {
        path: 'statistic/create',
        name: 'content-administrator_statistics-create',
        component: () => import('@/components/content_admin/Statistic.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'statistic/:id',
        name: 'content-administrator_statistics-review',
        component: () => import('@/components/content_admin/Statistic.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'archive',
        name: 'content-administrator_statistics-archive',
        component: () => import('@/components/content_admin/Archive.vue'),
        meta: {
          name: 'additing_news',
          isMenu: true,
          goBack: -1,
        },
      },
    ],
  },
];
