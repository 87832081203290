export default [
  {
    filing_status: 'draft',
    review_status: 'waiting_for_submission',
    icon: 'status_draft',
  },
  {
    filing_status: 'submitted',
    review_status: 'pending_review',
    icon: 'status_clock',
  },
  {
    filing_status: 'draft',
    review_status: 'needs_improvement',
    icon: 'status_pencil_ruler',
  },
  {
    filing_status: 'submitted',
    review_status: 'waiting_for_voting_date',
    icon: 'status_calendar_yellow',
  },
  {
    filing_status: 'submitted',
    review_status: 'waiting_for_voting_date_signed',
    icon: 'status_flag_yellow',
  },
  {
    filing_status: 'submitted',
    review_status: 'waiting_for_voting',
    icon: 'status_flag_green',
  },
  {
    filing_status: 'submitted',
    review_status: 'approved',
    icon: 'status_approved',
  },
  {
    filing_status: 'submitted',
    review_status: 'declined',
    icon: 'status_declined',
  },
];
