export const menu = [
  {
    name: 'vote',
    path_name: 'secretary-commission_votes-list',
    rooth_name: 'vote'
  },
  {
    name: 'categorization',
    path_name: 'secretary-commission_categorization-list',
    rooth_name: 'categorization'
  },
  {
    name: 'vote_history',
    path_name: 'secretary-commission_history-list',
    rooth_name: 'history'
  },
  {
    name: 'profile',
    child_name: '',
    path_name: 'secretary-commission_profile',
    rooth_name: 'profile'
  },
];
