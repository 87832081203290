export default [
  {
    filing_status: 'submitted',
    review_status: 'approved',
    buttons: ['print', 'downloadCertificate', 'downloadPDF'],
    comments_visible: false,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'submitted',
    review_status: 'declined',
    buttons: ['print'],
    comments_visible: false,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'draft',
    review_status: 'waiting_for_submission',
    // buttons: ['saveAsDraft', 'signAndSend', 'print'],
    buttons: ['saveAsDraft', 'certification_agency_signAndSend'],
    comments_visible: false,
    can_comment: false,
    edit: 'can_edit_all_fields',
    schedule: 'none',
  },
  {
    filing_status: 'submitted',
    review_status: 'pending_review',
    buttons: ['print'],
    comments_visible: false,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'submitted',
    review_status: 'waiting_for_voting_date',
    buttons: ['print'],
    comments_visible: false,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'submitted',
    review_status: 'waiting_for_voting_date_signed',
    buttons: ['print'],
    comments_visible: false,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'submitted',
    review_status: 'waiting_for_voting',
    buttons: ['print'],
    comments_visible: false,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'draft',
    review_status: 'needs_improvement',
    buttons: ['makeImprovement', 'saveAsDraft', 'print'],
    comments_visible: true,
    can_comment: false,
    edit: 'can_edit_commented_fields',
    schedule: 'none',
  },
  {
    filing_status: undefined,
    review_status: undefined,
    // buttons: ['createReport', 'createAndSignReport', 'print'],
    buttons: ['createReport', 'createAndSignReport'],
    comments_visible: false,
    can_comment: false,
    edit: 'can_edit_all_fields',
    schedule: 'none',
  },
];
