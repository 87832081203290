import CAs from '/public/js/data/CAs.json';
import euSignFileKey from '@/mixins/euSign/euSignFileKey';
import euSignHardwareKey from '@/mixins/euSign/euSignHardwareKey';
import euSignValidation from '@/mixins/euSign/euSignValidation';
import { mapGetters } from 'vuex';

export default {
  mixins: [euSignFileKey, euSignHardwareKey, euSignValidation],
  data() {
    return {
      euSign: null,

      euSettings: {
        language: 'uk',
        encoding: 'utf-8',
        httpProxyServiceURL: process.env.VUE_APP_API_HOST + '/proxy',
        directAccess: false,
        CAs: CAs,
        CACertificates: '/js/data/CACertificates.p7b',
      },
      readingKeyError: false,
      readingKeyErrorMsg: '',
    };
  },

  computed: {
    // ...mapGetters(['isInitEuSign', 'getSelectedKeyType']),
    ...mapGetters('modal', ['getModalState']),

    CAsData() {
      return CAs.map((el) => ({ issuerCName: el.issuerCNs[0], ...el }));
    },
    // buttonStatus() {
    //   return !this.getModalState ? this.isInitEuSign : true;
    // },
  },

  methods: {
    openModal(keyOwnerInfo) {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'key-info',
        size: 'xl',
        props: keyOwnerInfo,
      });
    },

    resetValidationData() {
      this.readingKeyError = false;
      this.readingKeyErrorMsg = '';
      this.$v.$reset();
    },
  },
};
