<template>
  <div class="eu-key__file-loader-wrapper eu-key__item_el">
    <FileUpload
      :maxSizeKB="100"
      isEuKey
      class="eu-key__file-loader"
      @remove="deleteEuKey"
    />
  </div>
</template>

<script>
import FileUpload from '@/elements/FileUpload.vue';
export default {
  components: {
    FileUpload,
  },

  data() {
    return {};
  },

  methods: {
    deleteEuKey() {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'accept',
        size: 'xl',
        handler: { name: 'removeUserEuKey', payload: null },
      });
    },
  },
};
</script>
