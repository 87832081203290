import Vue from 'vue';
import App from './App.vue';
import { generateFilterBlockName } from './utils/filterBlockName';
import router from './router';
import store from './store';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import vClickOutside from 'v-click-outside';
import TextField from '@/elements/TextField.vue';
import Button from '@/elements/Button.vue';
import SvgIcon from '@/elements/SvgIcon.vue';
import Vuelidate from 'vuelidate';
import Checkbox from '@/elements/Checkbox.vue';
import Tooltip from '@/elements/Tooltip.vue';
import InputSearch from '@/elements/InputSearch.vue';
import NavMenu from '@/views/NavMenu.vue';
import HeaderMain from '@/views/HeaderMain.vue';
import Loader from '@/elements/Loader.vue';
import NotificationMessage from '@/elements/NotificationMessage.vue';
import LoadingSpinner from '@/elements/LoadingSpinner.vue';
import VueTagsInput from '@johmun/vue-tags-input';

import '@/assets/sass/_main-styles.sass';

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

Vue.prototype.$generateFilterBlockName = generateFilterBlockName;

Vue.component('Button', Button);
Vue.component('TextField', TextField);
Vue.component('svg-icon', SvgIcon);
Vue.component('Checkbox', Checkbox);
Vue.component('Tooltip', Tooltip);
Vue.component('InputSearch', InputSearch);
Vue.component('NavMenu', NavMenu);
Vue.component('HeaderMain', HeaderMain);
Vue.component('Loader', Loader);
Vue.component('NotificationMessage', NotificationMessage);
Vue.component('LoadingSpinner', LoadingSpinner);
Vue.component('vue-tags-input', VueTagsInput);
Vue.use(Vuelidate);
Vue.use(vClickOutside);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
