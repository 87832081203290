export const table = {
  'search-results': {
    columns: [
      {
        name: 'name',
        isSortable: true,
      },
      {
        name: 'settlement',
        isSortable: true,
      },
      {
        name: 'star_category',
        isSortable: false,
      },
      {
        name: 'state',
        isSortable: true,
      },
      {
        name: 'type',
        isSortable: true,
      },
      {
        name: 'code_edrpou',
        isSortable: true,
      },
    ],
    actions: 'readonly',
  },
  hotel: 'search-results-details_hotel',
  tour_operator: 'search-results-details_tour-operator',
};
