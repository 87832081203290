export default [
  {
    path: '/secretary-commission',
    name: 'secretary_of_the_commission',
    component: () => import('@/components/secretary_commission/Main.vue'),
    redirect: { name: 'secretary-commission_votes-list' },

    children: [
      {
        path: 'votes-list',
        name: 'secretary-commission_votes-list',
        component: () =>
          import('@/components/secretary_commission/VotesList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'requests-list',
        name: 'secretary-commission_categorization-list',
        component: () =>
          import('@/components/secretary_commission/RequestsList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'vote-history',
        name: 'secretary-commission_history-list',
        component: () =>
          import('@/components/secretary_commission/VoteHistory.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'categorization/:id',
        name: 'secretary-commission_categorization-review',
        component: () =>
          import('@/components/secretary_commission/Categorization.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'categorization/:id',
        name: 'secretary-commission_vote-review',
        component: () =>
          import('@/components/secretary_commission/Categorization.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'categorization/:id',
        name: 'secretary-commission_history-review',
        component: () =>
          import('@/components/secretary_commission/Categorization.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'profile',
        name: 'secretary-commission_profile',
        component: () =>
          import('@/components/secretary_commission/Profile.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'categorization/voting-results/:id',
        name: 'secretary-commission_voting-results',
        component: () => import('@/components/vote/Voting.vue'),
        meta: {
          name: 'vote_results',
          isMenu: false,
          goBack: -1,
        },
      },
      // ======== for deep pages
      // {
      //   path: 'create-news',
      //   name: 'content_administrator-create-news',
      //   component: () => import('@/components/content_admin/NewsPage.vue'),
      //   meta: {
      //     isMenu: false,
      //     name: 'additing_news',
      //   },
      // },
    ],
  },
];
