<template>
  <div class="loading loading__wrapper">
    <div class="loading__text">
      {{ loaderText }}
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="20px"
      height="20px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      class="loading__spinner"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#B6B6B6"
        stroke-width="10"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  </div>
</template>
<script>
import VueI18n from '@/i18n';
export default {
  props: {
    loaderText: {
      type: String,
      default: VueI18n.t('main.loading'),
    },
  },
};
</script>
<style lang="sass" scoped>
.loading__wrapper
  display: flex
  align-items: center

.loading__text
  margin-right: 10px

.loading__spinner
  background: transparent
  shape-rendering: auto
</style>
