export default [
  {
    path: '/commissioner',
    name: 'commissioner',
    component: () => import('@/components/commissioner/Main.vue'),
    redirect: { name: 'commissioner_votes-list' },

    children: [
      {
        path: 'votes-list',
        name: 'commissioner_votes-list',
        component: () => import('@/components/commissioner/VotesList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'categorization/:id',
        name: 'commissioner_vote-review',
        component: () => import('@/components/commissioner/Categorization.vue'),
        meta: {
          isMenu: true,
          status: 'review',
        },
      },
      {
        path: 'categorization/:id',
        name: 'commissioner_history-review',
        component: () => import('@/components/commissioner/Categorization.vue'),
        meta: {
          isMenu: true,
          status: 'review',
        },
      },
      {
        path: 'votes-history',
        name: 'commissioner_history-list',
        component: () => import('@/components/commissioner/VotesHistory.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'profile',
        name: 'commissioner_profile',
        component: () => import('@/components/commissioner/Profile.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'categorization/voting-results/:id',
        name: 'commissioner_voting-results',
        component: () => import('@/components/vote/Voting.vue'),
        meta: {
          isMenu: false,
          name: 'vote_results',
          goBack: -2,
        },
      },
    ],
  },
];
