export const menu = [
  {
    name: 'hotels',
    path_name: 'access-administrator_hotels-list',
    rooth_name: 'hotels',
  },
  {
    name: 'tour_operators',
    path_name: 'access-administrator_tour-operators-list',
    rooth_name: 'tour_operators',
  },
  {
    name: 'dart_menu',
    path_name: 'access-administrator_dart-list',
    rooth_name: 'dart',
  },
  {
    name: 'commissioner',
    path_name: 'access-administrator_commissioner-list',
    rooth_name: 'commissioner',
  },
  {
    name: 'content_administrator',
    path_name: 'access-administrator_content-admin-list',
    rooth_name: 'content_administrator',
  },
  // {
  //   name: 'certification_agency',
  //   path_name: 'access-administrator_certification-agency-list',
  //   rooth_name: 'content_administrator',
  // },
  {
    name: 'access_administrator',
    path_name: 'access-administrator_access-admin-list',
    rooth_name: 'access_administrator',
  },
  {
    name: 'actions_log',
    path_name: 'access-administrator_actions-log-list',
    rooth_name: 'actions_log',
  },
  {
    name: 'profile',
    path_name: 'access-administrator_profile',
    rooth_name: 'profile',
  },
];
