export const table = {
  'deputy-presiding-commissioner_tour-operator-reports': {
    path_name: 'deputy-presiding-commissioner_tour-operator-report_review',
    columns: [
      {
        name: 'review_status',
        isSortable: false,
      },
      {
        name: 'number',
        isSortable: true,
        // alignCenter: true
      },
      {
        name: 'name',
        isSortable: true,
      },
      {
        name: 'signed_at',
        type: 'date',
        isSortable: true,
      },
    ],
    tooltip: {
      approved: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
    },
    actions: {
      approved: 'readonly',
    },
  },
  'deputy-presiding-commissioner_votes-list': {
    path_name: 'deputy-presiding-commissioner_vote-review',
    columns: [
      {
        name: 'review_status',
        isSortable: false,
      },
      {
        name: 'number',
        isSortable: true,
        // alignCenter: true
      },
      {
        name: 'name_of_legal_entity',
        isSortable: true,
      },
      {
        name: 'categorization_name',
        isSortable: true,
      },
      {
        name: 'submitted_at',
        isSortable: true,
        type: 'date',
      },
      {
        name: 'voting_at',
        isSortable: true,
        type: 'date',
      },
    ],
    tooltip: {
      waiting_for_voting: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        // {
        //   name: 'downloadPDF',
        //   isRouting: false,
        //   handler: ''
        // },
      ],
    },
    actions: {
      waiting_for_voting: 'readonly',
    },
  },
  'deputy-presiding-commissioner_history-list': {
    path_name: 'deputy-presiding-commissioner_history-review',
    columns: [
      {
        name: 'review_status',
        isSortable: true,
      },
      {
        name: 'number',
        isSortable: true,
        // alignCenter: true
      },
      {
        name: 'name_of_legal_entity',
        isSortable: true,
      },
      {
        name: 'categorization_name',
        isSortable: true,
      },
      {
        name: 'submitted_at',
        isSortable: true,
        type: 'date',
      },
      {
        name: 'voting_at',
        isSortable: true,
        type: 'date',
      },
    ],
    tooltip: {
      approved: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
      declined: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
    },
    actions: {
      approved: 'readonly',
      declined: 'readonly',
    },
  },
};
