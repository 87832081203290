import 'vue2-datepicker/locale/uk';

export default {
  formatLocale: {
    firstDayOfWeek: 1,
  },
  months: [
    'Січень',
    'Лютий',
    'Березень',
    'Квітень',
    'Травень',
    'Червень',
    'Липень',
    'Серпень',
    'Вересень',
    'Жовтень',
    'Листопад',
    'Грудень',
  ],
};
