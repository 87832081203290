export const table = {
  'access-administrator_hotels-list': {
    path_name: 'access-administrator_hotel-user',
    columns: [
      {
        // name: 'certificate_status', Notes
        name: 'notes',
        isSortable: true,
      },
      {
        name: 'title',
        isSortable: true,
      },
      {
        name: 'settlement',
        isSortable: true,
      },
      {
        name: 'code_main',
        isSortable: true,
      },
    ],
    tooltip: [
      {
        name: 'readonly',
        type_action: 'readonly',
        isRouting: true,
        handler: null,
      },
      // {
      //   isBlock: true,
      //   name: '',
      //   handler: 'test',
      // },
    ],
    actions: 'readonly',
  },

  'access-administrator_tour-operators-list': {
    path_name: 'access-administrator_tour-operator-user',
    columns: [
      {
        name: 'type_of_activity',
        isSortable: true,
      },
      {
        name: 'name_of_legal_entity',
        isSortable: true,
      },
      {
        name: 'mail_address',
        isSortable: true,
      },
      {
        name: 'code_main',
        isSortable: true,
      },
      {
        name: 'date_of_state_registration',
        type: 'date',
        isSortable: true,
      },
    ],
    tooltip: [
      {
        name: 'readonly',
        type_action: 'readonly',
        isRouting: true,
        handler: null,
      },
      // {
      //   isBlock: true,
      // },
    ],
    actions: 'readonly',
  },

  'access-administrator_dart-list': {
    path_name: 'access-administrator_dart-review-user',
    columns: [
      {
        name: 'full_name',
        isSortable: true,
      },
      {
        name: 'position',
        isSortable: true,
      },
      {
        name: 'created_at',
        type: 'date',
        isSortable: true,
      },
      {
        name: 'working_phone_number',
        isSortable: true,
      },
    ],
    tooltip: [
      {
        name: 'readonly',
        type_action: 'readonly',
        isRouting: true,
        handler: null,
      },
      {
        name: 'edit',
        type_action: 'edit',
        isRouting: true,
        handler: null,
      },
      {
        isBlock: true,
        name: '',
        handler: 'blockUnblockDartAccessAdmin',
      },
    ],
    actions: 'edit',
  },

  'access-administrator_commissioner-list': {
    path_name: 'access-administrator_commissioner-review-user',
    columns: [
      {
        name: 'full_name',
        isSortable: true,
      },
      {
        name: 'position',
        isSortable: true,
      },
      {
        name: 'created_at',
        type: 'date',
        isSortable: true,
      },
      {
        name: 'working_phone_number',
        isSortable: true,
      },
    ],
    tooltip: [
      {
        name: 'readonly',
        type_action: 'readonly',
        isRouting: true,
        handler: null,
      },
      {
        name: 'edit',
        type_action: 'edit',
        isRouting: true,
        handler: null,
      },
      {
        isBlock: true,
        name: '',
        handler: 'blockUnblockComissionerAccessAdmin',
      },
    ],
    actions: 'edit',
  },

  'access-administrator_content-admin-list': {
    path_name: 'access-administrator_content-admin-review-user',
    columns: [
      {
        name: 'full_name',
        isSortable: true,
      },
      {
        name: 'position',
        isSortable: true,
      },
      {
        name: 'created_at',
        type: 'date',
        isSortable: true,
      },
      {
        name: 'working_phone_number',
        isSortable: true,
      },
    ],
    tooltip: [
      {
        name: 'readonly',
        type_action: 'readonly',
        isRouting: true,
        handler: null,
      },
      {
        name: 'edit',
        type_action: 'edit',
        isRouting: true,
        handler: null,
      },
      {
        isBlock: true,
        name: '',
        handler: 'blockUnblockContentAdminAccessAdmin',
      },
    ],
    actions: 'edit',
  },

  'access-administrator_access-admin-list': {
    path_name: 'access-administrator_access-admin-review-user',
    columns: [
      {
        name: 'full_name',
        isSortable: true,
      },
      {
        name: 'position',
        isSortable: true,
      },
      {
        name: 'created_at',
        type: 'date',
        isSortable: true,
      },
      {
        name: 'working_phone_number',
        isSortable: true,
      },
    ],
    tooltip: [
      {
        name: 'readonly',
        type_action: 'readonly',
        isRouting: true,
        handler: null,
      },
      {
        name: 'edit',
        type_action: 'edit',
        isRouting: true,
        handler: null,
      },
      {
        isBlock: true,
        name: '',
        handler: 'blockUnblockAccessAdminAccessAdmin',
      },
    ],
    actions: 'edit',
  },

  'access-administrator_actions-log-list': {
    path_name: null,
    columns: [
      {
        name: 'username',
        isSortable: true,
      },
      {
        name: 'action',
        isSortable: true,
      },
      {
        name: 'ip',
        isSortable: true,
      },
      {
        name: 'date_time',
        type: 'date',
        isSortable: true,
      },
    ],
    tooltip: null,
    actions: null,
  },

  'access-administrator_certification-agency-list': {
    path_name: 'access-administrator_certification-agency-review-user',
    columns: [
      {
        name: 'certification_agency_full_name',
        isSortable: true,
      },
      {
        name: 'created_at',
        type: 'date',
        isSortable: true,
      },
      {
        name: 'phone',
        isSortable: true,
      },
    ],
    tooltip: [
      {
        name: 'readonly',
        type_action: 'readonly',
        isRouting: true,
        handler: null,
      },
      {
        name: 'edit',
        type_action: 'edit',
        isRouting: true,
        handler: null,
      },
      {
        isBlock: true,
        name: '',
        handler: 'blockUnblockContentAdminAccessAdmin',
      },
    ],
    actions: 'edit',
  },
};
