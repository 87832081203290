export const table = {
  'secretary-commission_votes-list': {
    path_name: 'secretary-commission_vote-review',
    columns: [
      {
        name: 'review_status',
        isSortable: true,
      },
      {
        name: 'number',
        isSortable: true,
        // alignCenter: true
      },
      {
        name: 'name_of_legal_entity',
        isSortable: true,
      },
      {
        name: 'categorization_name',
        isSortable: true,
      },
      {
        name: 'submitted_at',
        type: 'date',
        isSortable: true,
      },
      {
        name: 'voting_at',
        type: 'date',
        isSortable: true,
      },
    ],
    tooltip: {
      waiting_for_voting: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        // {
        //   name: 'downloadPDF',
        //   isRouting: false,
        //   handler: '',
        // },
      ],
      waiting_for_voting_date_signed: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        // {
        //   name: 'downloadPDF',
        //   isRouting: false,
        //   handler: '',
        // },
      ],
    },
    actions: {
      waiting_for_voting: 'readonly',
      waiting_for_voting_date_signed: 'readonly',
    },
  },
  'secretary-commission_categorization-list': {
    path_name: 'secretary-commission_categorization-review',
    columns: [
      {
        name: 'review_status',
        isSortable: true,
      },
      {
        name: 'number',
        isSortable: true,
        // alignCenter: true
      },
      {
        name: 'name_of_legal_entity',
        isSortable: true,
      },
      {
        name: 'categorization_name',
        isSortable: true,
      },
      {
        name: 'submitted_at',
        type: 'date',
        isSortable: true,
      },
    ],
    tooltip: {
      pending_review: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        // {
        //   name: 'downloadPDF',
        //   isRouting: false,
        //   handler: '',
        // },
      ],
      waiting_for_voting_date: [
        {
          name: 'consider',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        // {
        //   name: 'downloadPDF',
        //   isRouting: false,
        //   handler: '',
        // },
      ],
    },
    actions: {
      pending_review: 'readonly',
      waiting_for_voting_date: 'readonly',
    },
  },
  'secretary-commission_history-list': {
    path_name: 'secretary-commission_history-review',
    columns: [
      {
        name: 'review_status',
        isSortable: true,
      },
      {
        name: 'number',
        isSortable: true,
        // alignCenter: true
      },
      {
        name: 'name_of_legal_entity',
        isSortable: true,
      },
      {
        name: 'categorization_name',
        isSortable: true,
      },
      {
        name: 'submitted_at',
        type: 'date',
        isSortable: true,
      },
      {
        name: 'voting_at',
        type: 'date',
        isSortable: true,
      },
    ],
    tooltip: {
      approved: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
      declined: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
    },
    actions: {
      approved: 'readonly',
      declined: 'readonly',
    },
  },
};
