<template>
  <div class="modal__container-inner">
    <div class="accept accept__box">
      <h3 class="accept__title modal__mb-20 title-h4">
        {{ $t('modals.attention') }}
      </h3>
      <div class="accept__content">
        {{ $t('modals.assurance_action') }}
      </div>
      <div class="modal__btn-2col-center">
        <Button white @click.native="closeModal" :disabled="!isRequestLoaded">
          {{ $t('modals.cancel') }}
        </Button>
        <Button @click.native="acceptHandler" :disabled="!isRequestLoaded">
          {{ $t('modals.confirm') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters('modal', ['getModalProps', 'getModalHandler']),
    ...mapGetters(['isRequestLoaded']),
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },

    async acceptHandler() {
      const { name, payload } = this.getModalHandler;

      await this.$store.dispatch(name, payload);
      this.closeModal();
    },
  },
};
</script>

<style lang="sass" scoped>

.accept__box
  padding: 0 80px

.accept__box *
  text-align: center

.accept__content
  color: $gray
  margin-bottom: 60px
</style>
