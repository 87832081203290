import {
  createReportsDraft,
  editReportsDraft,
  getReportsData,
  deleteReportsDraft,
  getTourOperatorReportsTable,
  getIsReportsTableExistsDraft,
  іsignTourOperatorReport,
  getReportsComments,
  existingReportInCurrentPeriod,
} from '@/api/services/tour_operator';
import router from '@/router';
import VueI18n from '@/i18n';

export default {
  state: {
    reportsTable: null,
    reportsTableExistsDraft: false,
    reportsData: null,
    reportsComments: null,
    existingReport: false,
    reportDraftSaved: false,
  },

  getters: {
    getTourOperatorReportsTable: (state) => state.reportsTable,
    getReportsData: (state) => state.reportsData,
    isReportsTableExistsDraft: (state) => state.reportsTableExistsDraft,
    getReportsComments: (state) => state.reportsComments,
    isExistingReport: (state) => state.existingReport,
    isReportDraftSaved: (state) => state.reportDraftSaved,
  },

  mutations: {
    SET_PEPORTS_TABLE(state, data) {
      state.reportsTable = data;
    },
    SET_REPORTS_TABLE_EXISTS_DRAFT(state, value) {
      state.reportsTableExistsDraft = value;
    },
    SET_REPORTS_DATA(state, data) {
      state.reportsData = data;
    },
    SET_REPORTS_COMMENTS(state, data) {
      state.reportsComments = data;
    },
    SET_EXISTING_REPORT(state, value) {
      state.existingReport = value;
    },
    SET_REPORT_DRAFT_SAVED(state, value) {
      state.reportDraftSaved = value;
    },
  },

  actions: {
    async getTourOperatorReportsTable({ commit, dispatch, getters }) {
      let res;
      try {
        res = await getTourOperatorReportsTable(getters.getTableSortData);
        if (res.status === 200) {
          dispatch('getIsReportsTableExistsDraft');
          dispatch('checkExistingReportInCurrentPeriod');
          commit('SET_PEPORTS_TABLE', res.data.data.table);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log('>>>> getTourOperatorReportsTable error', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response);
        commit('GET_IS_ERROR', true);
      }
    },

    async getIsReportsTableExistsDraft({ commit }) {
      let res;
      try {
        res = await getIsReportsTableExistsDraft();
        if (res.status === 200) {
          commit('SET_REPORTS_TABLE_EXISTS_DRAFT', res.data.data.exists_draft);
        }
      } catch (e) {
        console.log('>>>> getIsDraftInTable error', e);
      }
    },

    async saveReportAsDraft({ commit }, { data, isSign = false }) {
      let res;
      try {
        res = await createReportsDraft(data);
        if (res.status === 200) {
          commit('SET_REPORT_DRAFT_SAVED', true);
          if (isSign) {
            commit('SET_DATA_TO_SIGN', res.data.data.report.uuid);
            commit('SET_CURRENT_FORM_TYPE', 'report-tour-operator');
            commit('modal/SET_MODAL', {
              state: true,
              name: 'add-key',
              size: 'l',
            });
          } else {
            // router.push({ name: 'tour-operator_reports-list' });
            router.push({ name: 'tour_operator' });
            commit('GET_IS_ERROR', false);
            commit('SHOW_NOTIFICATION', true);
            commit(
              'GET_RESPONSE_MESSAGE',
              VueI18n.t('notification.data_is_saved')
            );
          }
        }
      } catch (e) {
        console.log('>>>> saveReportAsDraft error', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response);
        commit('GET_IS_ERROR', true);
      }
    },

    async editReportAsDraft({ commit }, { data, isSign = false }) {
      let res;
      try {
        res = await editReportsDraft(data);
        if (res.status === 200) {
          if (isSign) {
            commit('SET_CURRENT_FORM_TYPE', 'report-tour-operator');
            commit('modal/SET_MODAL', {
              state: true,
              name: 'add-key',
              size: 'l',
            });
          } else {
            router.push({ name: 'tour-operator_reports-list' });
            commit('GET_IS_ERROR', false);
            commit('SHOW_NOTIFICATION', true);
            commit(
              'GET_RESPONSE_MESSAGE',
              VueI18n.t('notification.data_is_saved')
            );
          }
        }
      } catch (e) {
        console.log('>>>> editReportAsDraft error', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response);
        commit('GET_IS_ERROR', true);
      }
    },

    async getReportsData({ commit }, data) {
      let res;
      try {
        res = await getReportsData(data);
        if (res.status === 200) {
          commit('SET_REPORTS_DATA', res.data.data.report);
        }
      } catch (e) {
        console.log('>>>> getReportsData error', e);
      }
    },

    async deleteReportsDraft({ commit, dispatch }, uuid) {
      let res;
      try {
        res = await deleteReportsDraft({ report_uuid: uuid });
        if (res.status === 200) {
          dispatch('getTourOperatorReportsTable');
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.draft_deleted')
          );
        }
      } catch (e) {
        console.log('>>>> deleteReportsDraft error', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response);
        commit('GET_IS_ERROR', true);
      }
    },

    async signTourOperatorReport({ commit }, payload) {
      let res;
      commit('SET_RQUEST_LOADED', false);

      try {
        res = await іsignTourOperatorReport({ ...payload });
        if (res.status === 200) {
          commit('SET_RQUEST_LOADED', true);
          router.push({ name: 'tour-operator_reports-list' });
          commit('SET_RQUEST_LOADED', true);
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', VueI18n.t('table.signed'));
        }
        commit('SET_RQUEST_LOADED', false);
      } catch (e) {
        console.log('>>>> signTourOperatorReport error', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response);
        commit('GET_IS_ERROR', true);
      }
    },

    async getReportsComments({ commit }, uuid) {
      let res;
      try {
        res = await getReportsComments({ report_uuid: uuid });
        if (res.status === 200) {
          commit('SET_REPORTS_COMMENTS', res.data.data.criterias);
        }
      } catch (e) {
        console.log('>>>> getReportsComments error', e);
      }
    },

    async checkExistingReportInCurrentPeriod({ commit }) {
      let res;
      try {
        res = await existingReportInCurrentPeriod();
        if (res.status === 200) {
          commit(
            'SET_EXISTING_REPORT',
            res.data.data.is_exists_report_in_current_period
          );
        }
      } catch (e) {
        console.log('>>>> checkExistingReportInCurrentPeriod error', e);
      }
    },
  },
};
