import {
  getLatestNewsData,
  getNewsData,
  getAllTags,
} from '@/api/services/news.js';

export default {
  state: {
    newsData: null,
    latestNewsData: [],
    allTags: null,
  },

  getters: {
    getNewsData: (state) => {
      return state.newsData;
    },
    getLatestNewsData: (state) => state.latestNewsData,
    getAllTags: (state) => state.allTags,
  },

  mutations: {
    GET_NEWS_DATA(state, payload) {
      state.newsData = payload;
    },
    GET_LATEST_NEWS_DATA(state, payload) {
      state.latestNewsData = [...payload];
    },
    SET_ALL_TAGS(state, payload) {
      state.allTags = payload;
    },
  },

  actions: {
    async getNewsTableData({ commit }, tag = null) {
      let payload = {};
      if (!!tag) {
        payload.tag = tag;
      }
      try {
        let res;
        res = await getNewsData(payload);
        if (res.status === 200) {
          commit('GET_NEWS_DATA', res.data.data.news);
        }
      } catch (e) {
        console.log('>>> error getNewsTableData', e.response);
      }
    },
    async getLatestNewsData({ commit }) {
      try {
        let res;
        res = await getLatestNewsData();
        if (res.status === 200) {
          commit('GET_LATEST_NEWS_DATA', res.data.data.news);
        }
      } catch (e) {
        console.log('>>> error getLatestNewsData', e.response?.errors);
      }
    },

    async getAllTags({ commit }) {
      try {
        let res;
        res = await getAllTags();
        if (res.status === 200) {
          commit('SET_ALL_TAGS', res.data.data.tags);
        }
      } catch (e) {
        console.log('>>> error getAllTags', e.response?.errors);
      }
    },
    // async getRegistryTableData({ commit, getters }, payload) {
    //   try {
    //     let res;
    //     res = await getRegistryTableData({
    //       pageNum: getters.getCurrentPage,
    //       param: payload,
    //     });
    //     if (payload.isSearch) {
    //       res = await getRegistryTableData({
    //         pageNum: 1,
    //         param: payload.param,
    //       });
    //     }
    //     if (res.status === 200) {
    //       commit('SET_REGISTER_DATA', res.data.data.table.items);
    //       if (res.data.data.table.last_page > 1) {
    //         commit('SET_LAST_PAGE', res.data.data.table.last_page);
    //       }
    //     }
    //     return res.data.data;
    //   } catch (e) {
    //     console.log('>>> error', e.response?.errors);
    //   }
    // },
  },
};
