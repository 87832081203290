<template>
  <div>
    <div class="header__row-bottom">
      <div class="header__back-content" @click="$router.go(goBackTo)">
        <svg-icon class="icon-arrow-back" name="arrow-back" />
        <span class="header__back-content">{{ $t('main.back') }}</span>
      </div>
      <div class="title-block">
        <div class="logo-icon" @click="logout">
          <svg-icon name="arms" />
        </div>
        <p>{{ $t(`header.profile_title`) }}</p>
      </div>
    </div>
    <div class="header__row-role title-h3">
      <h3>{{ getCurrentTitle }}</h3>
    </div>
  </div>

  <!-- <div class="header__content">
      <div v-if="!isMainMenu" class="" @click="$router.back()">
        {{ $t('modals.back') }}
      </div>
      &nbsp; &nbsp; &nbsp; -->
  <!-- <div v-if="isMainMenu" class="header__exit">
        <button @click="logout">
          <svg-icon class="header__icon-exit" name="exit" />
        </button>
      </div> -->
  <!-- </div> -->

  <!-- <ul class="profile__navigation">
          <li
            v-for="option in options"
            :key="option"
            class="profile__navigation_item"
          >
            <button
              class="profile__option_btn"
              :class="{ active: selectedOption === option }"
              @click="putOption(option)"
            >
              <h5 class="navigation__item_title">
                {{ $t(`profile.${option}`) }}
              </h5>
            </button>
          </li>
        </ul> -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters(['getCurrentRole']),

    getCurrentTitle() {
      return this.isMainMenu && !!this.getCurrentRole
        ? this.$t(`sections.${this.getCurrentRole}`)
        : this.$t(`sections.${this.$route.meta?.name}`);
      // : this.$t(`sections.${this.$route.meta?.name}`);
    },

    goBackTo() {
      return this.$route.meta.goBack;
    },

    isMainMenu() {
      return this.$route.meta.isMenu;
    },
  },

  methods: {
    logout() {
      this.$router.push({ name: 'auth-password' });
      this.$store.dispatch('userLogout');
    },
  },
};
</script>

<style lang="sass" scoped>

.header__row-bottom
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  gap: 1.2rem
  padding-bottom: 4rem

.header__back-content
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 1.6rem
  gap: 1.2rem
  color: $black
  cursor: pointer

.header__back-group
  display: flex
  align-items: center
  text-decoration: none
  gap: 1rem
  &:hover
    cursor: pointer
    opacity: .7

.title-block
  margin-left: auto
  display: flex
  align-items: center
  gap: 1.2rem

.header__row-role
  padding-bottom: 5rem

.header__nav-menu
  padding-bottom: 1rem
</style>
