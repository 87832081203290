import {
  getResearchFiles,
  getHotelsByRegions,
  getTourOperatorsByRegions,
  getTaxRevenues,
  getTaxRevenuesTable,
  getTourismTax,
  getTourismTaxTable,
  getTaxRevenuesTotal,
  getStatisticTotal,
} from '@/api/services/landing';

export default {
  state: {
    pages: [
      {
        id: 'vnutrishniy-turyzm',
        type: 'domestic_tourism',
      },
      {
        id: 'vyiznyy-turyzm',
        type: 'inbound',
      },
      {
        id: 'vyyiznyy-turyzm',
        type: 'outbound',
      },
      {
        id: 'podatkovi-nadkhodzhennya',
        type: 'tax_revenues',
      },
    ],
    researchFiles: [],
    hotelsByRegions: [],
    hotelsTotal: 0,
    tourOperatorsByRegions: [],
    tourOperatorsTotal: 0,
    inbound_research_iframe_url: 'https://app.powerbi.com/view?r=eyJrIjoiNzJkNWMyN2MtNGMyOS00NWY1LWIxYmMtOTJlOTNhN2ZhMjU4IiwidCI6ImEzZGI2ZWU2LWZkN2EtNDMxMC05NDM3LTZjZDBjMTg0NmFjYiIsImMiOjl9',
    taxRevenues: {
      total_sum: 0,
      total_taxpayers_paid: 0,
      total_taxpayers_paid_individual: 0,
      total_taxpayers_paid_legal: 0,
      period: {
        mounth: null,
        year: null,
      }
    },
    taxRevenuesTable: [],
    taxRevenuesTableTotalSum: 0,
    taxRevenuesTaxpayersTotal: 0,
    taxRevenuesTaxpayersGrowth: 0,
    taxRevenuesTableTotalSumGrowth: 0,

    tourismTax: {
      period: {
        mounth: null,
        year: null,
      },
      total_sum: 0,
    },
    tourismTaxTable: [],
    tourismTaxTableTotalSum: 0,
    tourismTaxTableTotalSumGrowth: 0,
    taxRevenuesTotal: {
      total_sum: 0,
      total_taxpayers_paid: 0,
      total_taxpayers_paid_individual: 0,
      total_taxpayers_paid_legal: 0,
      period: {
        mounth: null,
        year: null,
      }
    },

    total: {},
  },

  getters: {
    getStatisticType: (state) => (id) => state.pages.find(el => el.id === id).type,

    getStatisticPages: (state) => state.pages,

    getResearchFiles: (state) => state.researchFiles,

    getInboundResearchIframeUrl: (state) => state.inbound_research_iframe_url,

    getHotelsByRegions: (state) => state.hotelsByRegions,

    getHotelsTotal: (state) => state.hotelsTotal,

    getTourOperatorsByRegions: (state) => state.tourOperatorsByRegions,

    getTourOperatorsTotal: (state) => state.tourOperatorsTotal,

    getTaxRevenues: (state) => state.taxRevenues,

    getTaxRevenuesTable: (state) => state.taxRevenuesTable,

    getTaxRevenuesTaxpayersTotal: (state) => state.taxRevenuesTaxpayersTotal,

    getTaxRevenuesTaxpayersGrowth: (state) => state.taxRevenuesTaxpayersGrowth,

    getTaxRevenuesTableTotalSum: (state) => state.taxRevenuesTableTotalSum,

    getTaxRevenuesTableTotalSumGrowth: (state) => state.taxRevenuesTableTotalSumGrowth,

    getTourismTax: (state) => state.tourismTax,

    getTourismTaxTable: (state) => state.tourismTaxTable,

    getTourismTaxTableTotalSum: (state) => state.tourismTaxTableTotalSum,

    getTourismTaxTableTotalSumGrowth: (state) => state.tourismTaxTableTotalSumGrowth,

    getTaxRevenuesTotal: (state) => state.taxRevenuesTotal,

    getStatisticTotal: (state) => state.total,
  },

  mutations: {
    SET_RESEARCH_FILES(state, data) {
      state.researchFiles = data;
    },

    SET_HOTELS_BY_REGIONS(state, data) {
      state.hotelsByRegions = data;
    },

    SET_HOTELS_TOTAL(state, data) {
      state.hotelsTotal = data;
    },

    SET_TOUR_OPERATORS_BY_REGIONS(state, data) {
      state.tourOperatorsByRegions = data;
    },

    SET_TOUR_OPERATORS_TOTAL(state, data) {
      state.tourOperatorsTotal = data;
    },

    SET_TAX_REVENUES(state, data) {
      state.taxRevenues = {
        ...state.taxRevenues,
        ...data,
      }
    },

    SET_TAX_REVENUES_TABLE_DATA(state, data) {
      state.taxRevenuesTable = data;
    },

    SET_TAX_REVENUES_TAXPAYERS_TOTAL(state, data) {
      state.taxRevenuesTaxpayersTotal = data;
    },

    SET_TAX_REVENUES_TAXPAYERS_GROWTH(state, data) {
      state.taxRevenuesTaxpayersGrowth = data;
    },

    SET_TAX_REVENUES_TABLE_TOTAL_SUM(state, data) {
      state.taxRevenuesTableTotalSum = data
    },

    SET_TAX_REVENUES_TABLE_TOTAL_SUM_GROWTH(state, data) {
      state.taxRevenuesTableTotalSumGrowth = data
    },

    SET_TOURISM_TAX(state, data) {
      state.tourismTax = {
        ...state.tourismTax,
        ...data,
      }
    },

    SET_TOURISM_TAX_TABLE(state, data) {
      state.tourismTaxTable = data
    },

    SET_TOURISM_TAX_TABLE_TOTAL_SUM(state, data) {
      state.tourismTaxTableTotalSum = data
    },

    SET_TOURISM_TAX_TABLE_TOTAL_SUM_GROWTH(state, data) {
      state.tourismTaxTableTotalSumGrowth = data
    },

    SET_TAX_REVENUES_TOTAL(state, data) {
      state.taxRevenuesTotal = {
        ...state.taxRevenuesTotal,
        ...data,
      }
    },

    SET_STATISTIC_TOTAL(state, data) {
      state.total = {
        ...state.total,
        ...data,
      }
    },
  },

  actions: {
    async fetchResearchFiles({ commit }, payload) {
      let res;
      try {
        res = await getResearchFiles(payload);

        if (res.status === 200) {
          commit(
            'SET_RESEARCH_FILES',
            res.data.data.researchFiles.items
          );
        }
      } catch (e) {
        console.log(
          '>>> fetchResearchFiles error',
          e.response.data
        );
      }
    },

    async fetchHotelsByRegions({ commit }) {
      let res;

      try {
        res = await getHotelsByRegions();

        if (res.status === 200) {
          commit(
            'SET_HOTELS_BY_REGIONS',
            res.data.data.data.items
          );

          commit(
            'SET_HOTELS_TOTAL',
            res.data.data.data.hotelsTotal
          );
        }
      } catch (e) {
        console.log(
          '>>> fetchHotelsByRegions error',
          e.response.data
        );
      }
    },

    async fetchTourOperatorsByRegions({ commit }) {
      let res;

      try {
        res = await getTourOperatorsByRegions();

        if (res.status === 200) {
          commit(
            'SET_TOUR_OPERATORS_BY_REGIONS',
            res.data.data.data.items
          );

          commit(
            'SET_TOUR_OPERATORS_TOTAL',
            res.data.data.data.tourOperatorsTotal
          );
        }
      } catch (e) {
        console.log(
          '>>> fetchTourOperatorsByRegions error',
          e.response.data
        );
      }
    },

    async fetchTaxRevenues({ commit }) {
      let res;

      try {
        res = await getTaxRevenues();

        if (res.status === 200) {
          commit(
            'SET_TAX_REVENUES',
            res.data.data.data
          );
        }
      } catch (e) {
        console.log(
          '>>> fetchTaxRevenues error',
          e.response.data
        );
      }
    },

    async fetchTaxRevenuesTable({ commit }, payload) {
      let res;

      try {
        res = await getTaxRevenuesTable(payload);

        if (res.status === 200) {
          commit(
            'SET_TAX_REVENUES_TABLE_DATA',
            res.data.data.data.items
          );

          commit(
            'SET_TAX_REVENUES_TAXPAYERS_TOTAL',
            res.data.data.data.total_taxpayers
          );

          commit(
            'SET_TAX_REVENUES_TAXPAYERS_GROWTH',
            res.data.data.data.total_taxpayers_growth
          );

          commit(
            'SET_TAX_REVENUES_TABLE_TOTAL_SUM',
            res.data.data.data.total_sum
          );

          commit(
            'SET_TAX_REVENUES_TABLE_TOTAL_SUM_GROWTH',
            res.data.data.data.total_sum_growth
          );
        }
      } catch (e) {
        console.log(
          '>>> fetchTaxRevenuesTable error',
          e.response.data
        );
      }
    },

    async fetchTourismTax({ commit }) {
      let res;

      try {
        res = await getTourismTax();

        if (res.status === 200) {
          commit(
            'SET_TOURISM_TAX',
            res.data.data.data
          );
        }
      } catch (e) {
        console.log(
          '>>> fetchTourismTax error',
          e.response.data
        );
      }
    },

    async fetchTourismTaxTable({ commit }, payload) {
      let res;

      try {
        res = await getTourismTaxTable(payload);

        if (res.status === 200) {
          commit(
            'SET_TOURISM_TAX_TABLE',
            res.data.data.data.items
          );

          commit(
            'SET_TOURISM_TAX_TABLE_TOTAL_SUM',
            res.data.data.data.total_sum
          );

          commit(
            'SET_TOURISM_TAX_TABLE_TOTAL_SUM_GROWTH',
            res.data.data.data.total_sum_growth
          );
        }
      } catch (e) {
        console.log(
          '>>> fetchTourismTaxTable error',
          e.response.data
        );
      }
    },

    async fetchTaxRevenuesTotal({ commit }) {
      let res;

      try {
        res = await getTaxRevenuesTotal();

        if (res.status === 200) {
          commit(
            'SET_TAX_REVENUES_TOTAL',
            res.data.data.data
          );
        }
      } catch (e) {
        console.log(
          '>>> fetchTaxRevenuesTotal error',
          e.response.data
        );
      }
    },

    async fetchStatisticTotal({ commit }, payload) {
      let res;

      try {
        res = await getStatisticTotal(payload);

        if (res.status === 200) {
          commit(
            'SET_STATISTIC_TOTAL',
            res.data.data.data
          );
        }
      } catch (e) {
        console.log(
          '>>> fetchStatisticTotal error',
          e.response.data
        );
      }
    },
  }
}