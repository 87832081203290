import {
  getTableOfCategorizationsSecretary,
  getCategorizationSecretary,
  getTableOfVotingHistorySecretary,
  getTableOfVotingSecretary,
  getCommentsSecretary,
  setVotingDateSecretary,
  signVotingDateSecretary,
  addCommentSecretaryOfTheCommission,
} from '@/api/services/secretary_commiss.js';
import router from '@/router';
import VueI18n from '@/i18n';

export default {
  state: {
    tableOfCategorizationsSecretary: [],
    categorizationSecretary: [],
    tableOfVotingHistorySecretary: [],
    tableOfVotingSecretary: [],
    commentsSecretary: [],
  },

  getters: {
    getTableOfCategorizationsSecretary: (state) =>
      state.tableOfCategorizationsSecretary,
    getTableOfVotingHistorySecretary: (state) =>
      state.tableOfVotingHistorySecretary,
    getTableOfVotingSecretary: (state) => state.tableOfVotingSecretary,
    getCategorizationSecretary: (state) => state.categorizationSecretary,
    getCommentsSecretary: (state) => state.commentsSecretary,
  },

  mutations: {
    SET_CATEGORIZATION_TABLE_SECRETARY(state, data) {
      state.tableOfCategorizationsSecretary = data;
    },
    SET_TABLE_OF_VOTING_SECRETARY(state, data) {
      state.tableOfVotingSecretary = data;
    },
    SET_TABLE_OF_VOTING_HISTORY_SECRETARY(state, data) {
      state.tableOfVotingHistorySecretary = data;
    },
    SET_CATEGORIZATION_SECRETARY(state, data) {
      state.categorizationSecretary = data;
    },
    SET_COMMENTS_SECRETARY(state, data) {
      state.commentsSecretary = data;
    },
  },

  actions: {
    async getTableOfCategorizationsSecretary({ commit, getters }) {
      try {
        let res;
        res = await getTableOfCategorizationsSecretary(
          getters.getTableSortData
        );
        if (res.status === 200) {
          commit('SET_CATEGORIZATION_TABLE_SECRETARY', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfCategorizations',
          e.response.data.error
        );
      }
    },
    async getTableOfVotingHistorySecretary({ commit, getters }) {
      try {
        let res;
        res = await getTableOfVotingHistorySecretary(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_VOTING_HISTORY_SECRETARY', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfVotingHistorySecretary',
          e.response.data.error
        );
      }
    },
    async getTableOfVotingSecretary({ commit, getters }) {
      try {
        let res;
        res = await getTableOfVotingSecretary(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_VOTING_SECRETARY', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfVotingSecretary',
          e.response.data.error
        );
      }
    },
    async getCategorizationSecretary({ commit }, uuid) {
      let res;
      try {
        res = await getCategorizationSecretary(uuid);
        commit(
          'SET_CATEGORIZATION_SECRETARY',
          res.data.data.categorization_application
        );
      } catch (e) {
        console.log('>>> getCategorizationSecretary error', e);
      }
    },
    async getCommentsSecretary({ commit }, uuid) {
      let res;
      try {
        res = await getCommentsSecretary(uuid);
        commit('SET_COMMENTS_SECRETARY', res.data.data.criterias);
      } catch (e) {
        console.log('>>> getCommentsSecretary error', e);
      }
    },
    async setVotingDateSecretary({ commit }, payload) {
      const { data, isSign } = payload;
      let res;
      try {
        if (!isSign) {
          res = await setVotingDateSecretary(data);
          if (res.status === 200) {
            commit('SHOW_NOTIFICATION', true);
            commit('GET_RESPONSE_MESSAGE', 'Дату встановлено');
            router.push({ name: 'secretary-commission_categorization-list' });
          }
        } else {
          res = await setVotingDateSecretary(data);
          if (res.status === 200) {
            commit('modal/SET_MODAL', {
              state: true,
              name: 'add-key',
              size: 'l',
            });
          }
        }
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },
    async signVotingDateSecretary({ commit }, payload) {
      let res;
      commit('SET_RQUEST_LOADED', false);
      try {
        res = await signVotingDateSecretary({ ...payload });
        if (res.status === 200) {
          commit('SET_RQUEST_LOADED', true);
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', 'Підписано');
          router.push({ name: 'secretary-commission_votes-list' });
        }
      } catch (e) {
        console.log(
          '>>> signVotingDateSecretary error',
          e.response.data.errors.ecp
        );
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.errors.ecp);
        commit('GET_IS_ERROR', true);
      }
    },

    async addCommentSecretaryOfTheCommission({ commit }, payload) {
      let res;
      const {
        categorization_application_uuid,
        criterion_uuid,
        comment: message,
      } = payload;
      try {
        res = await addCommentSecretaryOfTheCommission({
          categorization_application_uuid,
          criterion_uuid,
          message,
        });
        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.sent'));
        }
      } catch (e) {
        console.log('>>> addCommentSecretaryOfTheCommission error', e);
      }
    },
  },
};
