export default [
  {
    path: '/',
    component: () => import('@/components/landing/LandingPage.vue'),
    // redirect: {name: 'landing-page'},
    meta: {
      title: `Єдиний туристичний реєстр`,
    },
    children: [
      {
        path: '/',
        name: 'landing-page',
        component: () => import('@/components/landing/LandingPageMain.vue'),
        meta: {
          name: 'home',
        },
      },
      {
        path: '/news',
        name: 'news-all',
        component: () => import('@/components/landing/news/NewsAll.vue'),
        meta: {
          name: 'home',
        },
      },
      {
        path: '/news/:id?',
        name: 'news-details',
        component: () => import('@/components/landing/news/NewsDetails.vue'),
        meta: {
          name: 'home',
        },
      },
      {
        path: '/registry',
        name: 'search-results',
        component: () =>
          import('@/components/landing/register/SearchResults.vue'),
        meta: {
          name: 'home',
        },
      },
      {
        path: '/registry/hotel/:id?',
        name: 'search-results-details_hotel',
        component: () =>
          import('@/components/landing/register/HotelDetails.vue'),
        meta: {
          name: 'home',
          type: 'hotel',
        },
      },
      {
        path: '/registry/tour-operator/:id?',
        name: 'search-results-details_tour-operator',
        component: () =>
          import('@/components/landing/register/TourOperatorDetails.vue'),
        meta: {
          name: 'home',
          type: 'tour-operator',
        },
      },
      {
        path: '/questions',
        name: 'questions',
        component: () => import('@/components/landing/questions/Questions.vue'),
        meta: {
          name: 'home',
        },
      },
      {
        path: '/statistic',
        name: 'statistic',
        component: () =>
          import('@/components/landing/statistic/StatisticDetails.vue'),
        meta: {
          name: 'home',
        },
      },
      {
        path: '/statistic/:id?',
        name: 'statisticPage',
        component: () =>
          import('@/components/landing/statistic/StatisticDetails.vue'),
        meta: {
          name: 'home',
        },
      },
      {
        path: '/questions/:id?',
        name: 'FAQPage',
        component: () =>
          import('@/components/landing/questions/FAQPage.vue'),
        meta: {
          name: 'home',
        },
      },
      {
        path: '/contacts',
        name: 'contacts',
        component: () => import('@/components/landing/contacts/Contacts.vue'),
        meta: {
          name: 'home',
        },
      },
    ],
  },
];
