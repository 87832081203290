export default [
  {
    filing_status: 'submitted',
    review_status: 'waiting_for_voting',
    icon: 'status_flag_green',
  },
  {
    filing_status: 'submitted',
    review_status: 'approved',
    icon: 'status_approved',
  },
  {
    filing_status: 'submitted',
    review_status: 'declined',
    icon: 'status_declined',
  },
];
