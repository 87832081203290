<template>
  <div>
    <div class="header__row-top">
      <div class="logo-icon" @click="$router.push('/')">
        <svg-icon name="arms" />
      </div>
      <p>{{ $t(`header.profile_title`) }}</p>
      <div class="login__block" @click="logout">
        <div>{{ $t(`main.exit`) }}</div>
        <svg-icon class="exit-icon" name="exit" />
      </div>
      <div class="sandwich-menu" @click="toggleSandwichMenu()">
        <svg-icon
          v-if="!isSandwichOpen"
          class="sandwich-icon"
          name="sandwich"
        />
        <svg-icon v-else class="sandwich-icon" name="cross" />
      </div>
    </div>
    <div class="header__row-role title-h3">
      <div v-if="getCurrentRole === 'hotel'" class="hotel_title_container">
        <h3 class="header__title">{{ getCurrentTitle }}</h3>
        <star-rating
          :rating="userData.star_category"
          :read-only="true"
          :star-size="28"
          :fixed-points="1"
          :show-rating="false"
          :padding="8"
          active-color="#FFB33B"
          inactive-color="#fff"
          class="star_icons"
        />
      </div>
      <div v-else>
        <span class="header__title_name">{{ userData.full_name }}</span>
        <div class="header__title_wrapper">
          <h3 class="header__title">{{ getCurrentTitle }}</h3>
          <span
            v-if="!!userData.term_of_office_start"
            class="header__title_name header__title_name--date"
            >з {{ userData.term_of_office_start }} по
            {{ userData.term_of_office_end }}</span
          >
        </div>
      </div>
    </div>
    <nav-menu
      v-if="isMainMenu"
      :is-sandwich-open="isSandwichOpen"
      class="header__nav-menu"
      @closeSandwichMenu="closeSandwichMenu()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StarRating from 'vue-star-rating';
import { getFromLS } from '@/library/helpers';

export default {
  components: {
    StarRating,
  },
  data() {
    return {
      isSandwichOpen: false,
      userData: getFromLS('currentUser'),
    };
  },

  computed: {
    ...mapGetters(['getCurrentRole', 'getCurrentMenu']),

    getCurrentTitle() {
      if (this.getCurrentRole === 'hotel') {
        return this.userData.name_of_legal_entity;
      } else {
        return this.isMainMenu && !!this.getCurrentRole
          ? this.$t(`sections.${this.getCurrentRole}`)
          : this.$t(`sections.${this.$route.meta?.name}`);
      }
    },

    isMainMenu() {
      return this.$route.meta.isMenu;
    },
  },

  mounted() {
    this.closeMenuOnResize();
  },

  methods: {
    closeMenuOnResize() {
      const container = document.querySelector('.main_container');

      const resizeObserver = new ResizeObserver((entries, observer) => {
        for (let entry of entries) {
          if (entry.contentBoxSize) {
            const contentBoxSize = Array.isArray(entry.contentBoxSize)
              ? entry.contentBoxSize[0]
              : entry.contentBoxSize;
            if (contentBoxSize.inlineSize > 1150) {
              this.isSandwichOpen = false;
            }
          }
        }
      });

      resizeObserver.observe(container);
    },

    logout() {
      // this.$router.push({ name: 'auth' });
      // this.$router.push({ name: 'auth-password' });
      this.$store.dispatch('userLogout');
    },
    check() {
      
    },
    toggleSandwichMenu() {
      this.isSandwichOpen = !this.isSandwichOpen;
    },
    closeSandwichMenu() {
      this.isSandwichOpen = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.star_icons
  margin-left: 25px
  @include  xs
    margin-left: 0
    margin-top: 14px

.header__title_wrapper
  display: flex
  // align-items: flex-start
.header__title
  font-size: 36px
  font-weight: 400
  line-height: 48px
  @include xs
    font-size: 20px
    font-weight: 400
    line-height: 28px

.header__title_name
  font-size: 14px
  color: #7F7F7F
.hotel_title_container
  display: flex
  @include  xs
    flex-direction: column

.header__title_name--date
  font-size: 14px
  color: #7F7F7F
  margin-left: 12px
  line-height: 44px
  @include xs
    font-size: 12px
    line-height: 34px
.header__row-top
  display: flex
  align-items: center
  justify-content: start
  width: 100%
  gap: 1.2rem
  padding-bottom: 5rem

.header__row-role
  padding-bottom: 5rem
  @include xs
    padding-bottom: 2rem

.header__nav-menu
  // padding-bottom: 1rem

.login__block
  @include s
    display: none

.sandwich-menu
  margin-left: auto
  cursor: pointer
</style>

