<template>
  <div class="modal__container-inner">
    <div class="key-info key-info__box">
      <h3 class="key-info__title modal__title title-h4">
        {{ $t('modals.user_key_data') }}
      </h3>
      <div class="key-info__wrapper">
        <text-field
          v-for="(field, key) in keyInfo"
          :id="key"
          :key="key"
          v-model="keyInfo[key]"
          class="key-info__item"
          :is-disabled="true"
          :title="$t(`modals.${key}`)"
        />
      </div>
      <div class="modal__btn-2col-center">
        <Button white @click.native="closeModal">
          {{ $t('modals.cancel') }}
        </Button>
        <Button :disabled="!isInitEuSign" @click.native="confirmSavingKey">
          <template v-if="isInitEuSign">
            {{ currentButtonText }}
          </template>
          <LoadingSpinner v-else />
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import euSignController from '@/mixins/euSign/euSignController';
import { mapGetters } from 'vuex';

export default {
  mixins: [euSignController],

  data() {
    return {
      keyInfoFields: [
        'subjCN',
        'subjEDRPOUCode',
        'subjFullName',
        'subjLocality',
        'subjState',
        'issuerCN',
      ],
    };
  },

  computed: {
    ...mapGetters('modal', ['getModalProps']),
    ...mapGetters([
      'getKeySignData',
      'isInitEuSign',
      'getCurrentFormType',
      'getDataToSign',
    ]),

    keyInfo() {
      let obj = {};
      this.keyInfoFields.map(
        (el) => {
          if(el === 'subjEDRPOUCode') {
            if(this.getModalProps.keyInfo[el]) {
              obj[el] = this.getModalProps.keyInfo[el];
            } else if (this.getModalProps.keyInfo['subjDRFOCode']) {
              obj[el] = this.getModalProps.keyInfo['subjDRFOCode'];
            } else {
              obj[el] = '-';
            }
          } else {
            obj[el] = !!this.getModalProps.keyInfo[el] ? this.getModalProps.keyInfo[el] : '-'
          }
        }
      );

      return obj;
    },

    isAuthForm() {
      return (
        this.getCurrentFormType?.type === 'auth' &&
        this.getCurrentFormType?.isSet
      );
    },

    currentButtonText() {
      let res = this.$t('buttons.add_key_btn');
      if (this.isAuthForm) {
        res = this.$t('main.enter');
      } else if (this.getCurrentFormType.isSign) {
        res = this.$t('modals.send_key');
      }

      return res;
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },
    confirmSavingKey() {
      const { handler, isSign, key } = this.getCurrentFormType;

      let data = {};

      if (isSign) {
        data.raw_sign = this.getKeySignData;
        data[key] = this.getDataToSign;

        // data.categorization_application_uuid = this.getDataToSign;

        // data.voting_uuid= this.getDataToSign; atribute for voite

        // to do case for: categorization and voite
      } else {
        data.raw_sign = this.getKeySignData;
      }

      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'accept',
        size: 'xl',
        handler: { name: handler, payload: data },
      });
    },
  },
};
</script>

<style lang="sass">
.key-info__box
  padding: 0 80px
  @include xs
    padding: 0 50px
  @include xxs
    padding: 0 20px

.key-info__wrapper
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: 0 64px
  margin-bottom: 40px

.key-info__item
  width: calc(50% - 32px)
  @include xs
    width: 100%


.key-info__buttons
  display: flex
  justify-content: flex-end
  gap: 54px
</style>
