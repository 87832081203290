export const table = {
  'hotel_categorizations-list': {
    path_name: 'hotel_categorization-edit',
    columns: [
      {
        name: 'review_status',
        isSortable: true,
      },
      {
        name: 'number',
        isSortable: true,
        // alignCenter: true
      },
      {
        name: 'categorization_name',
        isSortable: true,
      },
      {
        name: 'submitted_at',
        type: 'date',
        isSortable: true,
      },
    ],
    tooltip: {
      approved: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
      declined: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
      pending_review: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
      waiting_for_submission: [
        {
          name: 'edit',
          type_action: 'edit',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
        {
          name: 'delete',
          isRouting: false,
          handler: 'deleteCategorization',
        },
      ],
      waiting_for_voting_date: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
      waiting_for_voting_date_signed: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
      waiting_for_voting: [
        {
          name: 'readonly',
          type_action: 'readonly',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
      needs_improvement: [
        {
          name: 'make_changes',
          type_action: 'edit',
          isRouting: true,
          handler: null,
        },
        {
          name: 'downloadPDF',
          isRouting: false,
          handler: 'downloadFile',
        },
      ],
    },
    actions: {
      approved: 'readonly',
      declined: 'readonly',
      pending_review: 'readonly',
      waiting_for_submission: 'edit',
      waiting_for_voting_date: 'readonly',
      waiting_for_voting_date_signed: 'readonly',
      waiting_for_voting: 'readonly',
      needs_improvement: 'edit',
    },
  },
};
