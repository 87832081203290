<template>
  <div class="modal__container-inner">
    <div class="accept accept__box">
      <h3 class="accept__title modal__mb-20 title-h4">
        {{ $t('modals.voting') }}
      </h3>
      <div class="accept__content">
        {{ $t('modals.voting_subtitle') }}
      </div>
      <div class="modal__btn-4col-center">
        <Button vote-decision="upgrade" @click.native="vote('upgrade')">
          {{ $t('voting.upgrade') }}
        </Button>
        <Button vote-decision="declined" @click.native="vote('declined')">
          {{ $t('voting.declined') }}
        </Button>
        <Button vote-decision="revision" @click.native="vote('revision')">
          {{ $t('voting.revision') }}
        </Button>
        <Button vote-decision="downgrade" @click.native="vote('downgrade')">
          {{ $t('voting.downgrade') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { log } from 'console';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters('modal', [
      'getModalProps',
      'getModalHandler',
      'getModalType',
    ]),
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },
    vote(decision) {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'accept-vote',
        size: 'xl',
        handler: {
          name: `confirmVote${this.getModalHandler.role}`,
          payload: {
            vote: decision,
            voting_uuid: this.getModalHandler.payload,
          },
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped>

.accept__box
  padding: 0 80px

.accept__box *
  text-align: center

.accept__content
  color: $gray
  margin-bottom: 60px
</style>
