import {
  getTableOfCategorizationsDart,
  getCategorizationDart,
  sendForImprovement,
  sendToComission,
  getTableOfVotingHistoryDart,
  getCommentsDart,
  leaveCommentDart,
  existingCategorizationСomments,
  getTableOfReportsDart,
  addCommentReportDart,
  existingReportСomments,
  sendReportForImprovementDart,
  approveTourOperatorsReport,
} from '@/api/services/dart.js';
import router from '@/router';
import VueI18n from '@/i18n';

export default {
  state: {
    tableOfCategorizationsDart: [],
    categorizationDart: [],
    tableOfVotingHistoryDart: [],
    commentsDart: [],
    tableOfTourOperatorsReportsDart: null,
    existingCategorizationСomments: false,
    existingReportСomments: false,
  },

  getters: {
    getTableOfCategorizationsDart: (state) => state.tableOfCategorizationsDart,
    getTableOfVotingHistoryDart: (state) => state.tableOfVotingHistoryDart,
    getCategorizationDart: (state) => state.categorizationDart,
    getCommentsDart: (state) => state.commentsDart,
    tableOfReportsDart: (state) => state.tableOfTourOperatorsReportsDart,
    isExistgCategorizationСomments: (state) =>
      state.existingCategorizationСomments,
    isExistingReportСomments: (state) => state.existingReportСomments,
  },

  mutations: {
    SET_CATEGORIZATION_TABLE_DART(state, data) {
      state.tableOfCategorizationsDart = data;
    },
    SET_TABLE_OF_VOTING_DART(state, data) {
      state.tableOfVotingHistoryDart = data;
    },
    SET_CATEGORIZATION_DART(state, data) {
      state.categorizationDart = data;
    },
    SET_COMMENTS_DART(state, data) {
      state.commentsDart = data;
    },
    SET_EXISTING_CATEGORIZATION_СOMMENTS(state, value) {
      state.existingCategorizationСomments = value;
    },
    SET_EXISTING_REPORT_СOMMENTS(state, value) {
      state.existingReportСomments = value;
    },
    SET_TABLE_OF_REPORTS_DART(state, data) {
      state.tableOfTourOperatorsReportsDart = data;
    },
  },

  actions: {
    async getTableOfCategorizationsDart({ commit, getters }) {
      try {
        let res;
        res = await getTableOfCategorizationsDart(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_CATEGORIZATION_TABLE_DART', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfCategorizations',
          e.response.data.error
        );
      }
    },

    async getTableOfVotingHistoryDart({ commit, getters }) {
      try {
        let res;
        res = await getTableOfVotingHistoryDart(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_VOTING_DART', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfVotingHistoryDart',
          e.response.data.error
        );
      }
    },

    async getCategorizationDart({ commit, dispatch }, uuid) {
      let res;
      try {
        res = await getCategorizationDart(uuid);
        if (res.status === 200) {
          commit(
            'SET_CATEGORIZATION_DART',
            res.data.data.categorization_application
          );
          dispatch('checkExistingCategorizationСomments', uuid);
        }
      } catch (e) {
        console.log('>>> getCategorizationDart error', e);
      }
    },

    async getCommentsDart({ commit }, uuid) {
      let res;
      try {
        res = await getCommentsDart(uuid);
        commit('SET_COMMENTS_DART', res.data.data.criterias);
      } catch (e) {
        console.log('>>> getCommentsDart error', e);
      }
    },

    async sendForImprovement({ commit }, uuid) {
      let res;
      try {
        res = await sendForImprovement(uuid);
        router.push({ name: 'dart_hotel-categorizations' });
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.sent'));
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async sendToComission({ commit }, uuid) {
      let res;
      try {
        res = await sendToComission(uuid);
        router.push({ name: 'dart_hotel-categorizations' });
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.sent'));
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async addCommentHotelDart({ commit, dispatch }, payload) {
      let res;
      try {
        res = await leaveCommentDart(
          payload.criterion_uuid,
          payload.categorization_application_uuid,
          payload.comment
        );

        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.sent'));
          dispatch(
            'checkExistingCategorizationСomments',
            payload.categorization_application_uuid
          );

          return res.data.data.result;
        }
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }

    },

    async addCommentReportDart({ commit, dispatch }, payload) {
      let res;
      try {
        res = await addCommentReportDart(
          payload.criterion_uuid,
          payload.report_uuid,
          payload.comment
        );

        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.sent'));
          dispatch('checkExistingReportСomments', payload.report_uuid);
        }
      } catch (e) {
        console.log('>>> addCommentToTourOperatorFromDart error', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async checkExistingCategorizationСomments({ commit }, uuid) {
      let res;
      try {
        res = await existingCategorizationСomments({ uuid });
        if (res.status === 200) {
          commit(
            'SET_EXISTING_CATEGORIZATION_СOMMENTS',
            res.data.data.exists_comment
          );
        }
      } catch (e) {
        console.log('>>>> checkExistingCategorizationСomments error', e);
      }
    },

    async checkExistingReportСomments({ commit }, uuid) {
      let res;
      try {
        res = await existingReportСomments({ uuid });
        if (res.status === 200) {
          commit('SET_EXISTING_REPORT_СOMMENTS', res.data.data.exists_comment);
        }
      } catch (e) {
        console.log('>>>> checkExistingReportСomments error', e);
      }
    },

    async getTableOfReportsDart({ commit, getters }) {
      let res;
      try {
        res = await getTableOfReportsDart(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_REPORTS_DART', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log('>>> getTableOfReportsDart error', e);
      }
    },

    async sendReportForImprovementDart({ commit }, uuid) {
      let res;
      try {
        res = await sendReportForImprovementDart({ uuid });
        if (res.status === 200) {
          router.push({ name: 'dart_tour-operator-reports' });
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.sent'));
        }
      } catch (e) {
        console.log('>>> sendForImprovementHandler error', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async approveTourOperatorsReport({ commit }, uuid) {
      let res;
      try {
        res = await approveTourOperatorsReport({ uuid });
        if (res.status === 200) {
          router.push({ name: 'dart_tour-operator-reports' });
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.approved'));
        }
      } catch (e) {
        console.log('>>>> approveTourOperatorsReport error', r);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },
  },
};
