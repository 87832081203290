<template>
  <button
    :class="cssClasses"
    class="btn btn__main"
    :pointer-events="eventNone ? 'none' : 'auto'"
    :disabled="disabled || isInactive"
  >
    <svg-icon v-if="iconName" :name="iconName" class="btn__icon" />
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    white: {
      type: Boolean,
      default: false,
    },

    eventNone: {
      type: Boolean,
      default: false,
    },

    transparent: {
      type: Boolean,
      default: false,
    },

    iconName: {
      type: String,
      default: '',
    },

    isRightIcon: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    voteDecision: {
      type: String,
      default: '',
    },
    upgrade: {
      type: Boolean,
      default: false,
    },
    declined: {
      type: Boolean,
      default: false,
    },
    revision: {
      type: Boolean,
      default: false,
    },
    downgrade: {
      type: Boolean,
      default: false,
    },
    isInactive: {
      type: Boolean,
      default: false,
    },
    isControl: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssClasses() {
      return {
        btn_black: !this.white && !this.voteDecision && !this.transparent,
        btn_white: this.white,
        btn_transparent: this.transparent,
        btn_small: this.small,
        btn_medium: this.medium,
        btn__icon_right: this.isRightIcon,
        btn_upgrade: this.voteDecision === 'upgrade',
        btn_declined: this.voteDecision === 'declined',
        btn_revision: this.voteDecision === 'revision',
        btn_downgrade: this.voteDecision === 'downgrade',
        btn_control: this.isControl,
        btn_disabled: this.disabled,
        btn_inactive: this.isInactive,
      };
    },
  },
};
</script>
<style lang="sass">
.btn__main
  display: flex
  align-items: center
  justify-content: center
  gap: 15px
  position: relative
  cursor: pointer
  border-radius: 28px
  min-width: 255px
  padding: 16px
  transition: all 0.25s
  &::before
    content: ""
    display: block
    width: calc(100% - 2px)
    height: calc(100% - 2px)
    position: absolute
    border-radius: 28px
    border: solid 2px transparent
    transition: all 0.25s
  &:not(.btn_inactive, .btn_disabled)
    &:hover
      transition: all 0.3s
      &::before
        border-color: $white

  @include xs
    font-size: 14px


.btn_small
  font-size: 0.8rem
  max-height: 20rem
  min-width: 0
  padding: 1rem 2rem

.btn_medium
  font-size: 14px
  // max-height: 20rem
  min-width: 150px
  padding: 12px 24px

.btn_black
  color: $white
  background-color: $black
  border: solid 2px $black
  .btn__icon
    fill: $white

.btn_transparent
  border: solid 2px $black
  .btn__icon
    fill: $black
  &:not(.btn_inactive, .btn_disabled)
    &:hover
      background-color: $black
      color: $white
      .btn__icon
        fill: $white

.btn_small
  font-size: 0.8rem
  max-height: 20rem
  min-width: 0
  padding: 1rem 2rem

.btn_upgrade
  background-color: #E6F9ED
  color: #469661
  border: 2px solid #E6F9ED
  &:not(.btn_inactive, .btn_disabled)
    &:hover
      border-color: #469661

.btn_declined
  background-color: #FFDDDC
  color: #B12B29
  border: 2px solid #FFDDDC
  &:not(.btn_inactive, .btn_disabled)
    &:hover
      border-color: #B12B29

.btn_revision
  background-color: #FFF4E2
  color: #BD8224
  border: 2px solid #FFF4E2
  &:not(.btn_inactive, .btn_disabled)
    &:hover
      border-color: #BD8224

.btn_downgrade
  background-color: #e5e5e5
  color: #272727
  border: 2px solid #e5e5e5
  &:not(.btn_inactive, .btn_disabled)
    &:hover
      border-color: #272727

.btn_white
  color: $black
  background: $white
  border: solid 2px $black
  .btn__icon
    fill: $black
  &:not(.btn_inactive, .btn_disabled)
    &:hover
      background: $black
      color: $white
      .btn__icon
        fill: $white

// .btn_disabled, .btn_disabled:hover
.btn_disabled
  background: $gray_background
  color: $gray
  border-color: #ededed
  &:hover
    cursor: not-allowed

  .svg-icon
    fill: $gray

.svg-icon.btn__icon
  max-height: 19px
  max-width: 19px
  // margin-left: 5px
  @include xs
    display: none

.btn__icon_right
  .btn__icon
    order: 2

.btn_control
  height: 48px
  &::before
    display: none
  &:not(.btn_disabled)
    stroke: none
    background-color: #F8F8F8
    color: black
    border: none
    .btn__icon
      fill: $black
  @include xs
    font-size: 12px
    font-style: normal
    font-weight: 400
    max-width: 288px
    margin: 0 0 26px 0
</style>
