<template>
  <div class="">
    <div class="eu-key__items-wrapper">
      <SelectField
        :items="CAsData"
        :title="$t('main.choose_key_organisation')"
        isObjectType
        valueToReturn="object"
        titleKey="issuerCName"
        :isDisabled="!isInitEuSign"
        :isError="$v.getSelectedACSKForTypeHardware.$error"
        class="eu-key__item-form mb-16"
        @select-value="setSelectedValue"
      />
      <SelectField
        :items="!!getKeyMediaTypesList.length ? getKeyMediaTypesList : []"
        :title="$t('main.choose_key')"
        isObjectType
        valueToReturn="object"
        titleKey="title"
        placeholder="Носій особистого ключа"
        :isDisabled="!isInitEuSign || !Boolean(getKeyMediaTypesList.length)"
        :isError="$v.getSelectedHardwareKey.$error"
        class="eu-key__item-form eu-key__item-form_last-el mb-16"
        @select-value="setSelectedKey"
      />
      <text-field
        v-model="keyPass"
        :title="$t('main.enter_key_password')"
        :placeholder="$t('main.password')"
        :isPassword="true"
        :isDisabled="!isInitEuSign"
        :isError="isKeyPasswordError"
        :errorText="errorKeyPasswordMsg"
        class="eu-key__item-form"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import euSignController from '@/mixins/euSign/euSignController';
import SelectField from '@/elements/SelectField.vue';
export default {
  components: {
    SelectField,
  },

  mixins: [euSignController],

  props: {
    isInit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },

  watch: {
    isInit(val) {
      val && this.$v.$touch();
      !this.$v.$invalid && this.readKeyFromDevice();
      this.$emit('initStatus', false);
    },
  },

  mounted() {
    this.setHardwareKeyLibrary();
    this.$store.commit('SET_KEY_PASSWORD', '');
  },

  computed: {
    ...mapGetters([
      'getKeyFileData',
      'getSelectedACSKForTypeHardware',
      'getKeyPassword',
      'isInitEuSign',
      'getSelectedHardwareKey',
      'getKeyMediaTypesList',
    ]),

    keyPass: {
      get() {
        return this.getKeyPassword;
      },
      set(val) {
        this.$store.commit('SET_KEY_PASSWORD', val);
      },
    },

    fileData: {
      get() {
        return !!this.getKeyFileData ? [this.getKeyFileData] : [];
      },
      set(val) {
        let file = !!val.length ? val[0] : null;
        this.$store.commit('SET_KEY_FILE_DATA', file);
      },
    },
  },

  methods: {
    setSelectedValue(val) {
      this.$store.commit('SET_SELECTED_ACSK_TYPE_HARDWARE', val);
    },

    setSelectedKey(val) {
      this.$store.commit('SET_SELECTED_HARDWARE_KEY', val);
    },
  },
};
</script>
