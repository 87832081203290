<template>
  <div v-if="getLoaderState" class="loader">
    {{ $t(`main.loading`) }}
    <img src="/images/loader.svg" alt="loading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getLoaderState']),
  },
};
</script>

<style lang="sass" scoped>
.loader
  display: flex
  align-items: center
  justify-content: center
  gap: 1rem
  position: fixed
  top: 10%
  left: 25%
  width: 50%
  height: 50%
  background-color: rgba(250, 250, 250, 0.9)
img
  height: 5rem
</style>
