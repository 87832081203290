<template>
  <div class="modal__container-inner">
    <div class="modal__pdf-container">
      <vue-pdf-app :pdf="getCurUrl" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VuePdfApp from 'vue-pdf-app';
import 'vue-pdf-app/dist/icons/main.css';

export default {
  components: {
    VuePdfApp,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('modal', ['getModalProps']),

    getCurUrl() {
      return this.getModalProps.url;
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__pdf-container
  height: 93vh
  width: 100%
</style>
