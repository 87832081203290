import {
  getTableOfVotingCommissioner,
  getCategorizationCommissioner,
  getTableOfVotingHistoryCommissioner,
  getCommentsCommissioner,
  confirmVoteCommissioner,
  addCommentCommissioner,
} from '@/api/services/commissioner.js';
import router from '@/router';
import VueI18n from '@/i18n';

export default {
  state: {
    tableOfCategorizationsCommissioner: [],
    categorizationCommissioner: [],
    tableOfVotingHistoryCommissioner: [],
    commentsCommissioner: [],
  },

  getters: {
    getTableOfVotingCommissioner: (state) =>
      state.tableOfCategorizationsCommissioner,
    getTableOfVotingHistoryCommissioner: (state) =>
      state.tableOfVotingHistoryCommissioner,
    getCategorizationCommissioner: (state) => state.categorizationCommissioner,
    getCommentsCommissioner: (state) => state.commentsCommissioner,
  },

  mutations: {
    SET_CATEGORIZATION_TABLE_COMMISSIONER(state, data) {
      state.tableOfCategorizationsCommissioner = data;
    },
    SET_TABLE_OF_VOTING_COMMISSIONER(state, data) {
      state.tableOfVotingHistoryCommissioner = data;
    },
    SET_CATEGORIZATION_COMMISSIONER(state, data) {
      state.categorizationCommissioner = data;
    },
    SET_COMMENTS_COMMISSIONER(state, data) {
      state.commentsCommissioner = data;
    },
  },

  actions: {
    async getTableOfVotingCommissioner({ commit, getters }) {
      try {
        let res;
        res = await getTableOfVotingCommissioner(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_CATEGORIZATION_TABLE_COMMISSIONER', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfCategorizations',
          e.response.data.error
        );
      }
    },
    async getTableOfVotingHistoryCommissioner({ commit, getters }) {
      try {
        let res;
        res = await getTableOfVotingHistoryCommissioner(
          getters.getTableSortData
        );
        if (res.status === 200) {
          commit('SET_TABLE_OF_VOTING_COMMISSIONER', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfVotingHistoryCommissioner',
          e.response.data.error
        );
      }
    },
    async getCategorizationCommissioner({ commit }, uuid) {
      let res;
      try {
        res = await getCategorizationCommissioner(uuid);
        commit(
          'SET_CATEGORIZATION_COMMISSIONER',
          res.data.data.categorization_application
        );
      } catch (e) {
        console.log('>>> getCategorizationCommissioner error', e);
      }
    },
    async getCommentsCommissioner({ commit }, uuid) {
      let res;
      try {
        res = await getCommentsCommissioner(uuid);
        commit('SET_COMMENTS_COMMISSIONER', res.data.data.criterias);
      } catch (e) {
        console.log('>>> getCommentsCommissioner error', e);
      }
    },
    async confirmVoteCommissioner({ commit }, payload) {
      commit('SET_RQUEST_LOADED', false);
      try {
        await confirmVoteCommissioner(payload);
        router.push({
          path: `voting-results/${payload.voting_uuid}`,
        });
        commit('SET_RQUEST_LOADED', true);
      } catch (e) {
        console.log('>>> error confirmVote', e);
        // Delete after Boris fix of categorization attributes
        router.push({
          path: `voting-results/${payload.voting_uuid}`,
        });
      }
    },

    async addCommentCommissioner({ commit }, payload) {
      let res;
      const {
        categorization_application_uuid,
        criterion_uuid,
        comment: message,
      } = payload;
      try {
        res = await addCommentCommissioner({
          categorization_application_uuid,
          criterion_uuid,
          message,
        });
        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.sent'));
        }
      } catch (e) {
        console.log('>>> addCommentCommissioner error', e);
      }
    },
  },
};
