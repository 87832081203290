<template>
  <v-dialog
    v-model="getModalState"
    persistent
    :max-width="`${getModalWidth}px`"
    @keydown.esc="close"
    @click:outside="close"
  >
    <div
      class="modal modal__container"
      :class="{ modal__container_pdf: isPdfViewer }"
    >
      <div class="modal__container-content">
        <div class="modal__container-wrapper-cross" @click="close">
          <svg-icon name="cross" />
        </div>
        <component
          :is="modalName"
          @go-to-route="closeModalWithRouting"
        ></component>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import KeyInfoModal from '@/elements/Modals/KeyInfoModal.vue';
import AcceptModal from '@/elements/Modals/AcceptModal.vue';
import AddKeyModal from '@/elements/Modals/AddKeyModal.vue';
import VoteModal from '@/elements/Modals/VoteModal.vue';
import AcceptVoteModal from '@/elements/Modals/AcceptVoteModal.vue';
import PdfViewModal from '@/elements/Modals/PdfViewModal.vue';
import CreateCertificateModal from '@/elements/Modals/CreateCertificateModal.vue';
import MapModal from '@/elements/Modals/MapModal.vue';
import IframeViewModal from '@/elements/Modals/IframeViewModal.vue';

export default {
  components: {
    KeyInfoModal,
    AcceptModal,
    AddKeyModal,
    VoteModal,
    AcceptVoteModal,
    PdfViewModal,
    MapModal,
    CreateCertificateModal,
    IframeViewModal,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters('modal', [
      'getModalState',
      'getModalName',
      'getModalWidth',
      'getModalProps',
    ]),
    modalName() {
      return this.getModalName ? `${this.getModalName}-modal` : '';
    },
    isPdfViewer() {
      return !!this.getModalProps?.isPdfViewer
        ? this.getModalProps?.isPdfViewer
        : false;
    },
  },

  methods: {
    close() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },
    closeModalWithRouting(path) {
      this.close();
      this.$router.push(path);
    },
  },
};
</script>

<style lang="sass">
.v-dialog:not(.v-dialog--fullscreen)
  max-width: 940px
  overflow-y: unset

.v-dialog__content
  width: 100%

.modal
  position: relative
  background: $white
  &::before
    content: ""
    position: absolute
    min-width: 100%
    height: 24px
    background: $background_main

.modal__container-wrapper-cross
  position: absolute
  top: -60px
  right: 0
  width: 50px
  height: 50px
  padding: 13px
  background-color: $white
  cursor: pointer
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12)

.modal .icon-cross
  width: 24px
  height: 24px

.modal__container-content
  position: relative
  padding: 70px 0 40px 0
  @include xxs
    width: 100vw

.modal__container-inner
  display: flex
  flex-direction: column
  align-items: center
  .icon-letter
    width: 64px
    height: 58px

  .icon-check
    width: 65px
    height: 65px

.modal__container_wrapper-icon
  width: 50px
  height: 50px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%

.modal__title
  line-height: 32px
  margin-bottom: 60px
  text-align: center

.modal__sub-title
  width: 515px
  margin: 0 auto
  font-size: 16px
  line-height: 24px
  color: #8D8D8D
  text-align: center

.icon-check-sm
  width: 19px
  height: 16px

.modal__custom-title
  margin-bottom: 10px

.modal__mb-20
  margin-bottom: 20px

.modal__btn--wide.btn__main
  padding: 16px 80px

  .btn__icon
    margin-left: 12px

.modal__btn--full.btn__main
  width: 100%

.modal__btn-2col-center, .modal__btn-4col-center
  display: flex
  justify-content: center
  gap: 4rem
  flex-wrap: wrap

.modal__btn-1col-center
  margin: 2.4rem 0
  display: flex
  justify-content: center
  gap: 4rem
  flex-wrap: wrap

.modal__container_pdf
  transform: translateY(20px)
  &:before
    content: none
  .modal__container-content
    padding: 0
  .modal__container-wrapper-cross
    // top: 0
    // right: -64px
</style>
